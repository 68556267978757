import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { sendGAEvent } from 'src/utils';
import { iconWarningSolid } from '@wfp/icons';
import { useHistory } from 'react-router-dom';
import { REPORT_DETAIL_DATA_KEY } from 'src/redux/report/constants';
import {
  Module,
  ModuleHeader,
  ModuleBody,
  ModuleFooter,
  Button,
  Input,
  Icon,
} from '@wfp/ui';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ApplyChangeTitle } from 'src/redux/report/actions';

interface ISubtitle {
  title: string;
  description: string;
}

interface Props {
  headingWidgets: any[];
  initialContent: string;
  isEditable: boolean;
  sectionId: number;
  subtitle?: ISubtitle;
  isVersionsTab?: boolean;
}

interface PropsTitleErrorMessage {
  msg: string;
}

const maxCharacterCount = 30;

const Title: FC<Props> = ({
  headingWidgets = [],
  initialContent = '',
  isEditable = true,
  sectionId,
  subtitle = null,
  isVersionsTab = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );

  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(initialContent || '');

  const charsExceed = content?.length > maxCharacterCount;
  const isEmpty = content.trim()?.length === 0;
  const isNotValid = getSectionNames()?.includes(content.trim());

  useEffect(() => {
    resetContent();
  }, [initialContent]);

  function resetContent() {
    setContent(initialContent);
  }

  function handleCancelBtn() {
    setIsEdit(false);
    resetContent();
  }

  function handleSaveBtn() {
    sendGAEvent('extra_section_title_save_btn_clicked');
    if (content !== initialContent) {
      dispatch(ApplyChangeTitle({ title: content }, sectionId));
      history.replace({ pathname: '', hash: `#${content}` });
    }
    setIsEdit(false);
  }

  function getCharactersResume() {
    return `${content?.length || 0} / ${maxCharacterCount}`;
  }

  function getSectionNames() {
    if (!reportDetailData?.sections) return [];

    let sectionNames = [];
    reportDetailData.sections.forEach(section => {
      if (
        !['Country office story', 'Extra Section'].includes(
          section.custom_title,
        )
      ) {
        sectionNames.push(section.custom_title);
      }

      section.children_list?.forEach(sectionChild => {
        sectionNames.push(sectionChild.custom_title);
      });
    });
    return sectionNames;
  }

  const TitleErrorMessage: FC<PropsTitleErrorMessage> = ({ msg }) => {
    return (
      <div className="subtitle-footer subtitle-error-msg">
        <Icon icon={iconWarningSolid} fill="#E4002B" description="" />
        <div>{msg}</div>
      </div>
    );
  };

  if (isVersionsTab) {
    return (
      <p className="title">
        <strong>{initialContent}</strong>
      </p>
    );
  }

  return (
    <Module className="subtitle-section" data-test-id="title-section">
      <ModuleHeader data-test-id="section-title">
        <div className="subtitle-section-title">Title</div>
        {isEditable && (
          <div className="subtitle-section-btns">
            {isEdit ? (
              <>
                <Button onClick={handleCancelBtn} kind="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveBtn}
                  disabled={charsExceed || isEmpty || isNotValid}
                >
                  Save title
                </Button>
              </>
            ) : (
              <Button onClick={() => setIsEdit(true)}>Edit</Button>
            )}
          </div>
        )}
      </ModuleHeader>
      <ModuleBody>
        <div className="module-body-title-wrapper">
          {isEdit ? (
            <Input name="title">
              {() => (
                <input
                  value={content}
                  className="subtitle-input"
                  placeholder="Insert title"
                  onChange={val => setContent(val.currentTarget.value)}
                />
              )}
            </Input>
          ) : initialContent ? (
            <div className="title-content-wrapper">{initialContent}</div>
          ) : (
            <div className="subtitle-empty-info">No title</div>
          )}

          {!isEdit && (
            <div className="subtitle-wrapper">
              {subtitle?.title && <p className="subtitle">{subtitle.title}</p>}
              {subtitle?.description && (
                <aside className="subtitle-note">{subtitle.description}</aside>
              )}
              <p className="title">
                {headingWidgets.map((item, index) => (
                  <span key={index}>: {item.component}</span>
                ))}
              </p>
            </div>
          )}
        </div>
      </ModuleBody>
      {isEditable && (
        <ModuleFooter>
          {charsExceed ? (
            <TitleErrorMessage
              msg={`Character limit exceed: ${getCharactersResume()}`}
            />
          ) : isEmpty ? (
            <TitleErrorMessage msg="The title cannot be empty" />
          ) : isNotValid ? (
            <TitleErrorMessage msg="This title is already used for another section" />
          ) : (
            <div className="subtitle-footer">
              Character count: {getCharactersResume()}
            </div>
          )}
        </ModuleFooter>
      )}
    </Module>
  );
};

export default Title;
