import { call, put, takeEvery } from 'redux-saga/effects';

import {
  COMMENT_REPLY_REQUEST_API,
  COMMENT_REQUEST_API,
  RESTORE_COMMENT_REQUEST_API,
} from 'src/constants/index';
import {
  getActionAPISource,
  receiveAPIResponseHoldingData,
  requestAPIFailedHoldingData,
} from 'src/redux/actions';
import {
  getExtraNarrative,
  getMultiNarrative,
  getNarrative,
} from 'src/redux/report/actions';
import {
  COMMENTS_DETAIL,
  COMMENTS_LIST,
  REPORT_DETAIL_DATA_KEY,
  RESTORE_COMMENT,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { replaceParamsInUrl, showNotification } from 'src/utils';
import { requestAPIHoldingData } from '../actions';

export function* CommentApiCallSaga(action) {
  try {
    let url = getActionAPISource(COMMENTS_DETAIL);

    url = replaceParamsInUrl(url, action.params);

    const response = yield call(
      apiCallAsync,
      url,
      action.params.data,
      action.params.method,
    );

    if (response.status >= 200 && response.status < 300) {
      yield put(receiveAPIResponseHoldingData(response.data, COMMENTS_LIST));

      // Retireve and update report data
      yield put(
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: action.params.reportId },
        }),
      );

      // Retrieve and update narrative value if is needed
      if (action.params.refreshNarrative === 'main') {
        yield put(
          getNarrative({
            url_format: {
              _id_: action.params.url_format._section_,
            },
          }),
        );
      } else if (action.params.refreshNarrative === 'extra') {
        yield put(
          getExtraNarrative({
            url_format: {
              _id_: action.params.url_format._section_,
            },
          }),
        );
      } else if (action.params.refreshNarrative === 'multi') {
        yield put(
          getMultiNarrative({
            url_format: {
              section_pk: action.params.url_format._section_,
            },
          }),
        );
      }
    } else {
      throw 'Comment Update API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailedHoldingData(COMMENTS_LIST, {
        message: e.message,
        data: e.response ? e.response.data : null,
      }),
    );
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* watchCommentApiCallsSaga() {
  yield takeEvery(COMMENT_REQUEST_API, CommentApiCallSaga);
}

export function* CommentReplyApiCallSaga(action) {
  try {
    let url = getActionAPISource(action.params.storage_key);
    url = replaceParamsInUrl(url, action.params);

    const response = yield call(
      apiCallAsync,
      url,
      action.params.data,
      action.params.method,
    );

    if (response.status >= 200 && response.status < 300) {
      const { _comment_, ...rest } = action.params.url_format;

      const params = {
        url_format: rest,
        storage_key: COMMENTS_LIST,
      };

      yield put(requestAPIHoldingData(COMMENTS_LIST, null, params));
    } else {
      throw 'Comment Reply API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailedHoldingData(COMMENTS_LIST, {
        message: e.message,
        data: e.response ? e.response.data : null,
      }),
    );
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* watchCommentReplyApiCallsSaga() {
  yield takeEvery(COMMENT_REPLY_REQUEST_API, CommentReplyApiCallSaga);
}

export function* RestoreCommentApiCallSaga(action) {
  try {
    const url = getActionAPISource(RESTORE_COMMENT);

    const response = yield call(apiCallAsync, url, action.params.data, 'post');

    if (response.status >= 200 && response.status < 300) {
      const { _comment_, ...rest } = action.params.url_format;
      const params = {
        url_format: rest,
        storage_key: COMMENTS_LIST,
      };
      yield put(
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: action.params.url_format._report_ },
        }),
      );
      yield put(requestAPIHoldingData(COMMENTS_LIST, null, params));
    } else {
      throw 'Restore Comment API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailedHoldingData(RESTORE_COMMENT, {
        message: e.message,
        data: e.response ? e.response.data : null,
      }),
    );
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* watchRestoreCommentApiCallsSaga() {
  yield takeEvery(RESTORE_COMMENT_REQUEST_API, RestoreCommentApiCallSaga);
}
