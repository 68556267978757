import clone from 'lodash/clone';

import AdministrationDashboard from 'src/pages/AdministrationDashboard';
import IssueOverview from 'src/pages/IssuesOverview';
import NotFound from 'src/pages/NotFound';
import Report from 'src/pages/Report';
import ReportsList from 'src/pages/ReportsList';
import UsersManagement from 'src/pages/UsersManagement';

export const sectionPrefix = 'section-';

const _year_match = ':year(\\d{4})',
  _cpb_key_match = ':cpb([A-Z]{2}\\d{2})',
  _section_match = `:section(${sectionPrefix}.*)?`,
  _project = `${_year_match}/${_cpb_key_match}`,
  _section = `${_year_match}/${_cpb_key_match}/${_section_match}`;

export const routesConfig = {
  root: {
    path: `/`,
    name: 'Home',
    sub_routes: {
      admin_dashboard: {
        path: '/admin-dashboard',
        name: 'Administration Dashboard',
        component: AdministrationDashboard,
      },
      all_comments: {
        path: `/${_project}/comments`,
        name: 'View all narratives and comments',
        component: IssueOverview,
      },
      report_detail: {
        path: `/${_section}`,
        name: 'Detail',
        matchToName: (match, context = {}) => {
          const country = context.country || '';
          return `${match.params.cpb.toUpperCase()} ${country}`.trim();
        },
        component: Report,
      },
      reports_dashboard: {
        path: `/${_year_match}?`,
        name: 'Reports Dashboard',
        matchToName: match => `${match.params.year || ''}`,
        component: ReportsList,
      },
      users_management: {
        path: '/users-management',
        name: 'Users Management',
        component: UsersManagement,
      },
      not_found: {
        path: '/not-found',
        name: 'Not Found',
        component: NotFound,
      },
    },
  },
};

export function getAppRoutes(rootUri) {
  const result = clone(routesConfig);

  const normalizeUri = uri => `/${uri}/`.replace(/\/+/g, '/');
  const joinUri = array => normalizeUri(array.join('/'));

  const prepareRoutes = (routes, parentUri = joinUri([rootUri])) => {
    for (const key in routes) {
      let currentUri = parentUri;
      if (routes[key].hasOwnProperty('path')) {
        currentUri = joinUri([currentUri, routes[key].path]);
        routes[key].path = currentUri;
      }

      if (routes[key].hasOwnProperty('sub_routes')) {
        prepareRoutes(routes[key].sub_routes, currentUri);
      }
    }
  };
  prepareRoutes(result);
  return result;
}
