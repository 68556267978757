import { iconClose } from '@wfp/icons';
import { Icon, SingleDatePickerInput } from '@wfp/ui';
import moment from 'moment';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';

import ConfirmationModal from 'src/components/ConfirmationModal';
import TableMain from 'src/components/TableMain';
import { DATE_FORMAT } from 'src/constants';
import OperationIdCell from '../OperationIdCell';

const TRANSITIONS_ORDER = [
  'new',
  'initial_draft',
  'review',
  'cd_approval',
  'rd_approval',
  'rd_approved',
  'published',
];

const STATUS_TITLES = {
  New: 'new',
  'Initial Draft': 'initial_draft',
  Review: 'review',
  'CD Approval': 'cd_approval',
  'RD Approval': 'rd_approval',
  'RD Approved': 'rd_approved',
  Published: 'published',
};

const dataPickerConfig = {
  displayFormat: 'D-MMM-YYYY',
};

const falseFunc = () => false;

const isStatusLate = (currentStatus, statusToCheck, dueDate) => {
  const now = Date.now();
  const checkedStatusOrder = TRANSITIONS_ORDER.indexOf(statusToCheck);
  const currentStatusOrder = TRANSITIONS_ORDER.indexOf(
    STATUS_TITLES[currentStatus],
  );

  if (currentStatusOrder === -1 || checkedStatusOrder === -1) {
    console.error('unknown status', currentStatusOrder, checkedStatusOrder);
    return false;
  }
  return currentStatusOrder <= checkedStatusOrder && now >= new Date(dueDate);
};

const ProjectStatusTab = ({
  data,
  loading,
  filterText,
  filterSelect,
  handleDateChange,
  handleDateReset,
}) => {
  const [dateReset, setDateReset] = useState(null);

  function getReadableStatus(status) {
    return Object.keys(STATUS_TITLES).find(
      key => STATUS_TITLES[key] === status,
    );
  }

  const columns = [
    {
      Header: 'Operation ID',
      accessor: 'project_code',
      id: 'project_code_column',
      Filter: column => filterText(column),
      Cell: ({ row }) => <OperationIdCell row={row} />,
    },
    {
      Header: 'Regional Bureau',
      accessor: 'project_regional_bureau_display_code',
      id: 'rb_column',
      Filter: column =>
        filterSelect('project_regional_bureau_display_code', column),
    },
    {
      Header: 'CRF',
      accessor: 'crf',
      id: 'crf',
      Cell: ({ value }) => (value ? value : '-'),
      Filter: column => filterSelect('crf', column),
    },
    {
      Header: 'Country',
      accessor: 'project_country',
      id: 'project_country_column',
      filter: 'equals',
      Filter: column => filterSelect('project_country', column, true),
    },
    {
      Header: 'Current Status',
      accessor: 'current_status',
      id: 'current_status_column',
      Filter: column => filterSelect('current_status', column),
    },
    {
      Header: 'Initial Draft',
      accessor: 'initial_draft',
      id: 'initial_draft_column',
      className: 'date-picker-column',
      Cell: ({ row }) => {
        const mObj = moment(row.original.initial_draft, DATE_FORMAT);
        const date = mObj.isValid() ? mObj : null;
        let className = 'due-date ';
        if (date) {
          className += isStatusLate(
            row.original.current_status,
            'initial_draft',
            date,
          )
            ? 'late'
            : 'in-time';
        }
        return (
          <div className={className}>
            <SingleDatePickerInput
              {...dataPickerConfig}
              disabled={loading}
              labelText=""
              datePicker={SingleDatePicker}
              value={date}
              className={className}
              isOutsideRange={falseFunc}
              onChange={e => {
                handleDateChange(e, 'initial_draft', row.original.pk);
              }}
            />
            {date && (
              <div
                onClick={() => {
                  setDateReset({
                    status: 'initial_draft',
                    pk: row.original.pk,
                    date,
                    projectName: row.original.project_code,
                  });
                }}
                className="reset-date-btn"
              >
                <Icon icon={iconClose} description="" />
              </div>
            )}
          </div>
        );
      },
      Filter: column => filterSelect('initial_draft', column),
    },
    {
      Header: 'Review',
      accessor: 'review',
      id: 'review_column',
      className: 'date-picker-column',
      Cell: ({ row }) => {
        const mObj = moment(row.original.review, DATE_FORMAT);
        const date = mObj.isValid() ? mObj : null;
        let className = 'due-date ';
        if (date) {
          className += isStatusLate(row.original.current_status, 'review', date)
            ? 'late'
            : 'in-time';
        }
        return (
          <div className={className}>
            <SingleDatePickerInput
              {...dataPickerConfig}
              labelText=""
              disabled={loading}
              datePicker={SingleDatePicker}
              value={date}
              className={className}
              isOutsideRange={falseFunc}
              onChange={e => handleDateChange(e, 'review', row.original.pk)}
            />
            {date && (
              <div
                onClick={() =>
                  setDateReset({
                    status: 'review',
                    pk: row.original.pk,
                    date,
                    projectName: row.original.project_code,
                  })
                }
                className="reset-date-btn"
              >
                <Icon icon={iconClose} description="" />
              </div>
            )}
          </div>
        );
      },
      Filter: column => filterSelect('review', column),
    },
    {
      Header: 'CD Approval',
      accessor: 'cd_approval',
      id: 'cd_approval_column',
      className: 'date-picker-column',
      Cell: ({ row }) => {
        const mObj = moment(row.original.cd_approval, DATE_FORMAT);
        const date = mObj.isValid() ? mObj : null;
        let className = 'due-date ';
        if (date) {
          className += isStatusLate(
            row.original.current_status,
            'cd_approval',
            date,
          )
            ? 'late'
            : 'in-time';
        }
        return (
          <div className={className}>
            <SingleDatePickerInput
              {...dataPickerConfig}
              labelText=""
              disabled={loading}
              datePicker={SingleDatePicker}
              value={date}
              className={className}
              isOutsideRange={falseFunc}
              onChange={e =>
                handleDateChange(e, 'cd_approval', row.original.pk)
              }
            />
            {date && (
              <div
                onClick={() =>
                  setDateReset({
                    status: 'cd_approval',
                    pk: row.original.pk,
                    date,
                    projectName: row.original.project_code,
                  })
                }
                className="reset-date-btn"
              >
                <Icon icon={iconClose} description="" />
              </div>
            )}
          </div>
        );
      },
      Filter: column => filterSelect('cd_approval', column),
    },
    {
      Header: 'RD Approval',
      accessor: 'rd_approval',
      id: 'rd_approval_column',
      className: 'date-picker-column',
      Cell: ({ row }) => {
        const mObj = moment(row.original.rd_approval, DATE_FORMAT);
        const date = mObj.isValid() ? mObj : null;
        let className = 'due-date ';
        if (date) {
          className += isStatusLate(
            row.original.current_status,
            'rd_approval',
            date,
          )
            ? 'late'
            : 'in-time';
        }
        return (
          <div className={className}>
            <SingleDatePickerInput
              {...dataPickerConfig}
              labelText=""
              disabled={loading}
              datePicker={SingleDatePicker}
              value={date}
              className={className}
              isOutsideRange={falseFunc}
              onChange={e =>
                handleDateChange(e, 'rd_approval', row.original.pk)
              }
            />
            {date && (
              <div
                onClick={() =>
                  setDateReset({
                    status: 'rd_approval',
                    pk: row.original.pk,
                    date,
                    projectName: row.original.project_code,
                  })
                }
                className="reset-date-btn"
              >
                <Icon icon={iconClose} description="" />
              </div>
            )}
          </div>
        );
      },
      Filter: column => filterSelect('rd_approval', column),
    },
  ];

  return (
    <>
      <TableMain
        data={data}
        columns={columns}
        pagination="client"
        className="-striped -highlight"
        loading={loading}
        isFilterable
        initialState={{
          sortBy: [
            {
              id: 'project_country_column',
              desc: false,
            },
          ],
        }}
      />
      {dateReset !== null && (
        <ConfirmationModal
          open={true}
          onSubmit={() => {
            handleDateReset(dateReset.status, dateReset.pk);
            setDateReset(null);
          }}
          onClose={() => setDateReset(null)}
          heading={`Clear '${getReadableStatus(dateReset.status)}' date for ${
            dateReset.projectName
          } ?`}
          content={
            <>
              <div className="revert-version-subtitle">
                {`Do you really want to clear date ${dateReset.date.format(
                  'DD-MMM-YYYY',
                )} ?`}
              </div>
            </>
          }
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
    </>
  );
};

export default ProjectStatusTab;
