import React, { FC } from 'react';

import { sendGAEvent } from 'src/utils';
import { ContextMenuGroup, ContextMenuItem } from '@wfp/ui';
import { gaParamPosition } from 'src/types';

interface Props {
  exportContent: (commentsOnly: boolean, narrativesOnly: boolean) => void;
  gaPosition: gaParamPosition;
}

const IssuesOverviewContextualMenu: FC<Props> = ({ exportContent }) => {
  return (
    <>
      <ContextMenuGroup>
        <ContextMenuItem
          onClick={() => {
            sendGAEvent('comments_file_summary_generated', {
              wfp_position: 'comments_page',
              wfp_type: 'narratives_and_comments',
            });
            exportContent(false, false);
          }}
        >
          Narratives & Comments
        </ContextMenuItem>
      </ContextMenuGroup>
      <ContextMenuGroup>
        <ContextMenuItem
          onClick={() => {
            sendGAEvent('comments_file_summary_generated', {
              wfp_position: 'comments_page',
              wfp_type: 'narratives',
            });
            exportContent(false, true);
          }}
        >
          Only Narratives
        </ContextMenuItem>
      </ContextMenuGroup>
      <ContextMenuGroup className="io-context-menu-last-item">
        <ContextMenuItem
          onClick={() => {
            sendGAEvent('comments_file_summary_generated', {
              wfp_position: 'comments_page',
              wfp_type: 'comments',
            });
            exportContent(true, false);
          }}
        >
          Only Comments
        </ContextMenuItem>
      </ContextMenuGroup>
    </>
  );
};

export default IssuesOverviewContextualMenu;
