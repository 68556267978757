import React, { Component } from 'react';
import { connect } from 'react-redux';

import { API_HOST } from 'src/constants';
import { requestAPI } from 'src/redux/actions';
import { GET_ALL_REPORTS_PERIODS } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import AdministrationDashboardHeader from './AdministrationDashboardHeader';
import AdministrationDashboardTable from './AdministrationDashboardTable';
import AdministrationDashboardTableFilters from './AdministrationDashboardTableFilters';

export class AdministrationDashboardComponent extends Component {
  state = {
    data: {},
    sorted: [],
    selectedProjects: [],
    period: '',
    loading: false,
    filterSets: {
      bureauFilters: [],
      countryFilters: [],
      projectTypeFilters: [],
      statusFilters: [],
    },
    currentPage: 1,
    activeFilters: {
      bureauFilters: null,
      countryFilters: null,
      projectTypeFilters: null,
      statusFilters: null,
    },
  };
  defaultPageSize = 10;

  getSelectedProjects = selectedProjects => {
    this.setState({ selectedProjects });
  };

  performAction = async action => {
    const { selectedProjects, period } = this.state;
    this.setState({ loading: true });

    const url = `${API_HOST}/acr-api/administration-dashboard-list/`;
    const data = {
      action: action,
      projects: selectedProjects,
      period: period,
    };
    return await apiCallAsync(url, data, 'post');
  };

  getFilterSet = filterSets => {
    this.setState({ filterSets });
  };

  generateProjects = () => {
    this.performAction('generate').then(() => {
      this.triggerUpdate();
    });
  };

  activateProjects = () => {
    this.performAction('activate').then(() => {
      this.triggerUpdate();
    });
  };

  setPeriod = period => {
    this.setState({ period }, this.triggerUpdate);
  };

  getFilteredReports = async (
    page,
    period,
    activeFilters,
    pageSize,
    sorted,
  ) => {
    const newState = { loading: true };
    if (typeof sorted === 'undefined') {
      sorted = this.state.sorted;
    } else {
      newState.sorted = sorted;
    }
    const orderingParam = sorted
      .map(item => `${item.id}=${item.desc ? 0 : 1}`)
      .join(';');
    this.setState(newState);

    const apiURL = `${API_HOST}/acr-api/administration-dashboard-list/`;
    const params = {
      page,
      period,
      filters: activeFilters,
      pageSize: pageSize || this.defaultPageSize,
      order: orderingParam,
    };

    return apiCallAsync(apiURL, params).then(response => {
      const data = response.data;
      const filterSet = {
        bureauFilters: data.projects.map(item => item.project.bureau),
        countryFilters: data.projects.map(item => item.project.country),
        projectTypeFilters: data.projects.map(
          item => item.project.project_type,
        ),
        statusFilters: data.projects.map(item => item.project.status),
      };

      if (this.state.period === '') {
        this.setPeriod(data.period);
      }
      this.getFilterSet(filterSet);
      this.setState({ loading: false, data });
      return data;
    });
  };

  triggerUpdate = () => {
    const { period, activeFilters, currentPage } = this.state;
    this.getFilteredReports(currentPage, period, activeFilters);
  };

  filterSelectCallBack = () => {
    const { period, activeFilters, currentPage } = this.state;

    this.getFilteredReports(currentPage, period, activeFilters).then(resp => {
      const projects = resp.projects;
      const filterSet = {
        bureauFilters: projects.map(item => item.project.bureau),
        countryFilters: projects.map(item => item.project.country),
        projectTypeFilters: projects.map(item => item.project.project_type),
        statusFilters: projects.map(item => item.project.status),
      };
      this.getFilterSet(filterSet);
    });
  };

  onFilterSelect = (filterBy, choice) => {
    this.setState(state => {
      const newState = { ...state };
      newState.activeFilters[filterBy] = choice;
      return newState;
    }, this.filterSelectCallBack);
  };

  setCurrentPage = page => {
    this.setState({ currentPage: page });
  };

  provideInitialSelectedData = data => {
    const result = {
      selected: [],
      enabledCO: [],
      structureSelected: [],
    };
    if (data) {
      const { projects = [], structures = [] } = data;
      result.selected = projects.map(item => {
        return {
          project: item.project.pk,
          selected: false,
        };
      });
      result.enabledCO = projects.map(item => {
        return {
          project: item.project.pk,
          selected: false,
        };
      });
      result.structureSelected = projects.map(item => {
        return {
          project: item.project.pk,
          structure: structures[0].pk,
        };
      });
    }
    return result;
  };

  componentDidMount() {
    this.props.dispatchFetch[GET_ALL_REPORTS_PERIODS]();
  }

  render() {
    const { loading, filterSets, period, activeFilters, data } = this.state;

    return (
      <section className="administration-dashboard">
        <AdministrationDashboardHeader
          generateProjects={this.generateProjects}
          activateProjects={this.activateProjects}
          setPeriod={this.setPeriod}
          loading={loading}
          period={period}
          periods={this.props.periods.data}
        />

        <AdministrationDashboardTableFilters
          filterSets={filterSets}
          onFilterSelect={this.onFilterSelect}
        />

        <AdministrationDashboardTable
          data={data}
          loading={loading}
          getFilteredReports={this.getFilteredReports}
          getPeriod={this.setPeriod}
          setCurrentPage={this.setCurrentPage}
          selectedData={this.provideInitialSelectedData(data)}
          activeFilters={activeFilters}
          period={period}
          getSelectedProjects={this.getSelectedProjects}
          defaultPageSize={this.defaultPageSize}
        />
      </section>
    );
  }
}

export const mapStateToProps = state => {
  let periods = [];
  try {
    periods = state.viewData[GET_ALL_REPORTS_PERIODS] || periods;
  } catch (e) {
    console.log('Error', e);
  }
  return {
    periods: periods,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    dispatchFetch: {
      [GET_ALL_REPORTS_PERIODS]: () => {
        dispatch(requestAPI(GET_ALL_REPORTS_PERIODS, null, {}));
      },
    },
  };
};

const AdministrationDashboardTemplate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdministrationDashboardComponent);
export default AdministrationDashboardTemplate;
