import { Checkbox } from '@wfp/ui';
import _ from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';

import TableMain from 'src/components/TableMain';

export default class AdministrationDashboardTable extends Component {
  state = {
    projects: [],
    structures: [],
    selectAll: false,
    selected: [],
    enabledCO: [],
    structureSelected: [],
  };

  handleSelectAll = () => {
    this.setState(
      state => {
        let result = [];
        if (!state.selectAll) {
          result = this.props.data.projects.map(item => {
            return {
              project: item.project.pk,
              selected: true,
            };
          });
        } else {
          result = this.props.data.projects.map(item => {
            return {
              project: item.project.pk,
              selected: false,
            };
          });
        }
        return { selectAll: !state.selectAll, selected: result };
      },
      () => this.getSelectedProjectsData(),
    );
  };

  handleSingleCheckboxChange = (pk, stateKey) => {
    this.setState(
      state => {
        const idx = state[stateKey].findIndex(el => el.project === pk);
        const oldItem = state[stateKey][idx];

        const newItem = { ...oldItem, selected: !oldItem.selected };
        const result = [
          ...state[stateKey].slice(0, idx),
          newItem,
          ...state[stateKey].slice(idx + 1),
        ];
        return { [stateKey]: result };
      },
      () => this.getSelectedProjectsData(),
    );
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedData, this.props.selectedData)) {
      this.setState({
        selected: this.props.selectedData.selected,
        enabledCO: this.props.selectedData.enabledCO,
        structureSelected: this.props.selectedData.structureSelected,
      });
    }
  }

  onStructureSelect = e => {
    const id = parseInt(e.pk);
    const choice = parseInt(e.value);
    this.setState(
      state => {
        const idx = state.structureSelected.findIndex(el => el.project === id);
        const oldItem = state.structureSelected[idx];
        const newItem = { ...oldItem, structure: choice };
        const result = [
          ...state.structureSelected.slice(0, idx),
          newItem,
          ...state.structureSelected.slice(idx + 1),
        ];

        return { structureSelected: result };
      },
      () => {
        this.getSelectedProjectsData();
      },
    );
  };

  getStructureDropdown = pk => {
    const structures = this.state.structures.map(item => {
      return {
        value: item.pk,
        label: item.name,
        pk: pk,
      };
    });
    const selected_structure = structures.find(
      structure => structure.value === this.getStructureSelected(pk),
    );
    return (
      <Select
        className="admin-dashboard-react-select admin-dashboard-structure-react-select"
        classNamePrefix="wfp--react-select"
        options={structures}
        defaultValue={selected_structure}
        id={`structureSelectId-${pk}`}
        key={`structureSelectId-${pk}`}
        onChange={structure => {
          this.onStructureSelect(structure);
        }}
      />
    );
  };

  fetchData = async ({ pageIndex, pageSize }) => {
    const { activeFilters, period, setCurrentPage } = this.props;
    const sorted = [];
    const currentPage = pageIndex;
    setCurrentPage(currentPage);
    const result = await this.props.getFilteredReports(
      currentPage,
      period,
      activeFilters,
      pageSize,
      sorted,
    );
    const { projects = [], structures = [] } = result;
    this.setState({
      projects,
      structures,
      selectAll: false,
    });
  };

  getSelectedProjectsData = () => {
    const { selected, structureSelected, enabledCO } = this.state;
    const result = [];
    const reports = selected
      .filter(item => item.selected)
      .map(item => item.project);
    reports.forEach(project => {
      result.push({
        project,
        structure: structureSelected.filter(item => item.project === project)[0]
          .structure,
        enabledCO: enabledCO.filter(item => item.project === project)[0]
          .selected,
      });
    });
    this.props.getSelectedProjects(result);
  };

  getStructureSelected = pk => {
    const { projects = [] } = this.props.data;

    if (
      projects.filter(
        item => item.project.pk === pk && item.project.structure_used,
      ).length
    ) {
      const project = this.state.projects.find(
        item => item.project.pk === pk && item.project.structure_used,
      );
      const structure_name = project ? project.project.structure_used : '';
      const structure_selected = this.state.structures.find(
        item => item.name === structure_name,
      );
      return structure_selected ? structure_selected.pk : '';
    } else {
      const structure_selected = this.state.structureSelected.find(
        item => item.project === pk,
      );

      return structure_selected ? structure_selected.structure : '';
    }
  };

  checkSelected = (pk, stateKey, field) => {
    const result = this.state[stateKey].filter(item => {
      return item.project === pk;
    });

    return result[0] && field in result[0] ? result[0][field] : false;
  };

  render() {
    let { projects = [] } = this.props.data;
    const { numPages = 1, projectsNumber = 0 } = this.props.data;

    projects = projects.map(item => item.project);
    return (
      <TableMain
        className={`-striped ${this.props.loading ? 'loading-data' : ''}`}
        data={projects}
        pageCount={numPages + 1}
        pagination="server"
        loading={this.props.loading}
        totalItems={projectsNumber}
        fetchData={this.fetchData}
        columns={[
          {
            Header: (
              <Checkbox
                labelText=""
                id="select-all-checkbox"
                checked={this.state.selectAll}
                onChange={this.handleSelectAll}
              />
            ),
            Cell: ({ row }) => {
              return (
                <Checkbox
                  labelText=""
                  id={`select-project-${row.index}`}
                  checked={this.checkSelected(
                    row.original.pk,
                    'selected',
                    'selected',
                  )}
                  onChange={() => {
                    this.handleSingleCheckboxChange(
                      row.original.pk,
                      'selected',
                    );
                  }}
                />
              );
            },
            width: 30,
            sortable: false,
            filterable: false,
            resizable: false,
            id: 'select_column',
          },
          {
            Header: 'Operation ID',
            accessor: 'code',
            width: 50,
            id: 'operation_id_column',
          },
          {
            Header: 'Regional Bureau',
            accessor: 'bureau',
            sortable: false,
            width: 200,
            id: 'bureau_column',
          },
          {
            Header: 'Category',
            accessor: 'category',
            width: 70,
            id: 'category_column',
          },
          {
            Header: 'Project Type',
            accessor: 'project_type',
            width: 150,
            id: 'project_type_column',
          },
          {
            Header: 'Country',
            accessor: 'country',
            id: 'country_column',
            width: 70,
          },
          {
            Header: 'Structure',
            accessor: 'structure_used',
            sortable: false,
            width: 120,
            id: 'structure_column',
          },
          {
            Header: 'Structure select',
            show: false,
            accessor: 'fff',
            sortable: false,
            id: 'hq-admin-structure-column',
            className: 'hq-admin-structure-column hidden',
            width: 100,
            Cell: ({ row }) => {
              return this.getStructureDropdown(row.original.pk);
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            sortable: false,
            Cell: props => {
              return <span className="status-label">{props.value}</span>;
            },
            id: 'status_column',
          },
        ]}
      />
    );
  }
}
