import {
  iconChat,
  iconCopy,
  iconDocument,
  iconLaunch,
  iconRestart,
} from '@wfp/icons';
import { Button, Icon, Module, ModuleBody } from '@wfp/ui';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import { DisplayToggleWrapper } from 'src/components/DisplayWrapper';
import {
  changeViewStateAction,
  getHistoryNarrative,
} from 'src/redux/report/actions';
import {
  COMMENTS_TARGET_ID,
  NARRATIVE,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
  VERSIONS,
} from 'src/redux/report/constants';
import { buildErrorComponents, sendGAEvent, showNotification } from 'src/utils';

export const SectionControlsComponent = ({
  hasNarrative,
  guidanceLink = '',
  error,
  dataSourceRefresh,
  activeSectionID,
  activeClickedTab,
  reportState,
  hasReport,
  dataSourceRefreshAllowed,
  handleRefreshDataSources,
}) => {
  const dispatch = useDispatch();

  const RefreshDataSourceButton = () => {
    const title = dataSourceRefresh.isFetching
      ? 'Refreshing data-sources...'
      : 'Refresh data sources';

    return (
      <Button
        onClick={() => {
          sendGAEvent('refresh_data_sources_btn_clicked');
          handleRefreshDataSources();
        }}
        title={title}
        data-test-id="refresh-data-sources-button"
        disabled={dataSourceRefresh.isFetching}
      >
        <Icon icon={iconRestart} description="" {...{ width: '1.2em' }} />
        {title}
      </Button>
    );
  };

  const errorsList = useMemo(() => {
    const commonErrorComponents = buildErrorComponents(error);
    const refreshDataSetErrorComponents = buildErrorComponents(
      dataSourceRefresh?.error,
    );

    const notifications = [];

    for (const errors of [
      commonErrorComponents,
      refreshDataSetErrorComponents,
    ]) {
      if (errors.length > 0) {
        notifications.push(errors);
      }
    }
    return notifications;
  }, [error, dataSourceRefresh?.error]);

  useEffect(() => {
    if (errorsList.length > 0) {
      showNotification(
        errorsList.map(error => error),
        'error',
        {
          autoClose: false,
          closeOnClick: false,
        },
      );
    }
  }, [errorsList]);

  function getNarrativeHistory() {
    dispatch(getHistoryNarrative(activeSectionID));
    dispatch(
      changeViewStateAction({
        activeClickedTab: VERSIONS,
        isTitleChanging: false,
        currentTitle: '',
      }),
    );
  }

  function onChangeNarrativeClicked() {
    dispatch(changeViewStateAction({ activeClickedTab: NARRATIVE }));
  }

  return (
    <Module className="special-module-title section-controls">
      <ModuleBody>
        {!!hasNarrative && activeClickedTab === VERSIONS ? (
          <Button
            onClick={() => onChangeNarrativeClicked()}
            data-test-id="narrative-button"
          >
            <Icon icon={iconDocument} description="" width="2em" />
            Narrative
          </Button>
        ) : (
          <Button
            onClick={() => {
              sendGAEvent('narrative_versions_btn_clicked');
              getNarrativeHistory();
            }}
            data-test-id="versions-button"
          >
            <Icon icon={iconCopy} description="" width="2em" />
            Versions
          </Button>
        )}
        <DisplayToggleWrapper
          targetId={COMMENTS_TARGET_ID}
          component={Button}
          title="Comments"
          data-test-id="show-comments-button"
        >
          <Icon icon={iconChat} description="Comments" />
          Show comments
        </DisplayToggleWrapper>

        <Button
          href={guidanceLink}
          target="_blank"
          title="ACR Guidance link"
          data-test-id="ACR Guidance link"
          onClick={() =>
            sendGAEvent('acr_guidance_link_clicked', { wfp_position: 'header' })
          }
        >
          <Icon icon={iconLaunch} description="ACR Guidance" />
          ACR Guidance
        </Button>
        {dataSourceRefreshAllowed &&
          hasReport &&
          reportState !== 'Published' && <RefreshDataSourceButton />}
      </ModuleBody>
    </Module>
  );
};

export const mapStateToProps = state => {
  const sectionData = state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data;

  return {
    hasNarrative: sectionData?.narrative,
    guidanceLink: sectionData?.guidance_link,
    activeSectionID: state.viewState.activeSectionID,
    activeClickedTab: state.viewState.activeClickedTab,
    reportState: state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data?.state,
    hasReport: !!state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data,
    dataSourceRefreshAllowed: sectionData?.data_source_refresh_allowed,
  };
};

const SectionControls = connect(mapStateToProps)(SectionControlsComponent);

export default SectionControls;
