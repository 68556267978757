import {
  TotalCashComponent,
  TotalComodityVouchersComponent,
  TotalFoodComponent,
} from 'acr_ui/sources/components/dataPresentation/totalFoodAndCBT';
import { TransfersByModalitiesComponent } from 'acr_ui/sources/components/dataPresentation/transfersByModality';
import React from 'react';

const TotalBenByModWrapper = props => {
  if (parseInt(props.report.period) >= 2022) {
    return <TransfersByModalitiesComponent data={props.data} />;
  }
  return (
    <section className="total-food-n-cbt-wrapper">
      <TotalFoodComponent
        data={props.data.food_and_report_block_food_mt || {}}
        currentPeriod={props.currentPeriod}
      />
      <TotalCashComponent
        data={props.data['annual-cbt-distribution'] || {}}
        currentPeriod={props.currentPeriod}
      />
      <TotalComodityVouchersComponent
        data={props.data['annual-cbt-distribution']}
        currentPeriod={props.currentPeriod}
      />
    </section>
  );
};

export default TotalBenByModWrapper;
