import { Button, InlineLoading, Modal } from '@wfp/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fileNameMap, publicationUploadMaxSize } from 'src/constants';
import {
  getActionAPISource,
  receiveAPIResponseHoldingData,
} from 'src/redux/actions';
import {
  PDF_MERGE_LIST,
  REPORT_LIST_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { calcMergeStatus, showNotification } from 'src/utils';
import ACRUploadErrorMessage from '../PublicationStatusTab/ACRUploadErrorMessage';
import ACRUploadNotificationError from '../PublicationStatusTab/ACRUploadNotificationError';

const ACRMultiFilesUploadButton = ({ period }) => {
  const [modal, setModal] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);
  const [filesSelectedError, setFilesSelectedError] = useState(null);

  const dispatch = useDispatch();

  const publicationList = useSelector(
    state => state.viewData?.ACR_REPORTS_LIST?.data,
  );

  const projectsCodesList = useMemo(() => {
    if (publicationList && publicationList?.length > 0) {
      return publicationList?.map(item => item.project_code);
    }

    return [];
  }, [publicationList]);

  const doneProjectsCodesList = useMemo(() => {
    if (publicationList && publicationList?.length > 0) {
      return publicationList?.map(item => {
        if (calcMergeStatus(item) === 'Done') return item.project_code;
      });
    }

    return [];
  }, [publicationList]);

  useEffect(() => {
    if (!modal) {
      setFilesSelected([]);
      setFilesSelectedError(null);
      setFilesUploading(false);
    }
  }, [modal]);

  function handleFilesChange(e) {
    const newFiles = e.target.files;

    if (!newFiles || newFiles.length === 0) return;

    let fileNamesErr = '';
    let filename = '';

    const files = [];

    for (const key in newFiles) {
      const newFile = newFiles[key];

      if (key === 'length') break;

      filename = newFile?.name;

      const fileFormat = newFile?.name?.split('.').pop();
      const fileName = newFile?.name?.split('.').shift();

      if (fileFormat !== 'pdf') {
        fileNamesErr = 'file_type';
        break;
      }

      if (newFile?.size / 1024 / 1024 > publicationUploadMaxSize) {
        fileNamesErr = 'file_size';
        break;
      }

      if (!fileName.includes(' ')) {
        fileNamesErr = 'file_name';
        break;
      }

      const fileNameSplitted = fileName.split(' ');
      if (!projectsCodesList.includes(fileNameSplitted[0])) {
        fileNamesErr = 'file_name_project_id';
        break;
      }

      if (doneProjectsCodesList.includes(fileNameSplitted[0])) {
        fileNamesErr = 'file_name_already_merged';
        break;
      }

      if (!fileNameSplitted[1].includes('-')) {
        fileNamesErr = 'file_name';
        break;
      }
      const secondPartSplitted = fileNameSplitted[1].split('-');
      if (
        secondPartSplitted[0] !== fileNameMap['acr_1'] &&
        secondPartSplitted[0] !== fileNameMap['acr_5']
      ) {
        fileNamesErr = 'file_name';
        break;
      }
      if (secondPartSplitted[1] !== 'B') {
        fileNamesErr = 'file_name';
        break;
      }

      files.push(newFile);
    }

    if (fileNamesErr) {
      setFilesSelectedError({
        error: fileNamesErr,
        filename,
      });
      return;
    }

    setFilesSelectedError(null);
    setFilesSelected(files);
  }

  async function handleFilesUpload() {
    setFilesUploading(true);

    const formData = new FormData();

    formData.append('multi', true);
    formData.append('period', period);
    filesSelected.forEach(file => {
      formData.append('files', file);
    });

    const url = getActionAPISource(PDF_MERGE_LIST);

    try {
      const publicationList = await apiCallAsync(url, formData, 'post');

      dispatch(
        receiveAPIResponseHoldingData(
          publicationList.data,
          REPORT_LIST_DATA_KEY,
        ),
      );

      setFilesUploading(false);
      showNotification('Files uploaded successfully', 'success');
      setModal(false);
    } catch (err) {
      console.error(err);

      const errorsList = err?.response?.data;

      if (errorsList) {
        showNotification(
          <ACRUploadNotificationError errors={errorsList} />,
          'error',
        );
      } else {
        showNotification('Error on files upload. Please retry later', 'error');
      }

      setFilesUploading(false);
      setModal(false);
    }
  }

  return (
    <>
      <Button
        onClick={() => setModal(true)}
        data-test-id="multiple-file-upload-button"
      >
        Multiple upload
      </Button>
      {modal && (
        <Modal
          className="acr-file-upload-modal"
          open={true}
          hideClose
          modalLabel="Multiple file upload"
          onRequestClose={() => null}
          secondaryButtonText="Cancel"
          onSecondarySubmit={() => setModal(false)}
          secondaryButtonDisabled={false}
          primaryButtonText="Upload"
          onRequestSubmit={handleFilesUpload}
          primaryButtonDisabled={
            filesSelected?.length <= 0 || !!filesSelectedError
          }
        >
          <input
            type="file"
            hidden
            onChange={handleFilesChange}
            multiple
            accept="application/pdf"
            id="acr-multi-upload"
          />
          <label htmlFor="acr-multi-upload" className="acr-upload-label">
            Choose files
          </label>
          {filesUploading && (
            <InlineLoading
              className="acr-file-upload-loader"
              description="Uploading..."
            />
          )}
          {filesSelectedError && (
            <ACRUploadErrorMessage
              filename={filesSelectedError?.filename}
              error={filesSelectedError?.error}
            />
          )}
          {filesSelected?.length > 0 && !filesSelectedError && (
            <div className="acr-file-selected">
              <p>Files selected:</p>
              {filesSelected?.map((item, index) => (
                <div key={`${item.name}-${index}`}>{item?.name}</div>
              ))}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ACRMultiFilesUploadButton;
