import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CANCEL_MULTINARRATIVE_ACTION,
  FORCE_UNLOCK_MULTINARRATIVE_ACTION,
  LOCK_MULTINARRATIVE_ACTION,
  MULTI_NARRATIVE_CANCEL,
  MULTI_NARRATIVE_FORCE_UNLOCK,
  MULTI_NARRATIVE_LOCK,
  MULTI_NARRATIVE_UPDATE,
  MULTI_NARRATIVE_VERSION_RESTORE,
  RESTORE_VERSION_MULTINARRATIVE_ACTION,
  SAVE_MULTINARRATIVE_ACTION,
} from 'src/constants';
import {
  cancelMultiNarrativeSuccess,
  getActionAPISource,
  lockMultiNarrative,
  lockMultiNarrativeSuccess,
  requestAPIHoldingData,
  restoreVersionMultiNarrativeSuccess,
  saveMultiNarrativeSuccess,
} from 'src/redux/actions';
import { REPORT_DETAIL_DATA_KEY } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

export function* LockMultiNarrativeApiCallSaga(action) {
  try {
    const url = getActionAPISource(MULTI_NARRATIVE_LOCK).replace(
      'narrative_pk',
      action.narrativeId,
    );

    const response = yield call(apiCallAsync, url, {}, 'post');

    yield put(
      requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
        url_format: { _id_: action.reportId },
      }),
    );

    yield put(
      lockMultiNarrativeSuccess({
        narrativeId: action.narrativeId,
        editor: response.data.editor,
        content: response.data.content,
      }),
    );
  } catch (e) {
    console.error(e);

    const msg = e.response?.data?.error;
    if (!msg) return;

    showNotification(
      <>
        {msg}
        <br />
        Please refresh the page
      </>,
      'error',
    );
  }
}

export function* CancelMultiNarrativeApiCallSaga(action) {
  try {
    const url = getActionAPISource(MULTI_NARRATIVE_CANCEL).replace(
      'narrative_pk',
      action.narrativeId,
    );

    const response = yield call(apiCallAsync, url, {}, 'put');

    yield put(
      requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
        url_format: { _id_: action.reportId },
      }),
    );

    yield put(
      cancelMultiNarrativeSuccess({
        value: response.data,
      }),
    );
  } catch (e) {
    console.error(e);

    const msg = e.response?.data?.error;
    if (!msg) return;

    showNotification(
      <>
        {msg}
        <br />
        Please refresh the page
      </>,
      'error',
    );
  }
}

export function* ForceUnlockMultiNarrativeApiCallSaga(action) {
  try {
    const url = getActionAPISource(MULTI_NARRATIVE_FORCE_UNLOCK).replace(
      'narrative_pk',
      action.narrativeId,
    );

    yield call(apiCallAsync, url, {}, 'post');

    yield put(
      lockMultiNarrative({
        narrativeId: action.narrativeId,
        reportId: action.reportId,
      }),
    );
  } catch (e) {
    console.error(e);

    const msg = e.response?.data?.error;
    if (!msg) return;

    showNotification(
      <>
        {msg}
        <br />
        Please refresh the page
      </>,
      'error',
    );
  }
}

export function* SaveMultiNarrativeApiCallSaga(action) {
  try {
    const { narrativeId, content, wordCount, autoSave } = action;

    const url = getActionAPISource(MULTI_NARRATIVE_UPDATE).replace(
      'narrative_pk',
      narrativeId,
    );

    const params = {
      content,
      word_count: wordCount,
      autosave: autoSave,
    };

    const response = yield call(apiCallAsync, url, params, 'put');

    if (!autoSave) {
      yield put(
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: action.reportId },
        }),
      );
    }

    yield put(
      saveMultiNarrativeSuccess({
        value: response.data,
        autosave: autoSave,
      }),
    );
  } catch (e) {
    console.error(e);

    const msg = e.response?.data?.error;
    if (!msg) return;

    showNotification(
      <>
        {msg}
        <br />
        Please refresh the page
      </>,
      'error',
    );
  }
}

export function* RestoreVersionMultiNarrativeApiCallSaga(action) {
  try {
    const { narrativeVersionId, content, narrativeId } = action;

    const url = getActionAPISource(MULTI_NARRATIVE_VERSION_RESTORE).replace(
      'narrative_version_pk',
      narrativeVersionId,
    );

    yield call(apiCallAsync, url, {}, 'post');

    yield put(
      restoreVersionMultiNarrativeSuccess({
        content,
        narrativeId,
      }),
    );
  } catch (e) {
    console.error(e);

    const msg = e.response?.data?.error;
    if (!msg) return;

    showNotification(
      <>
        {msg}
        <br />
        Please refresh the page
      </>,
      'error',
    );
  }
}

export function* narrativeSaga() {
  yield takeLatest(LOCK_MULTINARRATIVE_ACTION, LockMultiNarrativeApiCallSaga);
  yield takeLatest(
    CANCEL_MULTINARRATIVE_ACTION,
    CancelMultiNarrativeApiCallSaga,
  );
  yield takeLatest(
    FORCE_UNLOCK_MULTINARRATIVE_ACTION,
    ForceUnlockMultiNarrativeApiCallSaga,
  );
  yield takeLatest(SAVE_MULTINARRATIVE_ACTION, SaveMultiNarrativeApiCallSaga);
  yield takeLatest(
    RESTORE_VERSION_MULTINARRATIVE_ACTION,
    RestoreVersionMultiNarrativeApiCallSaga,
  );
}
