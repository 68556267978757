import sortBy from 'lodash/sortBy';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

import { CRF23 } from 'src/constants';
import { changeViewStateAction } from 'src/redux/report/actions';
import { SECTION_DETAIL_DATA_KEY } from 'src/redux/report/constants';
import CommentListFilterSet from './CommentListFilterSet';
import CommentListItem from './CommentListItem';

export const CommentList = ({
  user,
  permissions,
  sectionAPIKwargs,
  commentsContainerID,
  deleteComment,
  comments,
}) => {
  const [filters, setFilters] = useState([]);

  const viewData = useSelector(state => state.viewData);
  const is2023CRF = viewData.ACR_REPORTS_DETAIL?.data?.crf === CRF23;

  const multiNarratives = viewData.ACR_SECTIONS_DETAIL?.data?.narratives;
  const narrativeContent = useSelector(
    state => state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.content,
  );
  const highlightComment = useSelector(
    state => state.viewState?.highlightComment,
  );

  const scrollSharedOptions = {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    ignoreCancelEvents: true,
  };

  const dispatch = useDispatch();

  function removeHighlight() {
    dispatch(
      changeViewStateAction({
        highlightComment: undefined,
      }),
    );
  }

  useEffect(() => {
    if (!highlightComment) return;

    let timer;

    const highlightedCommentIndex = orderedComments.findIndex(
      item => item.anchor === highlightComment,
    );
    const lastIndex = orderedComments.length - 1;

    if (highlightedCommentIndex !== -1) {
      timer = setTimeout(() => {
        removeHighlight();
      }, 5000);

      // Scroll the main wrapper if the element to highlight is the last
      // of the list
      if (
        highlightedCommentIndex === lastIndex &&
        document.documentElement.scrollTop < 193
      ) {
        scroller.scrollTo('section-wrapper-main', scrollSharedOptions);
      }

      scroller.scrollTo(highlightComment, {
        ...scrollSharedOptions,
        containerId: 'acr-comments-wrapper',
        offset: -50,
      });
    }

    return () => {
      clearTimeout(timer);
    };
  }, [highlightComment]);

  function toggleFilter(filterToToggle) {
    const index = filters.indexOf(filterToToggle);
    const draftFilters = [...filters];
    if (index > -1) {
      draftFilters.splice(index, 1);
    } else {
      draftFilters.push(filterToToggle);
    }

    setFilters(draftFilters);
  }

  function filterComments(data) {
    if (filters.includes('opened')) {
      data = data.filter(comment => !comment.closed);
    }
    if (filters.includes('important')) {
      data = data.filter(comment => comment.important);
    }
    if (filters.includes('owner')) {
      data = data.filter(comment => comment.user.username === user);
    }
    return data;
  }

  function getAnchorsList(content) {
    if (!content) return [];

    let narrativeArray = content.split('id="');
    narrativeArray.shift();
    narrativeArray = narrativeArray.map(item => item.split('" name', 1)[0]);
    return narrativeArray;
  }

  function getSortedLinkedComments(linkedComments = [], anchorsList = []) {
    return linkedComments.sort(
      (a, b) => anchorsList.indexOf(a.anchor) - anchorsList.indexOf(b.anchor),
    );
  }

  // Ordering comments based on their highlights order in narrative
  const orderedComments = useMemo(() => {
    const filteredComments = filterComments(comments);

    let unlinkedComments = [];
    let linkedComments = [];

    if (!is2023CRF) {
      const narrativeArray = getAnchorsList(narrativeContent);
      filteredComments.forEach(comment => {
        if (
          comment.anchor !== null &&
          narrativeArray.includes(comment.anchor)
        ) {
          linkedComments.push(comment);
        } else {
          unlinkedComments.push(comment);
        }
      });

      linkedComments = getSortedLinkedComments(linkedComments, narrativeArray);

      return [...linkedComments, ...unlinkedComments];
    }

    const multiNarrativesOrdererd = sortBy(multiNarratives, 'order');
    multiNarrativesOrdererd.forEach(narrative => {
      let multiNarrativeLinkedComments = [];
      const narrativeArray = getAnchorsList(narrative.content);

      filteredComments.forEach(comment => {
        if (
          comment.anchor !== null &&
          narrativeArray.includes(comment.anchor)
        ) {
          multiNarrativeLinkedComments.push({
            ...comment,
            narrativeLinkedTitle: narrative.title,
          });
        }
      });

      multiNarrativeLinkedComments = getSortedLinkedComments(
        multiNarrativeLinkedComments,
        narrativeArray,
      );

      linkedComments.push(...multiNarrativeLinkedComments);
    });

    unlinkedComments = filteredComments
      .filter(
        item => linkedComments.findIndex(comm => comm.pk === item.pk) === -1,
      )
      .map(comment => ({
        ...comment,
        narrativeLinkedTitle: 'Untagged comments',
      }));

    return [...linkedComments, ...unlinkedComments];
  }, [narrativeContent, comments, filters]);

  return (
    <>
      <CommentListFilterSet toggleFilter={toggleFilter} />
      {orderedComments.map((comment, index) => (
        <Fragment key={comment.pk}>
          {comment.narrativeLinkedTitle &&
            (index === 0 ||
              orderedComments[index - 1].narrativeLinkedTitle !==
                comment.narrativeLinkedTitle) && (
              <div className="comment-item-linked-narrative-title">
                {comment.narrativeLinkedTitle}
              </div>
            )}
          <CommentListItem
            activeUser={user}
            permissions={permissions}
            sectionAPIKwargs={sectionAPIKwargs}
            commentsContainerID={commentsContainerID}
            id={comment.pk}
            data={comment}
            deleteComment={deleteComment}
            highlightIsHovered={comment.anchor === highlightComment}
          />
        </Fragment>
      ))}
    </>
  );
};

export default CommentList;
