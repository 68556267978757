import React from 'react';

import { publicationUploadMaxSize } from 'src/constants';

const errorMessages = {
  file_type: 'File type not allowed',
  file_size: `Max file size is ${publicationUploadMaxSize} MB`,
  file_name: 'File name not valid',
  file_name_project_id: 'Operation ID not valid',
  file_name_already_merged:
    "It's not possible to upload files of already merged reports",
};

const ACRUploadErrorMessage = ({ error = '', filename = '' }) => {
  const msg = 'Error on selecting file';

  if (!error) return <></>;

  return (
    <div className="acr-file-upload-msg">
      <p>{filename ? filename : ''}</p>
      {errorMessages?.[error] || msg}
    </div>
  );
};

export default ACRUploadErrorMessage;
