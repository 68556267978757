export const MODE_TITLE_MAP = {
  edit: 'Edit',
  post: 'Post version',
  save: 'Save as draft',
  cancel: 'Close',
};

export const disabledBenDataNote2020 =
  'For the 2020 reporting period, disability data has been collected using a variety of approaches, according to the ' +
  'existing needs, capacity, and experience of various WFP activities and operational contexts. Moving forward, as' +
  ' part of the 2020 Disability Inclusion Road Map, WFP will be building on this experience to mainstream and ' +
  'standardise disability data collection methodologies, aligning with international standards and best practices. \n';

export const disabledBenDataNote2021 =
  'For the 2021 reporting period, disability data has been collected using a variety of approaches; the WHO 15 ' +
  'percent global disability prevalence average, head counts in single activities, or disaggregation of data' +
  ' from post distribution monitoring reports (PDMs). As standardised guidance was not available in WFP prior' +
  ' to 2020, these methods have varied according to the existing needs, capacity, and experience of various ' +
  'WFP activities and operational contexts. Moving forward, as part of the 2020 disability inclusion road map, ' +
  'WFP is building on continued efforts to mainstream and standardise disability data collection methodologies, ' +
  'aligning with international standards and best practices. \n';

export const disabledBenDataNote2022 =
  'For the 2022 reporting period, disability data has been collected using a variety of approaches; head counts' +
  ' in single activities, disaggregation of data from post distribution monitoring reports (PDMs) and other' +
  ' data sources from UN agencies and National Census data. As standardized guidance was not available in WFP ' +
  'prior to 2020, these methods have varied according to the existing needs, capacity, and experience of various ' +
  'WFP activities and operational contexts. Moving forward, as part of the 2020 Disability Inclusion Road Map, ' +
  'WFP is building on continued efforts to mainstream and standardize disability data collection methodologies, ' +
  'aligning with international standards and best practices. \n';

export const DATA_KEY_ON_DATA_SET = {
  'Total Beneficiaries': 'total_actual_beneficiaries',
  'Beneficiaries by Residence Status':
    'total_adjusted_beneficiaries_res_status',
  'Beneficiaries by Age Group': 'total_adjusted_beneficiaries_age_group',
  'Beneficiaries by Sex and Age Group':
    'total_adjusted_beneficiaries_age_group',
  'Beneficiaries by Programme Area': 'actual_and_planned_adjustments',
  'Beneficiaries by Modality': ['beneficiaries_by_modality'],
  'Total Food and CBT': [
    'food_and_report_block_food_mt',
    'annual-cbt-distribution',
  ],
  'Total Transfers by Modality': [
    'food_and_report_block_food_mt',
    'annual-cbt-distribution',
  ],

  'Annual Food Transfer': 'food_and_report_block_food_mt',
  'Annual Cash Based Transfer and Commodity Voucher': 'annual-cbt-distribution',
  'Annual Food Distribution': 'food_and_report_block_food_mt',
  'Annual CBT and Commodity Voucher Distribution': 'annual-cbt-distribution',
  'WFP contribution to SDGs': 'acr-sdg',

  // Strategic Outcome
  'Strategic Outcome Title': 'strategic_outcome_strategic_outcomes',
  'Strategic Outcome and Output Results': 'outputs_outcomes',
  'Output and Outcome Results': 'outputs_outcomes',
  'Annual CSP Financial Overview by Strategic Outcome and Activity':
    'ca_fm_activity',
  'Annual Financial Overview by Strategic Outcome and Activity':
    'ca_fm_activity',
  'WFP Gender and Age Marker': 'gam',
  Resources: 'acr-r2r',

  // Crosscutting results
  'Progress towards Gender Equality indicators': 'acr-cross-cutting_progress',
  'Protection indicators': 'acr-cross-cutting_protection',
  'Accountability to Affected Population indicators':
    'acr-cross-cutting_population',
  'Accountability to affected people indicators':
    'acr-cross-cutting_population',
  'Environment indicators': 'acr-cross-cutting_environment',
  'Environmental sustainability indicators': 'acr-cross-cutting_environment',
  'Nutrition integration indicators': 'acr-cross-cutting_nutrition',
};
