import {
  iconCheckmark,
  iconDelete,
  iconEdit,
  iconRestartGlyph,
  iconStarOutline,
  iconStarSolid,
} from '@wfp/icons';
import { Icon } from '@wfp/ui';
import React from 'react';

import { sendGAEvent } from 'src/utils';

const CommentControlPanel = ({
  nested,
  permissions,
  closed,
  user,
  activeUser,
  id,
  important,
  parentClosed = false,
  markAsImportant,
  toggleDeleteConfirmationModal,
  toggleCloseConfirmationModal,
  toggleReplyComment,
  toggleEdit,
  isCommentsUpdating,
}) => {
  const {
    add_comment,
    change_issue,
    change_comment,
    change_own_issue,
    change_own_comment,
    delete_own_comment,
    mark_closed_issue,
    mark_important_issue,
    mark_closed_own_issue,
  } = permissions;

  const close_issue =
    (user.username === activeUser && mark_closed_own_issue) ||
    mark_closed_issue;
  const closeIcon = close_issue && (
    <Icon
      description="Close"
      fill="black"
      height="16"
      icon={iconCheckmark}
      id={id}
      className="noselect"
    />
  );

  const importantIcon = mark_important_issue && (
    <Icon
      description="Important"
      fill="black"
      height="16"
      icon={important ? iconStarSolid : iconStarOutline}
      id={id}
      className="noselect"
    />
  );
  let displayEditIcon, displayDeleteIcon; // displayDeleteIcon is used here to check if user can delete his own commnents not issues
  if (!nested) {
    displayEditIcon =
      !closed &&
      ((!!change_own_issue && user.username === activeUser) || !!change_issue);
    displayDeleteIcon = false;
  } else {
    displayEditIcon =
      !parentClosed &&
      ((!!change_own_comment && user.username === activeUser) ||
        !!change_comment);
    displayDeleteIcon =
      !parentClosed && !!delete_own_comment && user.username === activeUser;
  }
  return (
    <React.Fragment>
      {!nested ? (
        <React.Fragment>
          {add_comment && (
            <span
              className="acr-comment-icon-holder"
              onClick={toggleReplyComment}
              data-test-id="reply-link"
            >
              <Icon
                description="Reply"
                icon={iconRestartGlyph}
                height="16"
                id={id}
                className="noselect"
              />
              <span className="acr-comment-icon-label">Reply</span>
            </span>
          )}
          {close_issue && (
            <span
              className="acr-comment-icon-holder"
              onClick={() =>
                !isCommentsUpdating ? toggleCloseConfirmationModal() : null
              }
              data-test-id="close-link"
            >
              {closeIcon}
              <span className="acr-comment-icon-label">Close</span>
            </span>
          )}
          {mark_important_issue && (
            <span
              className="acr-comment-icon-holder"
              onClick={() => {
                if (!isCommentsUpdating) {
                  sendGAEvent('comment_important_btn_clicked');
                  markAsImportant();
                }
              }}
              data-test-id="important-link"
            >
              {importantIcon}
              <span className="acr-comment-icon-label">Important</span>
            </span>
          )}
        </React.Fragment>
      ) : null}

      {displayEditIcon && !closed && (
        <span
          className="acr-comment-icon-holder"
          onClick={() => (!isCommentsUpdating ? toggleEdit() : null)}
          data-test-id="edit-link"
        >
          <Icon
            description="Edit"
            icon={iconEdit}
            id={id}
            height="16"
            className="noselect"
          />
          <span className="acr-comment-icon-label">Edit</span>
        </span>
      )}
      {displayDeleteIcon && (
        <span
          className="acr-comment-icon-holder"
          onClick={() =>
            !isCommentsUpdating ? toggleDeleteConfirmationModal() : null
          }
        >
          <Icon
            description="Delete"
            fill="black"
            icon={iconDelete}
            id={id}
            className="noselect"
          />
          <span className="acr-comment-icon-label">Delete</span>
        </span>
      )}
    </React.Fragment>
  );
};

export default CommentControlPanel;
