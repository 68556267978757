import React from 'react';
import Picture from 'src/assets/images/picture-icon.svg';

const NoImageBox = () => {
  return (
    <div className="no-image-uploaded-box">
      <img src={Picture} />
      <p>No image uploaded yet</p>
    </div>
  );
};

export default NoImageBox;
