import React, { FC, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  IIssue,
  IIssuesOverviewActiveFilters,
  IIssuesOverviewData,
} from 'src/types';
import { ACR_ISSUES_OVERVIEW_URL } from 'src/redux/report/constants';
import slug from 'slug';
import { CRF23 } from 'src/constants';
import IssuesOverviewContentList from './IssuesOverviewContentList';

interface Props {
  activeFilters: IIssuesOverviewActiveFilters;
}

const IssuesOverviewContent: FC<Props> = ({ activeFilters }) => {
  function applyFilters(issue: IIssue) {
    if (activeFilters.showImportantComments && !issue.important) return false;
    if (activeFilters.showOpenComments && issue.closed) return false;
    if (activeFilters.showMyComments && issue.user.username !== currentUsername)
      return false;

    return true;
  }

  const appConfig = useSelector(
    (state: RootStateOrAny) => state.viewData?.GET_APP_CONFIGURATION?.data,
  );
  const currentUsername = appConfig?.userInfo?.username;

  const issuesOverviewData: IIssuesOverviewData = useSelector(
    (state: RootStateOrAny) => state.viewData[ACR_ISSUES_OVERVIEW_URL]?.data,
  );

  const is2023CRF = issuesOverviewData?.crf_code === CRF23;

  const flattenSections = useMemo(() => {
    const sections = [];
    issuesOverviewData.sections.forEach(section => {
      const { children_list, ...rest } = section;

      sections.push(rest);

      children_list.forEach(child => {
        sections.push(child);
      });
    });
    return sections;
  }, [issuesOverviewData.sections]);

  const filteredIssuesOverviewSections = useMemo(() => {
    if (is2023CRF) {
      return flattenSections?.map(
        ({ narratives, untagged_comments, ...rest }) => {
          let filteredNarratives = [];
          filteredNarratives = narratives?.map(narr => {
            const filteredIssues = narr.issues?.filter(applyFilters);
            return { ...narr, issues: filteredIssues };
          });

          return {
            ...rest,
            untagged_comments: untagged_comments.filter(applyFilters),
            narratives: filteredNarratives,
          };
        },
      );
    }

    return flattenSections?.map(
      ({ narratives, extra_narratives, untagged_comments, ...rest }) => {
        let filteredExtraNarratives = [];
        filteredExtraNarratives = extra_narratives?.map(narr => {
          const filteredIssues = narr.issues?.filter(applyFilters);
          return { ...narr, issues: filteredIssues };
        });

        return {
          ...rest,
          untagged_comments: untagged_comments.filter(applyFilters),
          narratives: narratives.filter(applyFilters),
          extra_narratives: filteredExtraNarratives,
        };
      },
    );
  }, [flattenSections, activeFilters, is2023CRF]);

  if (!issuesOverviewData) return null;

  return (
    <div className="issues-overview-content">
      {filteredIssuesOverviewSections?.map((section, sectionIndex) => {
        const {
          current_title: title,
          narratives,
          extra_narratives,
          untagged_comments,
        } = section;

        return (
          <div className="io-content-section-wrapper" key={slug(title)}>
            <h3 id={slug(title)}>{title}</h3>
            {is2023CRF ? (
              <>
                <IssuesOverviewContentList
                  narratives={narratives}
                  activeFilters={activeFilters}
                  untaggedComments={false}
                />
              </>
            ) : (
              <>
                <IssuesOverviewContentList
                  narratives={[
                    {
                      issues: narratives,
                      title: 'Main Narrative',
                      content: section.content,
                    },
                  ]}
                  activeFilters={activeFilters}
                  untaggedComments={false}
                />
                {extra_narratives.length > 0 && (
                  <>
                    <div className="io-content-narratives-separator"></div>
                    <IssuesOverviewContentList
                      narratives={extra_narratives}
                      activeFilters={activeFilters}
                      untaggedComments={false}
                    />
                  </>
                )}
              </>
            )}
            {untagged_comments.length > 0 && (
              <>
                <div className="io-content-narratives-separator"></div>
                <IssuesOverviewContentList
                  narratives={[{ issues: untagged_comments }]}
                  activeFilters={activeFilters}
                  untaggedComments
                />
              </>
            )}
            {sectionIndex < filteredIssuesOverviewSections?.length - 1 && (
              <div className="io-content-sections-separator"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default IssuesOverviewContent;
