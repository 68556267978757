import React, { FC } from 'react';
import { Checkbox } from '@wfp/ui';
import { IIssuesOverviewActiveFilters } from 'src/types';

interface Props {
  activeFilters: IIssuesOverviewActiveFilters;
  toggleActiveFilters: (filterBy: keyof IIssuesOverviewActiveFilters) => void;
}

const IssuesOverviewFilters: FC<Props> = ({
  activeFilters,
  toggleActiveFilters,
}) => {
  const {
    showNarratives,
    showOpenComments,
    showImportantComments,
    showMyComments,
  } = activeFilters;

  return (
    <div className="issues-overview-template-filters">
      <div className="issues-overview-filters">
        <div className="issues-overview-filter">
          <div className="issues-overview-filter-content">
            <Checkbox
              checked={showNarratives}
              onChange={() => toggleActiveFilters('showNarratives')}
              id="issues-overview-view-filter-content-narratives"
              labelText="View Narratives"
            />
          </div>
        </div>
        <div className="issues-overview-filter">
          <div className="issues-overview-comments-filter-title">Filters</div>
          <div className="issues-overview-filter-content">
            <Checkbox
              checked={showOpenComments}
              onChange={() => toggleActiveFilters('showOpenComments')}
              id="issues-overview-comments-filter-content-open-comments"
              labelText="Open comments"
            />
            <Checkbox
              checked={showImportantComments}
              onChange={() => toggleActiveFilters('showImportantComments')}
              id="issues-overview-comments-filter-content-important-comments"
              labelText="Important comments"
            />
            <Checkbox
              checked={showMyComments}
              onChange={() => toggleActiveFilters('showMyComments')}
              id="issues-overview-comments-filter-content-my-comments"
              labelText="My comments"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssuesOverviewFilters;
