import { Select } from '@wfp/ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectOption } from 'src/redux/report/actions';
import { SELECT_OPTION_STATE_KEY } from 'src/redux/report/constants';

export class SelectBoxContainer extends Component {
  onChange = event => {
    this.handleSelection(event.target.value);
  };

  handleSelection = selectedOptionIndex => {
    this.props.dispatchSelect(
      this.props.id,
      this.props.options[Number(selectedOptionIndex)].value,
    );
  };

  buildSelectOptionProps = () => {
    const presentationData = [];
    for (let i = 0, len = this.props.options.length; i < len; i++) {
      const data = this.props.options[i];
      presentationData.push({
        optionIndex: i,
        title: data.title,
        value: data.value,
        disabled: data.disabled || undefined,
      });
    }
    return presentationData;
  };
  getCurrentOptionIndex = () => {
    for (let i = 0, len = this.props.options.length; i < len; i++) {
      if (this.props.options[i].value === this.props.value) return i;
    }
    // If not found a proper value set the first value
    if (this.props.options.length > 0) {
      this.handleSelection('0');
    }
  };

  render() {
    return (
      <SelectBoxPresentation
        id={this.props.id}
        data={this.buildSelectOptionProps()}
        selectedOptionIndex={this.getCurrentOptionIndex()}
        onChange={this.onChange}
      />
    );
  }
}

export class SelectBoxPresentation extends Component {
  mapDataToSelectOptions = (item, index) => {
    return (
      <option key={index} value={item.optionIndex}>
        {item.title.slice(0, 50)}
        {item.title.length > 50 ? '...' : ''}
      </option>
    );
  };

  render() {
    return (
      <div className="form-group">
        <Select
          id={this.props.id}
          inline
          labelText=""
          onChange={this.props.onChange}
          value={this.props.selectedOptionIndex}
          className="col-md-2"
        >
          {this.props.data.map(this.mapDataToSelectOptions)}
        </Select>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  let selectBoxState;
  try {
    selectBoxState =
      state.viewState[SELECT_OPTION_STATE_KEY][ownProps.id] || {};
  } catch (e) {
    selectBoxState = {};
  }
  const newState = {
    isFetching: false,
    error: null,
    value: null,
    ...selectBoxState,
  };
  return newState;
};

export const mapDispatchToProps = dispatch => {
  return {
    dispatchSelect: (selectBoxID, value) => {
      dispatch(selectOption(selectBoxID, value));
    },
  };
};

export const SelectBox = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectBoxContainer);
