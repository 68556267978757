import {
  CapacityStrComponent,
  CBTComponent,
  CommodityVoucherComponent,
  FoodComponent,
} from 'acr_ui/sources/components/dataPresentation/beneficiariesByCBT/beneficiariesByCbt';
import { BeneficiariesByModalitiesComponent } from 'acr_ui/sources/components/dataPresentation/beneficiariesByModality';
import React from 'react';

const BeneficiariesByModality = props => {
  if (parseInt(props.report.period) >= 2022) {
    return <BeneficiariesByModalitiesComponent data={props.data} />;
  }

  return (
    <section className="total-food-n-cbt-wrapper">
      <FoodComponent
        data={props.data.beneficiaries_by_modality || {}}
        currentPeriod={props.currentPeriod}
      />

      <CBTComponent
        data={props.data.beneficiaries_by_modality || {}}
        currentPeriod={props.currentPeriod}
      />
      <CommodityVoucherComponent
        data={props.data.beneficiaries_by_modality || {}}
        currentPeriod={props.currentPeriod}
      />
      <CapacityStrComponent
        data={props.data.beneficiaries_by_modality || {}}
        currentPeriod={props.currentPeriod}
      />
    </section>
  );
};

export default BeneficiariesByModality;
