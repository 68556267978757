import {
  iconCheckmarkGlyph,
  iconInfoGlyph,
  iconWarningSolid,
} from '@wfp/icons';
import { Icon } from '@wfp/ui';
import type { FC } from 'react';
import React from 'react';

import iconWarning from 'src/assets/images/icon-warning.svg';
import { TNotificationType } from 'src/types';

interface Props {
  msg: string | JSX.Element;
  type: TNotificationType;
}

const ToastNotification: FC<Props> = ({ msg, type }) => {
  const iconsByType = {
    success: iconCheckmarkGlyph,
    info: iconInfoGlyph,
    error: iconWarningSolid,
  };

  return (
    <div className={`toast-notification-wrapper ${type}`}>
      {type === 'warning' ? (
        <img src={iconWarning} />
      ) : (
        <Icon
          icon={iconsByType?.[type]}
          description={type}
          height="20"
          width="20"
        />
      )}
      <div>{msg}</div>
    </div>
  );
};

export default ToastNotification;
