import { Button, Module, TextArea } from '@wfp/ui';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import {
  disabledBenDataNote2020,
  disabledBenDataNote2021,
  disabledBenDataNote2022,
} from 'src/components/ReportTemplate/Workspace/config';
import { UpdateDataNotes } from 'src/redux/report/actions';
import {
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { newLinesAsBr } from 'src/utils';

const DataNotes = () => {
  const dispatch = useDispatch();

  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );
  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const viewState = useSelector((state: RootStateOrAny) => state.viewState);

  const [dataNotes, setDataNotes] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (sectionDetailData && sectionDetailData.data_notes) {
      setDataNotes(sectionDetailData.data_notes);
    } else {
      setDataNotes('');
    }
  }, [sectionDetailData, viewState.activeSectionID]);

  function onDataNotesSubmit() {
    dispatch(UpdateDataNotes(dataNotes, viewState.activeSectionID));
    setIsEdit(false);
  }

  function handleOnChangeTextAreaDataNotes(e) {
    setDataNotes(e.target.value);
  }

  function renderBeneficiariesWithDisabilitiesDataNote() {
    if (!sectionDetailData.datasets) return false;
    const { total_actual_beneficiaries = {} } = sectionDetailData.datasets;
    const { beneficiaries_with_disabilities: { total = 0 } = {} } =
      total_actual_beneficiaries;
    if (total !== '0') {
      return !!total;
    }
    return false;
  }

  function handleCancelBtn() {
    setDataNotes(sectionDetailData.data_notes);
    setIsEdit(false);
  }

  if (!sectionDetailData) return null;
  if (!sectionDetailData.has_data_notes) return null;

  const isPublished = reportDetailData?.state === 'Published';

  return (
    <Module
      className="data-notes-section special-module-title"
      data-test-id="data-notes-section"
    >
      <header className="view-mode-header">
        <h6 className="datasource-title" data-test-id="data-notes-title">
          Data Notes
        </h6>
        {!isEdit &&
          !isPublished &&
          sectionDetailData?.permissions?.can_edit_data_notes && (
            <span className="view-mode-group">
              <Button onClick={() => setIsEdit(true)}>Edit</Button>
            </span>
          )}
        {isEdit && (
          <span className="view-mode-group" id="data-notes-control">
            <Button onClick={handleCancelBtn} kind="secondary">
              Cancel
            </Button>
            <Button
              onClick={onDataNotesSubmit}
              data-test-id="save-data-notes-button"
            >
              Save data notes
            </Button>
          </span>
        )}
      </header>
      <div className="data-notes-extra">
        {sectionDetailData.data_notes_extra}
      </div>

      {renderBeneficiariesWithDisabilitiesDataNote() &&
        sectionDetailData.report.period === '2020' && (
          <div className="data-notes-extra"> {disabledBenDataNote2020}</div>
        )}

      {renderBeneficiariesWithDisabilitiesDataNote() &&
        sectionDetailData.report.period === '2021' && (
          <div className="data-notes-extra"> {disabledBenDataNote2021}</div>
        )}

      {renderBeneficiariesWithDisabilitiesDataNote() &&
        sectionDetailData.report.period === '2022' && (
          <div className="data-notes-extra">
            {disabledBenDataNote2022.replaceAll(
              '$$$',
              sectionDetailData.report.period,
            )}
          </div>
        )}
      <div className="view-mode-content data-notes">
        {!isEdit ? (
          !dataNotes ? (
            <div className="data-notes-empty-info">No data notes</div>
          ) : (
            <section
              dangerouslySetInnerHTML={{ __html: newLinesAsBr(dataNotes) }}
            />
          )
        ) : (
          <TextArea
            onChange={handleOnChangeTextAreaDataNotes}
            className="form-control"
            value={dataNotes}
            name="dataNotesArea"
            rows={3}
            labelText=""
          />
        )}
      </div>
    </Module>
  );
};

export default DataNotes;
