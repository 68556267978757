import { Checkbox } from '@wfp/ui';
import React from 'react';

const CommentListFilterSet = ({ toggleFilter }) => {
  return (
    <div className="comment-filter-set">
      <div className="comment-filter noselect">
        <Checkbox
          labelText="OPEN"
          className="acr-comment-filter-checkbox"
          id="acr-comment-filter-by-open"
          onClick={() => toggleFilter('opened')}
          data-test-id="open-filter"
        />
      </div>
      <div className="comment-filter noselect">
        <Checkbox
          labelText="IMPORTANT"
          className="acr-comment-filter-checkbox"
          id="acr-comment-filter-by-important"
          onClick={() => toggleFilter('important')}
          data-test-id="important-filter"
        />
      </div>
      <div className="comment-filter noselect">
        <Checkbox
          labelText="MY COMMENTS"
          className="acr-comment-filter-checkbox"
          id="acr-comment-filter-by-ownership"
          onClick={() => toggleFilter('owner')}
          data-test-id="my-comments-filter"
        />
      </div>
    </div>
  );
};

export default CommentListFilterSet;
