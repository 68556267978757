import { Pagination } from '@wfp/ui';
import { Loading } from '@wfp/ui';
import React, { Fragment, useEffect } from 'react';
import {
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

const TableMain = ({
  className = '',
  data,
  columns,
  loading = false,
  pagination = '',
  isFilterable = false,
  initialState = {
    hiddenColumns: ['hq-admin-structure-column'],
  },
  totalItems, // for server side pagination
  fetchData, // for server side pagination
  pageCount = -1, // for server side pagination,
  currentPage = 1,
}) => {
  const isServerPaginated = pagination === 'server';

  const defaultColumn = {
    minWidth: 30,
    width: 50,
    maxWidth: 400,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    rows,
    pageCount: clientPageCount,
    state: { pageIndex: pageIndexState, pageSize: pageSizeState },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      manualPagination: isServerPaginated,
      autoResetPage: false,
      pageCount: isServerPaginated ? pageCount : -1,
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    usePagination,
  );

  function onPaginationChange({ page, pageSize }) {
    setPageSize(pageSize);
    gotoPage(isServerPaginated ? page : page - 1);
  }

  useEffect(() => {
    if (!pagination) {
      setPageSize(data?.length || 1);
    }
  }, [data?.length]);

  useEffect(() => {
    if (isServerPaginated) {
      fetchData({ pageIndex: pageIndexState, pageSize: pageSizeState });
    }
  }, [fetchData, pageIndexState, pageSizeState]);

  return (
    <div className={`${className ? className : ''} ReactTable`}>
      {loading && <Loading withOverlay={true} active />}
      <div {...getTableProps} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={`${index}-header-group`}
              className="rt-tr"
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`rt-th rt-resizable-header ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? '-sort-desc'
                          : '-sort-asc'
                        : ''
                    }`}
                    key={`${index}-header`}
                  >
                    <div
                      className="rt-resizable-header-content"
                      data-test-id="column-title"
                    >
                      {column.render('Header')}
                    </div>
                    <div {...column.getResizerProps()} className="rt-resizer" />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {isFilterable && (
          <div className="rt-thead -filters">
            {headerGroups.map((headerGroup, index) => (
              <div key={`${index}-filters-group`} className="rt-tr">
                {headerGroup.headers.map((column, index) => {
                  return (
                    <div
                      {...column.getHeaderProps()}
                      className="rt-th"
                      key={`${index}-filter`}
                    >
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
        <div className="rt-tbody">
          {totalItems === 0 && !loading && (
            <div className="table-main-empty-results">No items found</div>
          )}
          <div {...getTableBodyProps()} className="rt-tr-group">
            {page.map((row, ind) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <div
                    {...row.getRowProps()}
                    className={`rt-tr ${ind % 2 === 0 ? '-odd' : '-even'}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          key={`${index}-cell`}
                          className={`rt-td ${cell.column.className}`}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      {pagination && (
        <Pagination
          page={currentPage}
          onChange={onPaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          totalItems={isServerPaginated ? totalItems : rows?.length}
          pages={isServerPaginated ? pageCount : clientPageCount}
          data-test-id="pagination"
        />
      )}
    </div>
  );
};

export default TableMain;
