import {
  Button,
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
} from '@wfp/ui';
import React, { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';

import Title from 'src/components/ReportTemplate/Workspace/Title';
import { requestAPI } from 'src/redux/actions';
import {
  changeViewStateAction,
  revertHistoryNarrative,
} from 'src/redux/report/actions';
import {
  HISTORY_NARRATIVE_KEY,
  NARRATIVE,
  SECTION_DETAIL_DATA_KEY,
  VERSIONS,
} from 'src/redux/report/constants';

export const VersionsTabComponent = ({
  headingWidgets,
  history,
  activeSectionID,
  activeClickedTab,
  title,
  subtitle,
}) => {
  const dispatch = useDispatch();

  function revertNarrativeHistory(versionPk) {
    const params = {
      sectionPk: activeSectionID,
      versionPk,
      success_actions: [
        requestAPI(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: activeSectionID },
        }),
      ],
    };
    dispatch(revertHistoryNarrative(params));

    dispatch(changeViewStateAction({ activeClickedTab: NARRATIVE }));
  }

  return (
    <>
      <Module className="section-heading">
        <ModuleHeader>
          <Title
            initialContent={title}
            isEditable={false}
            sectionId={activeSectionID}
            headingWidgets={headingWidgets}
            subtitle={subtitle}
            isVersionsTab={activeClickedTab === VERSIONS}
          />
        </ModuleHeader>
      </Module>
      {history?.versions?.length > 0 ? (
        history.versions?.map((item, index) => {
          return (
            <Module className="version" key={index}>
              <ModuleBody>
                <section className="full-width">
                  {!item.is_latest && (
                    <Button
                      className="float-right"
                      onClick={() => revertNarrativeHistory(item.id)}
                    >
                      Revert to this version
                    </Button>
                  )}
                  <aside>
                    {item.date}
                    <br />
                    <strong>by:</strong> {item.username},
                    <br />
                    <strong>time:</strong> {item.time}
                  </aside>
                </section>
              </ModuleBody>
              <ModuleFooter className="versions">
                <div
                  className="version-text"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                <div className="extra-narrative-version">
                  {item?.extra_narratives &&
                    item?.extra_narratives?.map(extra_narrative => {
                      return (
                        <Fragment key={extra_narrative.title}>
                          <div className="version-title">
                            {extra_narrative.title}
                          </div>
                          <div
                            className="version-text"
                            dangerouslySetInnerHTML={{
                              __html: extra_narrative.content,
                            }}
                          />
                        </Fragment>
                      );
                    })}
                </div>
              </ModuleFooter>
            </Module>
          );
        })
      ) : (
        <div>No changes.</div>
      )}
    </>
  );
};

export const mapStateToProps = state => {
  return {
    history: state.viewData[HISTORY_NARRATIVE_KEY]?.data,
    activeSectionID: state.viewState.activeSectionID,
    activeClickedTab: state.viewState.activeClickedTab,
    title: state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.title,
    subtitle: state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.subtitle,
  };
};

const VersionsTab = connect(mapStateToProps)(VersionsTabComponent);

export default VersionsTab;
