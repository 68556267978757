// View Data
export const API_HOST = process.env.REACT_APP_API_HOST;
export const GET_APP_CONFIGURATION = 'GET_APP_CONFIGURATION';
export const REPORT_DETAIL_DATA_KEY = 'ACR_REPORTS_DETAIL';
export const REPORT_LIST_DATA_KEY = 'ACR_REPORTS_LIST';
export const POST_ACR_REPORTS_STATE_TRANSITION_KEY =
  'ACR_REPORTS_STATE_TRANSITIONS';
export const EXTRA_SECTION_DATA_KEY = 'ACR_ADD_EXTRA_SECTION';
export const SECTION_DETAIL_DATA_KEY = 'ACR_SECTIONS_DETAIL';
export const NARRATIVE_UPDATE_DATA_KEY = 'NARRATIVE_UPDATE_DATA_KEY';
export const MULTI_NARRATIVES_LIST = 'MULTI_NARRATIVES_LIST';
export const EXTRA_NARRATIVE_GET_DATA_KEY = 'EXTRA_NARRATIVE_GET_DATA_KEY';
export const GET_EXTRA_NARRATIVE_ACTION = 'GET_EXTRA_NARRATIVE_ACTION';
export const UPDATE_NARRATIVE_IN_STORAGE = 'UPDATE_NARRATIVE_IN_STORAGE';
export const SECTION_REFRESH_DATA_SOURCE_KEY = 'SECTION_REFRESH_DATA_SOURCE';
export const POST_ACR_INFO_BOX_CONTENT_KEY = 'POST_ACR_INFO_BOX_CONTENT_KEY';
export const CHANGE_DATE_PICKER_KEY = 'CHANGE_DATE_PICKER_KEY';
export const EXPORT_ACR_DASHBOARD_KEY = 'EXPORT_ACR_DASHBOARD_KEY';
export const UPDATE_DATA_NOTES_KEY = 'UPDATE_DATA_NOTES_KEY';
export const RESET_DATA_NOTES_KEY = 'RESET_DATA_NOTES_KEY';
export const APPLY_CHANGE_TITLE_KEY = 'APPLY_CHANGE_TITLE_KEY';
export const CANCEL_NARRATIVE_EDITING_KEY = 'CANCEL_NARRATIVE_EDITING_KEY';
export const NARRATIVE_UNLOCK_AND_FORCE_POST_DATA_KEY =
  'NARRATIVE_UNLOCK_AND_FORCE_POST_DATA_KEY';
export const LOCK_NARRATIVE_KEY = 'LOCK_NARRATIVE_KEY';
export const NARRATIVE_GET_DATA_KEY = 'NARRATIVE_GET_DATA_KEY';
export const HISTORY_NARRATIVE_KEY = 'HISTORY_NARRATIVE_KEY';
export const ICONS_DETAILS_KEY = 'ICONS_LIST';
export const REVERT_HISTORY_NARRATIVE_KEY = 'REVERT_HISTORY_NARRATIVE_KEY';
export const GET_ALL_REPORTS_PERIODS = 'GET_ALL_REPORTS_PERIODS';
export const EXPORT_TABLES = 'EXPORT_TABLES';
export const EXPORT_USERS = 'EXPORT_USERS';

// View State
export const SELECT_OPTION_STATE_KEY = 'SELECT_OPTION_STATE_KEY';
export const SET_PAGINATION_KEY = 'SET_PAGINATION_KEY';
export const SET_SEARCH_BOX_KEY = 'SET_SEARCH_BOX_KEY';
export const CLEAR_VIEW_STATE = 'CLEAR_VIEW_STATE';
export const CLEAR_VIEW_DATA = 'CLEAR_VIEW_DATA';
export const CHANGE_VIEW_STATE = 'CHANGE_VIEW_STATE';

// Actions
export const SELECT_SECTION_ACTION = 'SELECT_SECTION_ACTION';
export const SELECT_OPTION_ACTION = 'SELECT_OPTION_ACTION';
export const SET_PAGINATION_ACTION = 'SET_PAGINATION_ACTION';
export const CHANGE_SECTIONS_CONTENT_MODE_ACTION =
  'CHANGE_SECTIONS_CONTENT_MODE_ACTION';
export const SET_SEARCH_BOX_ACTION = 'SET_SEARCH_BOX_ACTION';
export const ADD_EXTRA_SECTION_ACTION = 'ADD_EXTRA_SECTION_ACTION';
export const REFRESH_SECTIONS_DATA_SOURCE_ACTION =
  'REFRESH_SECTIONS_DATA_SOURCE_ACTION';
export const CHANGE_DATE_PICKER_ACTION = 'CHANGE_DATE_PICKER_ACTION';
export const ACR_INFO_BOX_ACTION = 'ACR_INFO_BOX_ACTION';
export const ACR_REPORT_STATE_TRANSITION_ACTION =
  'ACR_REPORT_STATE_TRANSITION_ACTION';
export const UPDATE_DATA_NOTES_ACTION = 'UPDATE_DATA_NOTES_ACTION';
export const RESET_DATA_NOTES_ACTION = 'RESET_DATA_NOTES_ACTION';
export const APPLY_CHANGE_TITLE_ACTION = 'APPLY_CHANGE_TITLE_ACTION';
export const CANCEL_NARRATIVE_EDITING_ACTION =
  'CANCEL_NARRATIVE_EDITING_ACTION';
export const NARRATIVE_UNLOCK_AND_FORCE_POST_EDITING_ACTION =
  'NARRATIVE_UNLOCK_AND_FORCE_POST_EDITING_ACTION';

export const LOCK_NARRATIVE_ACTION = 'LOCK_NARRATIVE_ACTION';
export const HISTORY_NARRATIVE_ACTION = 'HISTORY_NARRATIVE_ACTION';
export const ICONS_DETAILS_ACTION = 'ICONS_DETAILS_ACTION';
export const GET_NARRATIVE_ACTION = 'GET_NARRATIVE_ACTION';
export const GET_MULTI_NARRATIVE_ACTION = 'GET_MULTI_NARRATIVE_ACTION';
export const REVERT_HISTORY_NARRATIVE_ACTION =
  'REVERT_HISTORY_NARRATIVE_ACTION';
export const CLEAN_REPORT_DATA_ACTION = 'CLEAN_REPORT_DATA_ACTION';
export const NOTIFICATIONS_DELAYED_URL = 'NOTIFICATIONS_DELAYED_URL';
export const SECTION_REFRESH_DATA_SOURCE = 'SECTION_REFRESH_DATA_SOURCE';

// Constants for Detail View
export const NARRATIVE = 'NARRATIVE';
export const VERSIONS = 'VERSIONS';

// Constants for ACR reports list status
export const PROJECT_STATUS = 'Project Status';
export const REVIEW_STATUS = 'Review Status';

// Constants for ACR review modals
export const GET_ALL_REVIEW_STATE_DATA = 'GET_ALL_REVIEW_STATE_DATA';
export const UPDATE_REVIEW_STATE_DATA_ACTION =
  'UPDATE_REVIEW_STATE_DATA_ACTION';
export const UPDATE_REVIEW_STATE_DATA_KEY = 'UPDATE_REVIEW_STATE_DATA_KEY';
export const GET_TECHNICAL_REVIEWS_DATA = 'GET_ALL_TECHNICAL_STATE_DATA';

// Constants for Image Manager
export const IMAGE_MANAGER_URL = 'IMAGE_MANAGER_URL';

// Url for PDF project
export const POST_REQUEST_ACR_PDF_REPORT_KEY =
  'POST_REQUEST_ACR_PDF_REPORT_KEY';
export const GET_LIST_ACR_PDF_REPORTS_KEY = 'GET_LIST_ACR_PDF_REPORTS_KEY';
export const ACR_PDF_REPORT_DETAIL_URL = 'ACR_PDF_REPORT_DETAIL_URL';
export const ACR_PDF_REPORT_FILE_PROXY_URL = 'ACR_PDF_REPORT_FILE_PROXY_URL';

export const PULL_REQUESTED_PDF = 'PULL_REQUESTED_PDF';
export const STOP_PULLING_REQUESTED_PDF = 'STOP_PULLING_REQUESTED_PDF';

// export const UPDATE_INFOGRAPHICS_KEY = "UPDATE_INFOGRAPHICS_KEY";
export const UPDATE_INFOGRAPHICS_ACTION = 'UPDATE_INFOGRAPHICS_ACTION';
export const LOCK_INFOGRAPHICS_ACTION = 'LOCK_INFOGRAPHICS_ACTION';
export const UNLOCK_INFOGRAPHICS_ACTION = 'UNLOCK_INFOGRAPHICS_ACTION';
export const INFOGRAPHICS_CREATE = 'INFOGRAPHICS_CREATE';
export const INFOGRAPHICS_LOCK = 'INFOGRAPHICS_LOCK';
export const INFOGRAPHICS_UNLOCK = 'INFOGRAPHICS_UNLOCK';
export const INFOGRAPHICS_GET = 'INFOGRAPHICS_GET';
export const CHANGE_INFOGRAPHICS_EDITOR = 'CHANGE_INFOGRAPHICS_EDITOR';

export const INFOGRAPHICS_MANAGEMENT_LIST = 'INFOGRAPHICS_MANAGEMENT_LIST';
export const INFOGRAPHICS_MANAGEMENT = 'INFOGRAPHICS_MANAGEMENT';
//Urls for comments management
export const COMMENTS_LIST = 'COMMENTS_LIST';
export const COMMENTS_DETAIL = 'COMMENTS_DETAIL';
export const PDF_MERGE_LIST = 'PDF_MERGE_LIST';
export const PDF_MERGE_MAKE_MERGE = 'PDF_MERGE_MAKE_MERGE';
export const PDF_MERGE_PERIODS = 'PDF_MERGE_PERIODS';
export const PDF_MERGE_MERGE_STATUS = 'PDF_MERGE_MERGE_STATUS';
export const PDF_MERGE_MAKE_SUBMIT = 'PDF_MERGE_MAKE_SUBMIT';
export const REPLY = 'REPLY';
export const REPLY_DETAIL = 'REPLY_DETAIL';
export const COMMENTS_TARGET_ID = 'comments-display-wrapper';
export const RESTORE_COMMENT = 'RESTORE_COMMENT';

// Issues overview
export const ACR_ISSUES_OVERVIEW_URL = 'ACR_ISSUES_OVERVIEW_URL';

// User management
export const USER_MANAGEMENT_LIST = 'USER_MANAGEMENT_LIST';

//Wordcount
export const WORDCOUNT_CONFIG = 'WORDCOUNT_CONFIG';

export const EDIT_CUSTOM_SUBTITLE = 'EDIT_CUSTOM_SUBTITLE';
