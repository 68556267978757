import { notificationStyle } from '@wfp/ui';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthProvider from 'src/auth/AuthProvider';
import { BaseLayout } from 'src/components/BaseLayout';
import { SET_ROUTES_CONFIG } from 'src/constants';
import { getAppRoutes } from 'src/routes/App';

function App() {
  const [appRoot] = useState('/');
  const dispatch = useDispatch();
  const routesConfig = useSelector(state => state.routesConfig);

  useEffect(() => {
    window.djconfig = {
      appRoot: appRoot,
      ...window.djconfig,
    };
    dispatch({
      type: SET_ROUTES_CONFIG,
      payload: getAppRoutes(appRoot),
    });
  }, []);

  if (!routesConfig.root) return null;

  return (
    <>
      <Helmet titleTemplate="SPRING-UI | %s">
        <title>Home</title>
      </Helmet>

      <BrowserRouter>
        <Switch>
          <Route path={routesConfig.root.path}>
            <AuthProvider forceLogin>
              <BaseLayout />
            </AuthProvider>
            <ToastContainer {...notificationStyle} />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
