import React from 'react';
import { Module, ModuleHeader, ModuleBody, Button } from '@wfp/ui';
import type { FC } from 'react';
import * as DOMPurify from 'dompurify';
import { format } from 'date-fns';
import type { ISelectedVersion } from 'src/types';

interface Props {
  content: string;
  cleanContent: string;
  editor: string;
  date: string;
  id: number;
  setSelectedVersion?: (version: ISelectedVersion) => void;
  revertEnabled?: boolean;
}

const VersionItem: FC<Props> = ({
  content,
  cleanContent,
  editor,
  date,
  id,
  setSelectedVersion,
  revertEnabled = true,
}) => {
  return (
    <Module light className="version-item">
      <ModuleHeader>
        <div className="version-item-header">
          <div className="version-item-info">
            <div className="version-item-date">
              {format(date, 'dd MMMM yyyy, HH:mm:ss')} (CEST)
            </div>
            <div className="version-item-editor">Created by: {editor}</div>
          </div>
          {setSelectedVersion && revertEnabled && (
            <div className="version-item-btn">
              <Button
                onClick={() =>
                  setSelectedVersion({
                    id,
                    date,
                    cleanContent,
                    content,
                    editor,
                  })
                }
                data-test-id="revert-version-button"
              >
                Revert to this version
              </Button>
            </div>
          )}
        </div>
      </ModuleHeader>
      <ModuleBody>
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      </ModuleBody>
    </Module>
  );
};

export default VersionItem;
