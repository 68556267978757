import { getBeneficiariesByProgrammeAreaOptions } from 'acr_ui/sources/components/dataPresentation/actualVsPlannedAdjustments';
import { getReduxConnectedViewModeOptions as getAnnualCBTCommodityVoucherDistributionViewModeOptions } from 'acr_ui/sources/components/dataPresentation/annualCBTCommodityVoucherDistribution';
import { getReduxConnectedViewModeOptions as getAnnualFoodDistributionViewModeOptions } from 'acr_ui/sources/components/dataPresentation/annualFoodDistribution';
import { BeneficiariesByAgeGroupComponent } from 'acr_ui/sources/components/dataPresentation/beneficiariesByAgeGroup';
import { BeneficiariesByResidenceStatusComponent } from 'acr_ui/sources/components/dataPresentation/beneficiariesByResidenceStatus';
import { CrossCuttingTable } from 'acr_ui/sources/components/dataPresentation/crosscuttingtables';
import CSPFinancialOverview from 'acr_ui/sources/components/dataPresentation/cspFinancialOverview';
import { GaMTable } from 'acr_ui/sources/components/dataPresentation/gamTable';
import { OutputsOutcomesTable } from 'acr_ui/sources/components/dataPresentation/outputsOutcomesTable';
import { R2RTable } from 'acr_ui/sources/components/dataPresentation/r2rTable';
import { TotalBeneficiariesComponent } from 'acr_ui/sources/components/dataPresentation/totalBeneficiaries';
import { StyledDataArrayTableComponent } from 'acr_ui/sources/components/tables/styledDataArrayTable';
import { getReduxConnectedViewModeComponent } from 'acr_ui/sources/components/widgets/viewMode';
import React from 'react';
import { connect } from 'react-redux';

import NestedParametrizedDataContainer from 'src/components/container/nestedParametrizedData';
import BeneficiariesByModality from 'src/components/ReportTemplate/Workspace/BeneficiariesByModality';
import TotalBenByModWrapper from 'src/components/ReportTemplate/Workspace/TotalBenByModWrapper';
import TotalFoodAndCBTWrapper from 'src/components/ReportTemplate/Workspace/TotalFoodAndCBTWrapper';
import { TableParametrizedDataPresentationComponent as RowSpanTable } from 'src/components/TableMain/rowspanTable';

const annualFoodDistributionViewModeOptions =
  getAnnualFoodDistributionViewModeOptions(connect);
const annualCBTCommodityVoucherDistributionViewModeOptions =
  getAnnualCBTCommodityVoucherDistributionViewModeOptions(connect);

const ViewMode = getReduxConnectedViewModeComponent(connect);

export const StrategicOutcomeTitle = props => (
  <span>{props.data.strategic_outcome}</span>
);

export const getDataSourceWidgetsParametersMap = {
  // Summary
  'Annual Food Transfer': {
    noModuleTitle: true,
    title: 'Annual Food Transfer',
    id: 'SUMMARY__annual_food_outcome',
    options: annualFoodDistributionViewModeOptions,
  },
  'Beneficiaries by Programme Area': {
    noModuleTitle: true,
    title: 'Beneficiaries by Programme Area',
    id: 'SUMMARY__beneficiaries_by_programme_area',
    options: getBeneficiariesByProgrammeAreaOptions(),
  },
  'Annual Cash Based Transfer and Commodity Voucher': {
    noModuleTitle: true,
    title: 'Annual Cash Based Transfer and Commodity Voucher',
    id: 'SUMMARY__annual_food_cbt',
    options: annualCBTCommodityVoucherDistributionViewModeOptions,
  },
  'Annual Food Distribution': {
    noModuleTitle: true,
    title: 'Annual Food Distribution',
    id: 'SUMMARY__annual_food_outcome',
    options: annualFoodDistributionViewModeOptions,
  },
  'Annual CBT and Commodity Voucher Distribution': {
    noModuleTitle: true,
    title: 'Annual CBT and Commodity Voucher Distribution',
    id: 'SUMMARY__annual_food_cbt',
    options: annualCBTCommodityVoucherDistributionViewModeOptions,
  },
  'WFP contribution to SDGs': {},

  'Output and Outcome Results': {
    id: 'SO__strategic_outcome_table',
    containerClassName: 'so-table',
  },

  // Strategic Outcome
  'Output Indicators': {
    parametersList: [
      'strategic_result',
      'output',
      'output_category',
      'activity',
    ],
    id: 'SO_output_values_table',
    presentationComponent: RowSpanTable,
    presentationComponentParameters: {
      headers: [
        { key: 'output_detailed_indicator', title: 'Detailed Indicator' },
        { key: 'unit_of_measure', title: 'Unit' },
        { key: 'target_value', title: 'Target Value' },
        { key: 'actual_value', title: 'Actual Value' },
        { key: 'percentage_achieved', title: '% Achieved' },
      ],
    },
  },
  'Outcome Indicators': {
    parametersList: [
      'strategic_result',
      'target_location',
      'activities',
      'modalities',
    ],
    id: 'SO_output_values_table',
    presentationComponent: RowSpanTable,
    presentationComponentParameters: {
      headers: [
        { key: 'strategic_result', title: 'Strategic Result' },
        { key: 'outcome_indicator', title: 'Outcome Indicator' },
        { key: 'target_location', title: 'Target / Location' },
        { key: 'activities', title: 'Activities' },
        { key: 'modalities', title: 'Modalities' },
        { key: 'gender', title: 'Gender' },
        { key: 'base_value', title: 'Base Value' },
        { key: 'latest_followup', title: 'Latest Follow Up' },
        { key: 'year_end_target', title: 'Year End Target' },
        { key: 'csp_end_target', title: 'CSP End Target' },
      ],
    },
  },

  // Crosscutting results
  'Progress towards Gender Equality indicators': {
    id: 'cross-cutting-gender',
    containerClassName: 'so-table',
  },
  'Protection indicators': {
    id: 'cross-cutting-protection',
    containerClassName: 'so-table',
  },
  'Accountability to Affected Population indicators': {
    id: 'cross-cutting-population',
    containerClassName: 'so-table',
  },
  'Accountability to affected people indicators': {
    id: 'cross-cutting-population',
    containerClassName: 'so-table',
  },
  'Environmental sustainability indicators': {
    id: 'cross-cutting-environment',
    containerClassName: 'so-table',
  },
  'Nutrition integration indicators': {
    id: 'cross-cutting-nutrition',
    containerClassName: 'so-table',
  },
};

export const DATA_SOURCES_WIDGETS_MAP = {
  // Summary
  'Total Beneficiaries': TotalBeneficiariesComponent,
  'Beneficiaries by Residence Status': BeneficiariesByResidenceStatusComponent,
  'Beneficiaries by Age Group': BeneficiariesByAgeGroupComponent, // 2018 ACR specific
  'Beneficiaries by Sex and Age Group': BeneficiariesByAgeGroupComponent,
  'Beneficiaries by Programme Area': ViewMode,
  'Beneficiaries by Modality': BeneficiariesByModality,
  'Total Food and CBT': TotalFoodAndCBTWrapper,
  'Total Transfers by Modality': TotalBenByModWrapper,
  'Annual Food Transfer': ViewMode,
  'Annual Food Distribution': ViewMode,
  'Annual Cash Based Transfer and Commodity Voucher': ViewMode,
  'Annual CBT and Commodity Voucher Distribution': ViewMode,
  'WFP contribution to SDGs': StyledDataArrayTableComponent,

  // Strategic Outcome
  'Output Indicators': NestedParametrizedDataContainer,
  'Outcome Indicators': NestedParametrizedDataContainer,
  'Strategic Outcome Title': StrategicOutcomeTitle,
  'Output and Outcome Results': OutputsOutcomesTable,
  'Annual Financial Overview by Strategic Outcome and Activity':
    CSPFinancialOverview,
  'WFP Gender and Age Marker': GaMTable,
  Resources: R2RTable,

  // Crosscutting results
  'Progress towards Gender Equality indicators': CrossCuttingTable,
  'Protection indicators': CrossCuttingTable,
  'Accountability to Affected Population indicators': CrossCuttingTable,
  'Accountability to affected people indicators': CrossCuttingTable,
  'Environmental sustainability indicators': CrossCuttingTable,
  'Nutrition integration indicators': CrossCuttingTable,
};
