import React, { FC } from 'react';
import { sendGAEvent } from 'src/utils';

interface NotificationMessageProps {
  link: string;
  gaParam: string;
}

const NotificationMessage: FC<NotificationMessageProps> = ({
  link,
  gaParam,
}) => {
  return (
    <div>
      <p className="notify-text">Word export is ready.</p>
      <a
        className="download-link open-pdf-link"
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          sendGAEvent('comments_file_summary_downloaded', {
            wfp_type: gaParam,
          })
        }
      >
        Open .docx
      </a>
    </div>
  );
};

export default NotificationMessage;
