import React, { Component } from 'react';

export default class TableContainer extends Component {
  getHeadRows = () => {
    const dataString = JSON.stringify(this.props.data),
      columnOrderIndex = {
        activities: dataString.indexOf('"level_key":"activities",'),
        modalities: dataString.indexOf('"level_key":"modalities",'),
      };

    function compareHeadingIndexes(a, b) {
      const aIndex = columnOrderIndex[a.key],
        bIndex = columnOrderIndex[b.key];
      if (!!aIndex && !!bIndex) return aIndex < bIndex ? -1 : 1;
      return 0;
    }

    return (
      <tr>
        {this.props.headers
          .sort(compareHeadingIndexes)
          .map((heading, index) => (
            <th key={index}>{heading.title}</th>
          ))}
      </tr>
    );
  };

  getDataRows = () => {
    let rows = [];
    for (let i = 0, len = this.props.data.length; i < len; i++) {
      rows = rows.concat(this.assembleRows(this.props.data[i]));
    }
    return rows.map((dataRow, index) => (
      <tr key={index}>
        {dataRow.map((tableCellData, index) => {
          return (
            <td
              colSpan={tableCellData.colspan}
              rowSpan={tableCellData.rowspan}
              className={tableCellData.classname}
              key={index}
            >
              {tableCellData.value}
            </td>
          );
        })}
      </tr>
    ));
  };

  getHeaderKeys = () => {
    return this.props.headers.map(item => item.key);
  };

  getHeaderAssets = () => {
    const assets = {};
    this.props.headers.map(item => {
      assets[item.key] = { rowspan: item.rowspan || 1 };
    });
    return assets;
  };

  getTableCellAssets = () => {
    const assets = {};
    const fullWidthColSpanAsset = { colspan: this.props.headers.length };
    const levelKeyClasses = this.props.levelKeyClasses || {};
    this.props.headers.map(item => {
      assets[item.key] = { rowspan: item.rowspan || 1 };
    });
    (this.props.subTableSplitKeys || []).map(item => {
      assets[item] = {
        classname: 'sub-table-split-td',
        ...fullWidthColSpanAsset,
      };
    });
    for (const key in levelKeyClasses) {
      assets[
        key
      ].classname = `${assets[key].classname} ${levelKeyClasses[key]}`;
    }

    return assets;
  };

  getAnnotationsConf = () => {
    const annotatedKeys = [];
    const annotationSources = [];
    const annotations = this.props.annotations || {};
    for (const key in annotations) {
      annotatedKeys.push(key);
      for (const value of annotations[key]) {
        if (!annotationSources.indexOf(value) !== -1) {
          annotationSources.push(value);
        }
      }
    }
    return {
      annotatedKeys: annotatedKeys,
      annotationSources: annotationSources,
    };
  };

  assembleRows = levelData => {
    const headerKeys = this.getHeaderKeys();
    const headerAssets = this.getHeaderAssets();
    const tableCellAssets = this.getTableCellAssets();
    if (levelData.hasOwnProperty('children')) {
      let childLevelData = [];
      if (
        (this.props.subTableSplitKeys || []).indexOf(levelData.level_key) !== -1
      ) {
        childLevelData.push([
          {
            key: levelData.level_key,
            value: levelData.value,
            ...tableCellAssets[levelData.level_key],
          },
        ]);
      }
      for (let i = 0, len = levelData.children.length; i < len; i++) {
        childLevelData = childLevelData.concat(
          this.assembleRows(levelData.children[i]),
        );
      }
      childLevelData = childLevelData.length === 0 ? [[]] : childLevelData;
      if (headerKeys.indexOf(levelData.level_key) === -1) {
        const annotationConf = this.getAnnotationsConf();
        if (
          annotationConf.annotationSources.indexOf(levelData.level_key) !== -1
        ) {
          for (const row of childLevelData) {
            for (const cell of row) {
              if (annotationConf.annotatedKeys.indexOf(cell.key) !== -1) {
                cell.value = `${levelData.value}; ${cell.value}`;
              }
            }
          }
        }
        return childLevelData;
      } else {
        childLevelData[0].unshift({
          rowspan: childLevelData.length,
          key: levelData.level_key,
          value: levelData.value,
        });
      }
      return childLevelData;
    } else {
      const bottomData = [];
      for (let i = 0, len = headerKeys.length; i < len; i++) {
        const headerKey = headerKeys[i];
        if (levelData.hasOwnProperty(headerKey)) {
          bottomData.push({
            rowspan: headerAssets[headerKey].rowspan,
            key: headerKey,
            value: levelData[headerKey],
          });
        }
      }
      return [bottomData];
    }
  };

  render() {
    return (
      <TablePresentation
        headRows={this.getHeadRows()}
        dataRows={this.getDataRows()}
      />
    );
  }
}

export const TablePresentation = props => (
  <table className="standard-table">
    <thead>{props.headRows}</thead>
    <tbody>{props.dataRows}</tbody>
  </table>
);

export const TableParametrizedDataPresentationComponent = props => (
  <section>
    <header className="parameters">{props.parameterComponents}</header>
    <article>
      <TableContainer
        data={props.data}
        {...props.presentationComponentParameters}
      />
    </article>
  </section>
);

export const sampleData = [
  {
    p1: 'a',
    p2: 'b',
    p3: 'c',
  },
  {
    p1: 'a',
    p2: 'c',
    p3: 'b',
  },
  {
    p1: 'b',
    p2: 'a',
    p3: 'c',
  },
  {
    p1: 'b',
    p2: 'c',
    p3: 'a',
  },
  {
    p1: 'c',
    p2: 'a',
    p3: 'b',
  },
  {
    p1: 'c',
    p2: 'b',
    p3: 'a',
  },
];

export const sampleHeaders = [
  { key: 'p1', title: 'Parameter 1' },
  { key: 'p2', title: 'Parameter 2' },
  { key: 'p3', title: 'Parameter 3' },
];
