import { Button, InlineLoading, Modal } from '@wfp/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fileNameMap, publicationUploadMaxSize } from 'src/constants';
import {
  getActionAPISource,
  receiveAPIResponseHoldingData,
} from 'src/redux/actions';
import {
  PDF_MERGE_LIST,
  REPORT_LIST_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';
import ACRUploadErrorMessage from '../ACRUploadErrorMessage';
import ACRUploadNotificationError from '../ACRUploadNotificationError';

const ACRFileUploadButton = ({ pk, type, projectCode, period }) => {
  const [modal, setModal] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileSelectedError, setFileSelectedError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!modal) {
      setFileSelected(null);
      setFileSelectedError('');
      setFileUploading(false);
    }
  }, [modal]);

  function handleFileChange(e) {
    const newFile = e.target.files[0];

    if (!newFile) return;

    const fileFormat = newFile?.name?.split('.').pop();
    const fileName = newFile?.name?.split('.').shift();

    try {
      if (fileFormat !== 'pdf') throw 'file_type';

      if (newFile?.size / 1024 / 1024 > publicationUploadMaxSize) {
        throw 'file_size';
      }

      if (!fileName.includes(' ')) throw 'file_name';
      const fileNameSplitted = fileName.split(' ');
      if (fileNameSplitted[0] !== projectCode) throw 'file_name_project_id';

      if (!fileNameSplitted[1].includes('-')) throw 'file_name';
      const secondPartSplitted = fileNameSplitted[1].split('-');
      if (secondPartSplitted[0] !== fileNameMap[type]) throw 'file_name';
      if (secondPartSplitted[1] !== 'B') throw 'file_name';

      setFileSelectedError('');
      setFileSelected(newFile);
    } catch (err) {
      console.error(err);

      if (err) {
        setFileSelectedError(err);
        return;
      }
    }
  }

  async function handleFileUpload() {
    setFileUploading(true);

    const formData = new FormData();

    formData.append('pk', pk);
    formData.append('type', type);
    formData.append('period', period);
    formData.append('file', fileSelected, fileSelected.name);

    const url = getActionAPISource(PDF_MERGE_LIST);

    try {
      const publicationList = await apiCallAsync(url, formData, 'post');

      dispatch(
        receiveAPIResponseHoldingData(
          publicationList.data,
          REPORT_LIST_DATA_KEY,
        ),
      );

      setFileUploading(false);
      showNotification('File uploaded successfully', 'success');
      setModal(false);
    } catch (err) {
      console.log(err);

      const defaultMsg = 'Error on file upload. Please retry later';

      setFileUploading(false);
      showNotification(
        <ACRUploadNotificationError
          errors={err?.response?.data || defaultMsg}
        />,
        'error',
      );
      setModal(false);
    }
  }

  return (
    <>
      <Button onClick={() => setModal(true)} data-test-id="choose-file-button">
        Choose file
      </Button>
      {modal && (
        <Modal
          className="acr-file-upload-modal"
          data-test-id="file-upload-popup"
          open={true}
          hideClose
          modalLabel={`File upload - ${projectCode} - ${fileNameMap?.[type]}`}
          onRequestClose={() => null}
          secondaryButtonText="Cancel"
          onSecondarySubmit={() => setModal(false)}
          secondaryButtonDisabled={false}
          primaryButtonText="Upload"
          onRequestSubmit={handleFileUpload}
          primaryButtonDisabled={!fileSelected}
        >
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/pdf"
            id="acr-single-upload"
          />
          <label
            htmlFor="acr-single-upload"
            className="acr-upload-label"
            data-test-id="choose-file-button"
          >
            Choose file
          </label>
          {fileUploading && (
            <InlineLoading
              className="acr-file-upload-loader"
              description="Uploading..."
            />
          )}
          {fileSelectedError && (
            <ACRUploadErrorMessage error={fileSelectedError} />
          )}
          {fileSelected && !fileSelectedError && (
            <div className="acr-file-selected">
              <p data-test-id="selected-file-label">File selected:</p>
              <div data-test-id="selected-file-name">{fileSelected?.name}</div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ACRFileUploadButton;
