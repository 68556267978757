import React, { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import IssuesOverviewTemplate from 'src/components/IssuesOverviewTemplate';

import { clearApiKeys, requestAPI } from 'src/redux/actions';
import { ACR_ISSUES_OVERVIEW_URL } from 'src/redux/report/constants';
import { useParams } from 'react-router-dom';

const IssuesOverview: FC = () => {
  const dispatch = useDispatch();

  const { year, cpb: projectId } = useParams();

  useEffect(() => {
    if (!year || !projectId) return;

    dispatch(
      requestAPI(ACR_ISSUES_OVERVIEW_URL, null, {
        url_format: {
          _period_: year,
          _cpb_key_: projectId,
        },
      }),
    );

    return () => {
      dispatch(clearApiKeys([ACR_ISSUES_OVERVIEW_URL]));
    };
  }, [year, projectId]);

  return <IssuesOverviewTemplate />;
};
export default IssuesOverview;
