import { iconInfoGlyph } from '@wfp/icons';
import { Icon, ModuleFooter } from '@wfp/ui';
import momentTz from 'moment-timezone';
import React from 'react';
import type { FC } from 'react';
import isEmpty from 'lodash/isEmpty';

interface IRefreshInfo {
  date: string;
  type: string;
  user: string;
}

interface Props {
  refreshInfo: IRefreshInfo[];
}

const LatestRefreshInfo: FC<Props> = ({ refreshInfo = [] }) => {
  if (isEmpty(refreshInfo)) return <></>;

  const flatRefreshInfoList = refreshInfo.flat();

  // Get latest date index
  const indexOfMaxValue = flatRefreshInfoList?.reduce(
    (iMax, x, i, arr) =>
      new Date(x.date) > new Date(arr[iMax].date) ? i : iMax,
    0,
  );

  const latestRefreshInfo = flatRefreshInfoList?.[indexOfMaxValue];

  const formattedDate = momentTz
    .tz(latestRefreshInfo.date, 'Europe/Rome')
    .format('Do MMMM YYYY [at] hh:mm:ss A');

  return (
    <ModuleFooter>
      <div className="latest-refresh-info-wrapper">
        <Icon
          fill="grey"
          className="last-refresh-date-icon"
          icon={iconInfoGlyph}
          description="Last Refresh Date"
          data-test-id="latest-refresh-date"
        />
        <div className="latest-refresh-info-text">
          Data refreshed on&nbsp;
          <span title="Central European Time">
            {`${formattedDate} CET`}
          </span>{' '}
          {latestRefreshInfo?.type === 'automatic'
            ? '(automated)'
            : `by ${latestRefreshInfo?.user}`}
        </div>
      </div>
    </ModuleFooter>
  );
};

export default LatestRefreshInfo;
