import { iconChat, iconLaunch, iconRestart } from '@wfp/icons';
import { Button, Icon, Module, ModuleBody } from '@wfp/ui';
import React, { useEffect, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { DisplayToggleWrapper } from 'src/components/DisplayWrapper';
import {
  COMMENTS_TARGET_ID,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { buildErrorComponents, sendGAEvent, showNotification } from 'src/utils';

export const SectionControls = ({
  error = null,
  dataSourceRefresh,
  handleRefreshDataSources,
}) => {
  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY].data,
  );

  const RefreshDataSourceButton = () => {
    const title = dataSourceRefresh?.isFetching
      ? 'Refreshing data-sources...'
      : 'Refresh data sources';

    return (
      <Button
        onClick={() => {
          sendGAEvent('refresh_data_sources_btn_clicked');
          handleRefreshDataSources();
        }}
        title={title}
        data-test-id="refresh-data-sources-button"
        disabled={dataSourceRefresh?.isFetching}
      >
        <Icon icon={iconRestart} description="" {...{ width: '1.2em' }} />
        {title}
      </Button>
    );
  };

  const errorsList: string[] = useMemo(() => {
    const commonErrorComponents = buildErrorComponents(error);
    const refreshDataSetErrorComponents = buildErrorComponents(
      dataSourceRefresh?.error,
    );

    const notifications = [];

    for (const errors of [
      commonErrorComponents,
      refreshDataSetErrorComponents,
    ]) {
      if (errors.length > 0) {
        notifications.push(errors);
      }
    }
    return notifications;
  }, [error, dataSourceRefresh?.error]);

  useEffect(() => {
    if (errorsList.length > 0) {
      showNotification(<>{errorsList.map(error => error)}</>, 'error', {
        autoClose: false,
        closeOnClick: false,
      });
    }
  }, [errorsList]);

  return (
    <Module className="special-module-title section-controls">
      <ModuleBody>
        <DisplayToggleWrapper
          targetId={COMMENTS_TARGET_ID}
          component={Button}
          title="Comments"
          data-test-id="show-comments-button"
        >
          <Icon icon={iconChat} description="Comments" />
          Show comments
        </DisplayToggleWrapper>

        <Button
          href={sectionDetailData?.guidance_link}
          title="ACR Guidance link"
          data-test-id="ACR Guidance link"
          onClick={() =>
            sendGAEvent('acr_guidance_link_clicked', { wfp_position: 'header' })
          }
        >
          <Icon icon={iconLaunch} description="ACR Guidance" />
          ACR Guidance
        </Button>
        {sectionDetailData?.data_source_refresh_allowed &&
          reportDetailData &&
          reportDetailData?.state !== 'Published' && (
            <RefreshDataSourceButton />
          )}
      </ModuleBody>
    </Module>
  );
};

export default SectionControls;
