import * as msal from '@azure/msal-browser';

import { loginRequest, msalConfig } from 'src/auth/config';
import store from 'src/redux/store';

// Create the main msalApp instance
export const msalApp = new msal.PublicClientApplication(msalConfig);
msalApp.initialize();

export function msSignIn() {
  msalApp.loginRedirect(loginRequest);
}

export function msSignOut() {
  msalApp.logout();
}

export async function getTokenRedirect(request = loginRequest, account = null) {
  const customLoginData = store.getState()?.customLogin;

  // If user is logged with custom login return the token from the Redux store
  if (customLoginData?.accessToken) {
    const { accessToken } = customLoginData;

    return {
      accessToken,
    };
  }

  // If user is logged using Microsoft call "acquireTokenSilent" and return the token
  let requestAccount = account;
  if (requestAccount === null) {
    const accounts = await msalApp.getAllAccounts();
    if (accounts && accounts.length === 1) {
      requestAccount = accounts[0];
    }
  }
  request = { ...request, account: requestAccount };
  if (!requestAccount) {
    msSignIn();
    return null;
  }

  return await msalApp.acquireTokenSilent(request).catch(error => {
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      console.log('Silent token acquisition fails! ');
      console.log('Acquiring token using redirect.');
      return msalApp.acquireTokenRedirect(request);
    }
    throw error;
  });
}

export function seeProfile() {
  return getTokenRedirect(loginRequest);
}
