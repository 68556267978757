import { Module, ModuleBody, ModuleHeader } from '@wfp/ui';
import React from 'react';

const titles = ['Programme Performance', 'Summary'];

const BottomLinks = ({ report, currentTitle }) => {
  if (!report || !report.links || !titles.includes(currentTitle)) return null;

  const links = [];

  if (currentTitle === titles[0]) {
    links.push(
      <li key={currentTitle}>
        <h2>Supplementary reading</h2>
      </li>,
    );
    for (const item of report.links.programme_performance) {
      links.push(
        <li key={item.url}>
          <a
            href={item.url}
            target="_blank"
            className="sections-action"
            rel="noreferrer"
          >
            {item.credit}
          </a>
        </li>,
      );
    }
  } else if (currentTitle === titles[1]) {
    links.push(<h2 key={currentTitle}>Supplementary reading</h2>);
    if (report.links.summary) {
      for (const item of report.links.summary) {
        links.push(
          <li key={item.url}>
            <a
              href={item.url}
              target="_blank"
              className="sections-action"
              rel="noreferrer"
            >
              {item.credit}
            </a>
          </li>,
        );
      }
    }
  }

  return (
    <Module>
      <ModuleHeader>Links</ModuleHeader>
      <ModuleBody>{links}</ModuleBody>
    </Module>
  );
};

export default BottomLinks;
