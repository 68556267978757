import { iconDelete } from '@wfp/icons';
import { Button, Icon } from '@wfp/ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getActionAPISource,
  receiveAPIResponseHoldingData,
} from 'src/redux/actions';
import { REPORT_LIST_DATA_KEY } from 'src/redux/report/constants';
import { PDF_MERGE_LIST } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { openACRFile, showNotification } from 'src/utils';
import ACRFileUploadButton from '../ACRFileUploadButton';

const ACRFileCell = ({ type, value, rowValues, period }) => {
  const { project_code, merge_status, pk } = rowValues;

  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  async function deleteFile() {
    const url = getActionAPISource(PDF_MERGE_LIST);

    try {
      const response = await apiCallAsync(`${url}${pk}/${type}`, {}, 'delete');

      showNotification('File deleted successfully', 'success');

      dispatch(
        receiveAPIResponseHoldingData(response.data, REPORT_LIST_DATA_KEY),
      );
      setIsDeleting(false);
    } catch (err) {
      console.error(err);
      showNotification('Error on delete file. Please retry later', 'error');
      setIsDeleting(false);
    }
  }

  function handleDelete() {
    if (!isDeleting) {
      setIsDeleting(true);
      deleteFile();
    }
  }

  if (!value) {
    return (
      <ACRFileUploadButton
        pk={pk}
        type={type}
        projectCode={project_code}
        period={period}
      />
    );
  }

  return (
    <div className="acr-file-cell">
      <div
        className="acr-file-link"
        onClick={() => openACRFile(`https://${value}`)}
        data-test-id="show-file-link"
      >
        Show file
      </div>
      {merge_status !== 'Done' && (
        <Button
          onClick={handleDelete}
          disabled={isDeleting}
          data-test-id="delete-button"
        >
          <Icon
            description="Delete"
            fill="#fff"
            icon={iconDelete}
            className="noselect"
          />
        </Button>
      )}
    </div>
  );
};

export default ACRFileCell;
