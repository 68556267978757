import { Blockquote, Modal, SingleDatePickerInput, Tag } from '@wfp/ui';
import Select from 'react-select';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import { DATE_FORMAT } from 'src/constants';
import { requestAPI } from 'src/redux/actions';
import { updateReviewData } from 'src/redux/report/actions';
import {
  GET_ALL_REVIEW_STATE_DATA,
  GET_APP_CONFIGURATION,
} from 'src/redux/report/constants';
import ConfirmationModal from 'src/components/ConfirmationModal';

interface IOption {
  value: string;
  label: string;
}

interface Props {
  reportPk: number;
  hasPermissionToModify: boolean;
  setShowModal: (val: boolean) => void;
}

const ReviewGroupModal: FC<Props> = ({
  reportPk,
  hasPermissionToModify,
  setShowModal,
}) => {
  const [deadline, setDeadline] = useState(null);
  const [reviewState, setReviewState] = useState(null);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const reviewStateData = useSelector(
    (state: RootStateOrAny) => state.viewData[GET_ALL_REVIEW_STATE_DATA],
  );
  const userData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[GET_APP_CONFIGURATION]?.data,
  );
  const isHqAdmin = userData.userInfo?.roles.includes('hq_admin');
  const dispatch = useDispatch();

  useEffect(() => {
    if (reviewStateData && reviewStateData.data) {
      if (reviewStateData.data.active_choice) {
        setReviewState(reviewStateData.data.active_choice);
      }
      if (reviewStateData.data.deadline) {
        setDeadline(moment(reviewStateData.data.deadline));
      } else {
        setDeadline(null);
      }
    }
  }, [reviewStateData]);

  const options: IOption[] = reviewStateData?.data?.choices
    .sort((a: string, b: string) => a.localeCompare(b))
    .filter(
      (option: string) =>
        option !== 'HQ Technical Units' ||
        (option === 'HQ Technical Units' && isHqAdmin),
    )
    .map((option: string) => ({
      label: option,
      value: option,
    }));

  useEffect(() => {
    dispatch(
      requestAPI(GET_ALL_REVIEW_STATE_DATA, null, {
        url_format: { _id_: reportPk },
      }),
    );
  }, [reportPk]);

  function submitReviewData() {
    if (!reviewStateData.data.notifications_info) return;

    dispatch(
      updateReviewData({
        choice: reviewState,
        deadline: deadline ? deadline.format(DATE_FORMAT) : '',
        reportPk,
        notifications_info: reviewStateData.data.notifications_info,
      }),
    );
  }

  function handleSubmit() {
    if (reviewState === 'HQ Technical Units' && deadline) {
      setShowConfirmationModal(true);
    } else {
      submitReviewData();
      setShowModal(false);
    }
  }

  function getOptionValue() {
    return { label: reviewState, value: reviewState };
  }

  const formatOptionLabel = ({ value, label }) => (
    <>
      {label}
      {reviewStateData?.data &&
        reviewStateData.data?.active_choice === value && (
          <Tag type="info" className="review-group-modal-current">
            Current reviewer
          </Tag>
        )}
    </>
  );

  return (
    <>
      {!showConfirmationModal && (
        <Modal
          modalHeading={'Set review group'}
          className="set-review-status-modal-content"
          open={true}
          onRequestClose={() => setShowModal(false)}
          secondaryButtonText="Cancel"
          primaryButtonDisabled={!hasPermissionToModify || !valuesUpdated}
          primaryButtonText="Set review group"
          onRequestSubmit={handleSubmit}
        >
          <div
            className="modal fade text-left"
            id="set-review-status-modal-acr"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="review-group-modal-subtitle">
                    Select who should review the ACR and the desired due date.
                  </div>

                  <div className="set-review-status-modal-form">
                    <form>
                      <div className="row">
                        <span className="set-review-status-modal-review-group-title">
                          Review group
                        </span>
                        <div className="col-lg-6 review_state_radio">
                          <Select
                            classNamePrefix="wfp--react-select"
                            onChange={(val: IOption) => {
                              setValuesUpdated(true);
                              setReviewState(val.value);
                            }}
                            value={getOptionValue()}
                            formatOptionLabel={formatOptionLabel}
                            name="bureauFilters"
                            id="bureauFilter"
                            options={options}
                            disabled={reviewStateData?.isFetching}
                          />
                        </div>
                      </div>
                      <div className="set-review-status-modal-datepicker-container">
                        <span className="set-review-status-modal-datepicker-title">
                          Due date
                        </span>
                        <span className="col-lg-2">
                          <SingleDatePickerInput
                            labelText=""
                            inputIconPosition="after"
                            datePicker={SingleDatePicker}
                            disabled={
                              reviewStateData?.isFetching ||
                              (!isHqAdmin &&
                                reviewState === 'HQ Technical Units')
                            }
                            value={deadline}
                            // @ts-ignore: 'displayFormat' is a ReactDatePicker prop
                            displayFormat="DD/MM/YYYY"
                            onChange={(date: any) => {
                              let newDate = null;
                              if (date && date?.isValid()) {
                                newDate = date;
                              }
                              setValuesUpdated(true);
                              setDeadline(newDate);
                            }}
                            showDefaultInputIcon
                          />
                        </span>
                      </div>
                    </form>
                    {reviewState === 'HQ Technical Units' && deadline ? (
                      <Blockquote
                        icon={<span className="review-icon icon-send-mail" />}
                      >
                        {reviewStateData?.data?.notifications_info > 0 ? (
                          <>
                            <b>
                              HQ Technical Units will be notified by automatic
                              email to finalize their review
                            </b>{' '}
                            by the selected due date.
                          </>
                        ) : (
                          <>
                            <b>
                              HQ Technical Units will be notified by automatic
                              email to start their review
                            </b>{' '}
                            and complete it by the selected due date.
                          </>
                        )}
                      </Blockquote>
                    ) : (
                      <Blockquote>
                        Selected review group will not be notified by automatic
                        email. Please inform them of the review status update
                        and the desired due date.
                      </Blockquote>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          open={true}
          onSubmit={() => {
            submitReviewData();
            setShowModal(false);
          }}
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          heading="Email sending confirmation"
          content={
            <>
              <div className="confirmation-sending-mail">
                An automatic email with the updated review status and the
                desired due date will be sent to all HQ Technical Units.
                <br />
                <br />
                Do you want to continue?
              </div>
            </>
          }
          primaryButtonText="Send notification"
          secondaryButtonText="Cancel"
          customClassName="review-group-modal-confirmation"
        />
      )}
    </>
  );
};

export default ReviewGroupModal;
