import { call, fork, put, select, take } from 'redux-saga/effects';

import { API_HOST, RESET_CUSTOM_LOGIN_DATA_REQUEST } from 'src/constants';
import { resetCustomLoginDataSuccess } from 'src/redux/actions';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

// Take only first Saga effect
function* takeFirst(pattern, saga, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });
  return task;
}

function* ResetCustomLoginSaga() {
  try {
    const url = `${API_HOST}/jwt/token/logout/`;

    const refresh = yield select(state => state.customLogin.refreshToken);

    if (!refresh) {
      return;
    }

    const response = yield call(apiCallAsync, url, { refresh }, 'post');

    if (response.status === 200) {
      yield put(resetCustomLoginDataSuccess());
      window.location.href = '/';
    } else {
      throw 'Reset Custom Login API call has failed';
    }
  } catch (e) {
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* watchCustomLoginSaga() {
  yield takeFirst(RESET_CUSTOM_LOGIN_DATA_REQUEST, ResetCustomLoginSaga);
}
