import {
  TotalCashComponent,
  TotalComodityVouchersComponent,
  TotalFoodComponent,
} from 'acr_ui/sources/components/dataPresentation/totalFoodAndCBT';
import React from 'react';

const TotalFoodAndCBTWrapper = props => {
  return (
    <section className="total-food-n-cbt-wrapper">
      <TotalFoodComponent
        data={props.data.food_and_report_block_food_mt || {}}
        currentPeriod={props.currentPeriod}
      />
      <TotalCashComponent
        data={props.data['annual-cbt-distribution'] || {}}
        currentPeriod={props.currentPeriod}
      />
      {props.report.state !== 'Published' &&
      parseInt(props.currentPeriod) >= 2021 ? (
        <TotalComodityVouchersComponent
          data={props.data['annual-cbt-distribution']}
          currentPeriod={props.currentPeriod}
        />
      ) : null}
    </section>
  );
};

export default TotalFoodAndCBTWrapper;
