import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestAPIHoldingData } from 'src/redux/actions';
import { getActionAPISource } from 'src/redux/actions';
import { SECTION_DETAIL_DATA_KEY } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  showNotification,
} from 'src/utils';

export const useRefreshDataSource = () => {
  const sectionDetailData = useSelector(
    state => state.viewData[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const viewState = useSelector(state => state.viewState);

  const updatedActiveSectionID = useRef(viewState?.activeSectionID);
  const updatedSectionTitle = useRef(null);
  const isPollingActive = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (viewState?.activeSectionID) {
      updatedActiveSectionID.current = viewState.activeSectionID;
    }
  }, [viewState.activeSectionID]);

  useEffect(() => {
    if (sectionDetailData?.title) {
      updatedSectionTitle.current = sectionDetailData.title;
    }
  }, [sectionDetailData?.title]);

  function isChildOfCrossCuttingResults(sectionTitle = '') {
    const crossCuttingResultsTitles = [
      "Gender equality and women's empowerment",
      'Protection and accountability to affected populations',
      'Environmental sustainability',
      'Nutrition integration',
      'Protection and accountability to affected people',
    ];

    return crossCuttingResultsTitles.includes(sectionTitle);
  }

  function handleRefreshDataSources() {
    const url = `${getActionAPISource('SECTION_REFRESH_DATA_SOURCE')}`.replace(
      '_id_',
      viewState.activeSectionID,
    );
    apiCallAsync(url, {}, 'post')
      .then(response => {
        if (response.status === 200) {
          showNotification(response.data.result, 'info', {
            autoClose: false,
            toastId: viewState.activeSectionID,
            className: 'data-refresh-notification',
          });
          if (response.data.extra) {
            setLocalStorageItem('delayed_notification', '+', true);
            if (!isPollingActive.current) {
              handleRefreshStatusCheck();
            }
          }
        }
      })
      .catch(error => {
        if (error.response) {
          showNotification(
            `${error.message}: ${error.response.data.error}`,
            'error',
            { autoClose: false },
          );
        }
      });
  }

  function handleRefreshStatusCheck() {
    const url = `${getActionAPISource('NOTIFICATIONS_DELAYED_URL')}`;
    if (parseInt(getLocalStorageItem('delayed_notification')) > 0) {
      isPollingActive.current = true;

      apiCallAsync(url, {}).then(response => {
        if (response.status === 200) {
          for (const each of response.data) {
            if (!!each.status && each.status === 'success') {
              showNotification(
                `Data refresh successfully completed for ${each.section_name}`,
                'success',
                { autoClose: false },
              );
              setLocalStorageItem('delayed_notification', '-', true);

              let sectionID = null;

              // Get new data only if user still on same section or same section group
              if (updatedActiveSectionID.current === each.section) {
                sectionID = each.section;
              } else if (
                (updatedSectionTitle.current.startsWith('Strategic outcome') &&
                  each.section_name.startsWith('Strategic outcome')) ||
                (isChildOfCrossCuttingResults(updatedSectionTitle.current) &&
                  isChildOfCrossCuttingResults(each.section_name))
              ) {
                sectionID = updatedActiveSectionID.current;
              }

              if (sectionID) {
                dispatch(
                  requestAPIHoldingData(SECTION_DETAIL_DATA_KEY, null, {
                    url_format: { _id_: sectionID },
                  }),
                );
              }
            } else if (!!each.status && each.status === 'failed') {
              showNotification(
                `Failed refresh for ${each.section_name}`,
                'error',
                { autoClose: false },
              );
              setLocalStorageItem('delayed_notification', '-', true);
            }
          }
        }
      });

      setTimeout(() => handleRefreshStatusCheck(), 5000);
    } else {
      isPollingActive.current = false;
    }
  }

  return [handleRefreshDataSources];
};
