import {
  ADD_EXTRA_SECTION_ACTION,
  CHANGE_SECTIONS_CONTENT_MODE_ACTION,
  CHANGE_VIEW_STATE,
  CLEAN_REPORT_DATA_ACTION,
  CLEAR_VIEW_STATE,
  PULL_REQUESTED_PDF,
  SELECT_OPTION_ACTION,
  SELECT_SECTION_ACTION,
  SET_PAGINATION_ACTION,
  SET_SEARCH_BOX_ACTION,
  STOP_PULLING_REQUESTED_PDF,
} from 'src/redux/report/constants';

export const viewState = (state = {}, action) => {
  let cleanedState;
  switch (action.type) {
    case CLEAR_VIEW_STATE:
      return {};
    case CHANGE_VIEW_STATE:
      return { ...state, ...action.value };

    case SELECT_SECTION_ACTION:
    case CHANGE_SECTIONS_CONTENT_MODE_ACTION:
      return {
        ...state,
        [action.dataKey]: action.value,
      };
    case SELECT_OPTION_ACTION:
      return {
        ...state,
        [action.dataKey]: selectBox(state[action.dataKey], action),
      };
    case SET_PAGINATION_ACTION:
      return {
        ...state,
        [action.dataKey]: changePage(state[action.dataKey], action),
      };
    case SET_SEARCH_BOX_ACTION:
      return {
        ...state,
        [action.dataKey]: action.value,
      };
    case CLEAN_REPORT_DATA_ACTION:
      cleanedState = { ...state };
      delete cleanedState['activeSectionID'];
      return cleanedState;

    case PULL_REQUESTED_PDF:
      return {
        ...state,
        [PULL_REQUESTED_PDF]: true,
      };
    case STOP_PULLING_REQUESTED_PDF:
      cleanedState = { ...state };
      delete cleanedState[PULL_REQUESTED_PDF];
      return cleanedState;

    case ADD_EXTRA_SECTION_ACTION:
    default:
      return state;
  }
};

export const selectBox = (state = {}, action) => {
  switch (action.type) {
    case SELECT_OPTION_ACTION:
      return {
        ...state,
        [action.selectBoxID]: action.value,
      };
    default:
      return state;
  }
};

export const changePage = (state = {}, action) => {
  switch (action.type) {
    case SET_PAGINATION_ACTION:
      return {
        ...state,
        [action.paginatorId]: action.value,
      };
    default:
      return state;
  }
};
