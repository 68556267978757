// COMMON
export const SET_ROUTES_CONFIG = 'SET_ROUTES_CONFIG';

export const API_HOST = process.env.REACT_APP_API_HOST;
export const SPR_HOST = process.env.REACT_APP_SPR_HOST;
export const WEB_REP_HOST = process.env.WEB_REPORT_HOST;
export const REQUEST_API = 'REQUEST_API';
export const RECEIVE_FROM_API = 'RECEIVE_FROM_API';
export const RECEIVE_FROM_API_HOLDING_DATA = 'RECEIVE_FROM_API_HOLDING_DATA';
export const RECEIVE_INFOGRAPHICS_FROM_API = 'RECEIVE_INFOGRAPHICS_FROM_API';
export const REQUEST_API_FAILURE = 'REQUEST_API_FAILURE';
export const REQUEST_API_FAILURE_HOLDING_DATA =
  'REQUEST_API_FAILURE_HOLDING_DATA';
export const CLEAR_API_KEYS = 'CLEAR_API_KEYS';
export const REQUEST_API_HOLDING_DATA = 'REQUEST_API_HOLDING_DATA';
export const REQUEST_API_LATEST = 'REQUEST_API_LATEST';
export const RECEIVE_DATA_NOTES_FROM_API = 'RECEIVE_DATA_NOTES_FROM_API';
export const RECEIVE_NARRATIVE_FROM_API = 'RECEIVE_NARRATIVE_FROM_API';
export const RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API =
  'RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API';
export const COMMENT_REQUEST_API = 'COMMENT_REQUEST_API';
export const COMMENT_REPLY_REQUEST_API = 'COMMENT_REPLY_REQUEST_API';
export const SET_NARRATIVE_ACTION = 'SET_NARRATIVE_ACTION';
export const SET_EXTRA_NARRATIVE_ACTION = 'SET_EXTRA_NARRATIVE_ACTION';
export const SET_SUBTITLE_ACTION = 'SET_SUBTITLE_ACTION';
export const SET_SUBTITLE_SUCCESS_ACTION = 'SET_SUBTITLE_SUCCESS_ACTION';
export const RECEIVE_EXTRA_NARRATIVE_FROM_API =
  'RECEIVE_EXTRA_NARRATIVE_FROM_API';
export const RESTORE_COMMENT_REQUEST_API = 'RESTORE_COMMENT_REQUEST_API';

export const GET_PUBLICATION_LIST_REQUEST = 'GET_PUBLICATION_LIST_REQUEST';
export const RESET_REPORTS_LIST = 'RESET_REPORTS_LIST';

export const LOCK_MULTINARRATIVE_ACTION = 'LOCK_MULTINARRATIVE_ACTION';
export const LOCK_MULTINARRATIVE_ACTION_SUCCESS =
  'LOCK_MULTINARRATIVE_ACTION_SUCCESS';
export const CANCEL_MULTINARRATIVE_ACTION = 'CANCEL_MULTINARRATIVE_ACTION';
export const CANCEL_MULTINARRATIVE_ACTION_SUCCESS =
  'CANCEL_MULTINARRATIVE_ACTION_SUCCESS';
export const SAVE_MULTINARRATIVE_ACTION = 'SAVE_MULTINARRATIVE_ACTION';
export const SAVE_MULTINARRATIVE_ACTION_SUCCESS =
  'SAVE_MULTINARRATIVE_ACTION_SUCCESS';
export const RESTORE_VERSION_MULTINARRATIVE_ACTION =
  'RESTORE_VERSION_MULTINARRATIVE_ACTION';
export const RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS =
  'RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS';
export const FORCE_UNLOCK_MULTINARRATIVE_ACTION =
  'FORCE_UNLOCK_MULTINARRATIVE_ACTION';
export const FORCE_UNLOCK_MULTINARRATIVE_ACTION_SUCCESS =
  'FORCE_UNLOCK_MULTINARRATIVE_ACTION_SUCCESS';
export const MULTI_NARRATIVE_LOCK = 'MULTI_NARRATIVE_LOCK';
export const MULTI_NARRATIVE_CANCEL = 'MULTI_NARRATIVE_CANCEL';
export const MULTI_NARRATIVE_UPDATE = 'MULTI_NARRATIVE_UPDATE';
export const MULTI_NARRATIVE_VERSIONS = 'MULTI_NARRATIVE_VERSIONS';
export const MULTI_NARRATIVE_VERSION_RESTORE =
  'MULTI_NARRATIVE_VERSION_RESTORE';
export const MULTI_NARRATIVE_FORCE_UNLOCK = 'MULTI_NARRATIVE_FORCE_UNLOCK';
export const RESET_MULTINARRATIVE_ACTION = 'RESET_MULTINARRATIVE_ACTION';
export const RECEIVE_MULTI_NARRATIVE_FROM_API =
  'RECEIVE_MULTI_NARRATIVE_FROM_API';

export const REQUEST_POST_ACR_INFO_BOX_SUCCESS =
  'REQUEST_POST_ACR_INFO_BOX_SUCCESS';

// Signal reduces cases
export const CLEAN_SIGNALS = 'CLEAN_SIGNALS';
export const ADD_SIGNALS = 'ADD_SIGNALS';
export const TOGGLE_SIGNALS = 'TOGGLE_SIGNALS';

// Singals
export const UPDATE = 'UPDATE';
export const SUCCESS_REQUEST = 'SUCCESS_REQUEST';

export const SET_CUSTOM_LOGIN_DATA = 'SET_CUSTOM_LOGIN_DATA';
export const RESET_CUSTOM_LOGIN_DATA_REQUEST =
  'RESET_CUSTOM_LOGIN_DATA_REQUEST';
export const RESET_CUSTOM_LOGIN_DATA_SUCCESS =
  'RESET_CUSTOM_LOGIN_DATA_SUCCESS';

export const ICON_KWARGS = {
  height: '.8em',
  fill: 'white',
  style: { marginLeft: '.5em' },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const fileNameMap = {
  acr_1: 'ACR1',
  acr_5: 'ACR5',
};

export const publicationUploadMaxSize = 25;

export const maxCommentTitleCharacters = 150;

export const commentsErrorsMessages = {
  title: {
    empty: 'Please fill out the comment title field',
    maxLength: `Comment title can't exceed ${maxCommentTitleCharacters} characters`,
  },
  content: {
    empty: 'Please fill out the comment message field',
  },
};

export const CRF23 = 'CRF 2022-2025';

export const wordCountAllowedSections = [
  'Programme performance',
  'Cross-cutting Results',
];

export const narrativeAutosaveInterval = 30000;

export const rolesToLabels = {
  co_approver: 'Co Approver',
  co_coordinator: 'Co Coordinator',
  coordinator: 'Coordinator',
  editor: 'Editor',
  hq_admin: 'HQ Admin',
  hq_reviewer: 'HQ Reviewer',
  rd_approver: 'RD Approver',
  viewer: 'Viewer',
};

export const TRANSITION_LABELS = {
  send_to_review: 'Send to Review',
  send_to_cd_approval: 'Send to CD Approval',
  send_to_rd_approval: 'Send to RD Approval',
  send_to_rd_approved: 'Approve',
  publish: 'Publish',
  back_to_initial_draft: 'Back to Initial Draft',
  back_to_review: 'Back to Review',
  back_to_cd_approval: 'Back to CD Approval',
  back_to_rd_approval: 'Back to RD Approval',
  back_to_rd_approved: 'Back to RD Approved',
};

export const TRANSITION_AIM = {
  send_to_review: 'review',
  send_to_cd_approval: 'cd_approval',
  send_to_rd_approval: 'rd_approval',
  send_to_rd_approved: 'rd_approved',
  publish: 'published',
  back_to_initial_draft: 'initial_draft',
  back_to_review: 'review',
  back_to_cd_approval: 'cd_approval',
  back_to_rd_approval: 'rd_approval',
  back_to_rd_approved: 'rd_approved',
};

export const forward = 'forward';
export const backward = 'backward';
export const TRANSITION_DIRECTION = {
  send_to_review: forward,
  send_to_cd_approval: forward,
  send_to_rd_approval: forward,
  send_to_rd_approved: forward,
  publish: forward,
  back_to_initial_draft: backward,
  back_to_review: backward,
  back_to_cd_approval: backward,
  back_to_rd_approval: backward,
  back_to_rd_approved: backward,
};

export const CURRENT_STATE_LABELS = {
  new: 'New',
  initial_draft: 'Initial Draft',
  review: 'Review',
  cd_approval: 'CD Approval',
  rd_approval: 'RD Approval',
  rd_approved: 'RD Approved',
  published: 'Published',
};

export const REVIEW_BUTTONS = {
  set_review_status: 'Set review status',
  technical_review: 'Technical review',
};
