import {
  ADD_SIGNALS,
  API_HOST,
  CANCEL_MULTINARRATIVE_ACTION,
  CANCEL_MULTINARRATIVE_ACTION_SUCCESS,
  CLEAN_SIGNALS,
  CLEAR_API_KEYS,
  COMMENT_REPLY_REQUEST_API,
  COMMENT_REQUEST_API,
  FORCE_UNLOCK_MULTINARRATIVE_ACTION,
  FORCE_UNLOCK_MULTINARRATIVE_ACTION_SUCCESS,
  GET_PUBLICATION_LIST_REQUEST,
  LOCK_MULTINARRATIVE_ACTION,
  LOCK_MULTINARRATIVE_ACTION_SUCCESS,
  RECEIVE_DATA_NOTES_FROM_API,
  RECEIVE_EXTRA_NARRATIVE_FROM_API,
  RECEIVE_FROM_API,
  RECEIVE_FROM_API_HOLDING_DATA,
  RECEIVE_INFOGRAPHICS_FROM_API,
  RECEIVE_MULTI_NARRATIVE_FROM_API,
  RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API,
  RECEIVE_NARRATIVE_FROM_API,
  REQUEST_API,
  REQUEST_API_FAILURE,
  REQUEST_API_FAILURE_HOLDING_DATA,
  REQUEST_API_HOLDING_DATA,
  REQUEST_API_LATEST,
  REQUEST_POST_ACR_INFO_BOX_SUCCESS,
  RESET_CUSTOM_LOGIN_DATA_REQUEST,
  RESET_CUSTOM_LOGIN_DATA_SUCCESS,
  RESET_MULTINARRATIVE_ACTION,
  RESET_REPORTS_LIST,
  RESTORE_COMMENT_REQUEST_API,
  RESTORE_VERSION_MULTINARRATIVE_ACTION,
  RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS,
  SAVE_MULTINARRATIVE_ACTION,
  SAVE_MULTINARRATIVE_ACTION_SUCCESS,
  SET_CUSTOM_LOGIN_DATA,
  SET_EXTRA_NARRATIVE_ACTION,
  SET_NARRATIVE_ACTION,
  SET_SUBTITLE_ACTION,
  SET_SUBTITLE_SUCCESS_ACTION,
  TOGGLE_SIGNALS,
} from 'src/constants';

export const sendSignalsAction = signalsValuesPool => {
  return {
    type: ADD_SIGNALS,
    signalsValuesPool,
  };
};

export const cleanSignalsAction = signalsToDrop => {
  return {
    type: CLEAN_SIGNALS,
    signalsToDrop,
  };
};

export const toggleSignalsAction = signalsToToggle => {
  return {
    type: TOGGLE_SIGNALS,
    signalsToToggle,
  };
};

export const requestAPI = (dataKey, id, params = {}) => {
  return {
    type: REQUEST_API,
    dataKey,
    id,
    params,
  };
};

export const commentRequestAPI = (params = {}) => {
  return {
    type: COMMENT_REQUEST_API,
    params,
  };
};

export const commentReplyRequestAPI = (params = {}) => {
  return {
    type: COMMENT_REPLY_REQUEST_API,
    params,
  };
};

export const commentRestoreRequestAPI = (params = {}) => {
  return {
    type: RESTORE_COMMENT_REQUEST_API,
    params,
  };
};

export const setNarrativeVal = params => {
  return {
    type: SET_NARRATIVE_ACTION,
    value: params.val,
  };
};

export const lockMultiNarrative = params => {
  return {
    type: LOCK_MULTINARRATIVE_ACTION,
    narrativeId: params.narrativeId,
    reportId: params.reportId,
  };
};

export const lockMultiNarrativeSuccess = params => {
  return {
    type: LOCK_MULTINARRATIVE_ACTION_SUCCESS,
    narrativeId: params.narrativeId,
    editor: params.editor,
    content: params.content,
  };
};

export const cancelMultiNarrative = params => {
  return {
    type: CANCEL_MULTINARRATIVE_ACTION,
    narrativeId: params.narrativeId,
    reportId: params.reportId,
  };
};

export const cancelMultiNarrativeSuccess = params => {
  return {
    type: CANCEL_MULTINARRATIVE_ACTION_SUCCESS,
    value: params.value,
  };
};

export const forceUnlockMultiNarrative = params => {
  return {
    type: FORCE_UNLOCK_MULTINARRATIVE_ACTION,
    narrativeId: params.narrativeId,
    reportId: params.reportId,
  };
};

export const forceUnlockMultiNarrativeSuccess = params => {
  return {
    type: FORCE_UNLOCK_MULTINARRATIVE_ACTION_SUCCESS,
    narrativeId: params.narrativeId,
    editor: params.editor,
  };
};

export const saveMultiNarrative = params => {
  return {
    type: SAVE_MULTINARRATIVE_ACTION,
    narrativeId: params.narrativeId,
    content: params.content,
    wordCount: params.wordCount,
    autoSave: params.autoSave,
    reportId: params?.reportId,
  };
};

export const saveMultiNarrativeSuccess = params => {
  return {
    type: SAVE_MULTINARRATIVE_ACTION_SUCCESS,
    value: params.value,
    autosave: params.autosave,
  };
};

export const resetMultiNarrative = params => {
  return {
    type: RESET_MULTINARRATIVE_ACTION,
    narrativeId: params.narrativeId,
  };
};

export const restoreVersionMultiNarrative = params => {
  return {
    type: RESTORE_VERSION_MULTINARRATIVE_ACTION,
    narrativeVersionId: params.narrativeVersionId,
    content: params.content,
    narrativeId: params.narrativeId,
  };
};

export const restoreVersionMultiNarrativeSuccess = params => {
  return {
    type: RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS,
    content: params.content,
    narrativeId: params.narrativeId,
  };
};

export const setSubtitleVal = params => {
  return {
    type: SET_SUBTITLE_ACTION,
    value: params.val,
    sectionId: params.sectionId,
  };
};

export const setSubtitleValSuccess = params => {
  return {
    type: SET_SUBTITLE_SUCCESS_ACTION,
    value: params.value,
  };
};

export const setExtraNarrativeVal = params => {
  return {
    type: SET_EXTRA_NARRATIVE_ACTION,
    value: params.val,
    id: params.id,
  };
};

export const requestAPILatest = (dataKey, id, params = {}) => {
  return {
    type: REQUEST_API_LATEST,
    dataKey,
    id,
    params,
  };
};

export const requestAPIHoldingData = (dataKey, id, params = {}) => {
  return {
    type: REQUEST_API_HOLDING_DATA,
    dataKey,
    id,
    params,
  };
};

export const clearApiKeys = dataKeys => {
  return {
    type: CLEAR_API_KEYS,
    dataKeys: dataKeys,
  };
};

export const requestAPIFailed = (dataKey, error, timestamp) => {
  return {
    type: REQUEST_API_FAILURE,
    dataKey,
    error,
    timestamp,
  };
};

export const requestAPIFailedHoldingData = (dataKey, error, timestamp) => {
  return {
    type: REQUEST_API_FAILURE_HOLDING_DATA,
    dataKey,
    error,
    timestamp,
  };
};

export const receiveAPIResponse = (dataKey, payload, request_params) => {
  return {
    type: RECEIVE_FROM_API,
    dataKey,
    payload,
    request_params,
  };
};

export const receiveAPIResponseHoldingData = (payload, dataKey) => {
  return {
    type: RECEIVE_FROM_API_HOLDING_DATA,
    payload,
    dataKey,
  };
};

export const setCustomLoginData = payload => {
  return {
    type: SET_CUSTOM_LOGIN_DATA,
    payload,
  };
};

export const resetCustomLoginDataSuccess = () => {
  return {
    type: RESET_CUSTOM_LOGIN_DATA_SUCCESS,
  };
};

export const resetCustomLoginDataRequest = () => {
  return {
    type: RESET_CUSTOM_LOGIN_DATA_REQUEST,
  };
};

export const receivePostAcrInfoBoxSuccess = (payload, dataKey) => {
  return {
    type: REQUEST_POST_ACR_INFO_BOX_SUCCESS,
    payload,
    dataKey,
  };
};

export const receiveAPIInfographicsResponse = (
  dataKey,
  json,
  request_params,
) => {
  return {
    type: RECEIVE_INFOGRAPHICS_FROM_API,
    dataKey,
    payload: json,
    request_params,
  };
};

export const receiveNarrativeResponse = payload => {
  return {
    type: RECEIVE_NARRATIVE_FROM_API,
    payload,
  };
};

export const receiveMultiNarrativeResponse = payload => {
  return {
    type: RECEIVE_MULTI_NARRATIVE_FROM_API,
    payload,
  };
};

export const receiveMultiNarrativeSingleResponse = payload => {
  return {
    type: RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API,
    payload,
  };
};

export const receiveExtraNarrativeResponse = payload => {
  return {
    type: RECEIVE_EXTRA_NARRATIVE_FROM_API,
    payload,
  };
};

export const receiveDataNotesResponse = (dataKey, json, request_params) => {
  return {
    type: RECEIVE_DATA_NOTES_FROM_API,
    dataKey,
    payload: json,
    request_params,
  };
};

export const getPublicationListRequest = payload => {
  return {
    type: GET_PUBLICATION_LIST_REQUEST,
    payload,
  };
};

export const resetReportsList = () => {
  return {
    type: RESET_REPORTS_LIST,
  };
};

export const getActionAPISource = dataKey => {
  try {
    let url = window.djconfig.APIS[dataKey];
    if (url === undefined) {
      throw 'no such an API';
    }
    url = `${API_HOST}` + `/${url}`.replace(/\/+/g, '/');
    return url;
  } catch (e) {
    throw `Failed to resolve URI of API source: "${dataKey}"`;
  }
};

export const getAppUri = (relativeUri = '') => {
  let appRootUri = '';
  try {
    appRootUri = window.djconfig.appRoot;
  } catch (e) {
    console.warn('Failed to fetch App root URI. Using relative URI instead.');
  }
  return `/${appRootUri}/${relativeUri}/`.replace(/\/+/g, '/');
};
