import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SelectBox } from 'src/components/widgets/SelectBox';
import { SELECT_OPTION_STATE_KEY } from 'src/redux/report/constants';
import { getTitleForKey } from 'src/utils/dataSources';

export const makeSelectBoxID = (id, parameter) => id + '__' + parameter;

export class ParametrizedDataContainer extends Component {
  prepareParameterComponents = () => {
    const gatheredValues = this.gatherParametersForSelectBoxes();
    return gatheredValues.map((item, index) => (
      <SelectBox key={index} {...item} />
    ));
  };

  getNestedParameterValuesSet = (data, parameterName, valuesSet) => {
    for (let i = 0, len = data.length; i < len; i++) {
      const currentItem = data[i];

      // collect parameter values of current level
      if (
        currentItem.hasOwnProperty('level_key') &&
        currentItem.hasOwnProperty('value') &&
        currentItem.level_key === parameterName &&
        valuesSet.indexOf(currentItem.value) === -1
      ) {
        valuesSet.push(currentItem.value);
      }
      // collect parameter values of nesting level
      for (const key of Object.keys(currentItem)) {
        const value = currentItem[key];
        if (Array.isArray(value)) {
          this.getNestedParameterValuesSet(value, parameterName, valuesSet);
        }
      }
    }

    return valuesSet;
  };

  filterNestingLevels = (data, parameterName, parametersValue) => {
    const filteredData = [];

    // filter the top level
    for (let i = 0, len = data.length; i < len; i++) {
      const currentItem = data[i];
      if (
        currentItem.hasOwnProperty('level_key') &&
        currentItem.level_key === parameterName &&
        currentItem.value !== parametersValue
      ) {
        // skip the value that mismatches the parameter's value
      } else {
        filteredData.push(currentItem);
      }
    }

    // filter all the nesting arrays
    for (let i = 0, len = filteredData.length; i < len; i++) {
      const currentItem = filteredData[i];
      let hasArrayChild = false,
        hasNotEmptyArrayChild = false;

      for (const key of Object.keys(currentItem)) {
        const value = currentItem[key];
        if (Array.isArray(value)) {
          if (!hasArrayChild) {
            hasArrayChild = true;
          }
          currentItem[key] = this.filterNestingLevels(
            value,
            parameterName,
            parametersValue,
          );
          if (currentItem[key].length > 0) {
            hasNotEmptyArrayChild = true;
          }
        }
      }
      if (hasArrayChild && !hasNotEmptyArrayChild) {
        filteredData[i] = null;
      }
    }
    return filteredData.filter(x => !!x);
  };

  getCurrentValueOfParameter = parameter => {
    return this.props.currentParameterValues[parameter].value;
  };

  gatherParametersForSelectBoxes = () => {
    let dataCopy = JSON.parse(JSON.stringify(this.props.data));
    const selectBoxes = [];
    let previousParameter, previousValue;
    for (let i = 0, len = this.props.parametersList.length; i < len; i++) {
      const parameter = this.props.parametersList[i],
        selectBoxID = makeSelectBoxID(this.props.id, parameter),
        parameterValueSet = this.getNestedParameterValuesSet(
          dataCopy,
          parameter,
          [],
        ),
        currentParameterValue = this.getCurrentValueOfParameter(parameter),
        fallbackValue = { title: getTitleForKey(parameter), value: undefined };

      const selectBoxItem = {
        title: parameter,
        id: selectBoxID,
        options: parameterValueSet.map(item => ({
          title: item.toString(),
          value: item,
        })),
      };
      selectBoxItem.options.unshift(fallbackValue);
      selectBoxes.push(selectBoxItem);
      if (
        typeof currentParameterValue !== 'undefined' &&
        parameter !== previousParameter &&
        currentParameterValue !== previousValue
      ) {
        dataCopy = this.filterNestingLevels(
          dataCopy,
          parameter,
          currentParameterValue,
        );
      }
      previousParameter = parameter;
      previousValue = currentParameterValue;
    }
    return selectBoxes;
  };

  getDataWithAppliedParameters = () => {
    let dataCopy = JSON.parse(JSON.stringify(this.props.data));
    for (let i = 0, len = this.props.parametersList.length; i < len; i++) {
      const parameter = this.props.parametersList[i];
      const currentParameterValue = this.getCurrentValueOfParameter(parameter);
      if (typeof currentParameterValue !== 'undefined') {
        dataCopy = this.filterNestingLevels(
          dataCopy,
          parameter,
          currentParameterValue,
        );
      }
    }
    return dataCopy;
  };

  render() {
    const data = this.getDataWithAppliedParameters(),
      PresentationComponent =
        this.props.presentationComponent || DefaultDataPresentationComponent,
      presentationComponentParameters =
        this.props.presentationComponentParameters || {};
    return (
      <PresentationComponent
        data={data}
        currentParameterValues={this.props.currentParameterValues}
        parameterComponents={this.prepareParameterComponents()}
        presentationComponentParameters={presentationComponentParameters}
      />
    );
  }
}

export const nestingLevelToUl = (data, ulIndexKey) => {
  const currentLevelItems = data.map((item, index) => {
    const itemValues = [];
    let valueIndex = 0;
    for (const key of Object.keys(item)) {
      const value = item[key];
      if (Array.isArray(value)) {
        itemValues.push(nestingLevelToUl(value, ++valueIndex));
      } else {
        itemValues.push(
          <p key={++valueIndex}>
            <strong>{key}</strong>: {JSON.stringify(value)}
          </p>,
        );
      }
    }
    return <li key={index}>{itemValues}</li>;
  });
  return <ul key={ulIndexKey}>{currentLevelItems}</ul>;
};

export const DefaultDataPresentationComponent = props => {
  return (
    <section>
      <header className="parameters">
        {props.parameterComponents}
        <p>{JSON.stringify(props.currentParameterValues)}</p>
      </header>
      <article>{nestingLevelToUl(props.data)}</article>
    </section>
  );
};

export const mapStateToProps = (state, ownProps) => {
  const currentParameterValues = {};
  for (let i = 0, len = ownProps.parametersList.length; i < len; i++) {
    let selectBoxState;
    try {
      selectBoxState =
        state.viewState[SELECT_OPTION_STATE_KEY][
          makeSelectBoxID(ownProps.id, ownProps.parametersList[i])
        ] || {};
    } catch (e) {
      selectBoxState = {};
    }
    currentParameterValues[ownProps.parametersList[i]] = selectBoxState;
  }
  const newState = {
    currentParameterValues: currentParameterValues,
  };
  return newState;
};

const NestedParametrizedData = connect(mapStateToProps)(
  ParametrizedDataContainer,
);
export default NestedParametrizedData;

export const sampleData = [
  {
    value:
      'Strategic Result 3 - Smallholders have improved food security and nutrition',
    level_key: 'strategic_result',
    children: [
      {
        value: 'Consumption-based Coping Strategy Index (Average)',
        level_key: 'outcome_indicator',
        children: [
          {
            value: 'Chuquisaca',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≤14.95',
                        latest_followup: '6.41',
                        year_end_target: '≤14.95',
                        base_value: '14.95',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Oruro',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≤20.79',
                        latest_followup: '8.35',
                        year_end_target: '≤20.79',
                        base_value: '20.79',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Potosi',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≤13.97',
                        latest_followup: '7.4',
                        year_end_target: '≤13.97',
                        base_value: '13.96',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Tarija',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≤21.44',
                        latest_followup: '12.25',
                        year_end_target: '≤21.44',
                        base_value: '21.44',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value: 'Dietary Diversity Score',
        level_key: 'outcome_indicator',
        children: [
          {
            value: 'Chuquisaca',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '>3.95',
                        latest_followup: '8.91',
                        year_end_target: '>3.95',
                        base_value: '3.95',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Oruro',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '>6.49',
                        latest_followup: '9.1',
                        year_end_target: '>6.49',
                        base_value: '6.49',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Potosi',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '>4.08',
                        latest_followup: '8.5',
                        year_end_target: '>4.08',
                        base_value: '4.08',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Tarija',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '>0',
                        latest_followup: '0',
                        year_end_target: '>0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '>4.83',
                        latest_followup: '8.81',
                        year_end_target: '>4.83',
                        base_value: '4.83',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value:
          'Food Consumption Score  / Percentage of households with Acceptable Food Consumption Score',
        level_key: 'outcome_indicator',
        children: [
          {
            value: 'Chuquisaca',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥80',
                        latest_followup: '100',
                        year_end_target: '≥80',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Oruro',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥88',
                        latest_followup: '89.2',
                        year_end_target: '≥88',
                        base_value: '41.4',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Potosi',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥80',
                        latest_followup: '92',
                        year_end_target: '≥80',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Tarija',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥80',
                        latest_followup: '87',
                        year_end_target: '≥80',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value:
          'Food Consumption Score  / Percentage of households with Borderline Food Consumption Score',
        level_key: 'outcome_indicator',
        children: [
          {
            value: 'Chuquisaca',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥20',
                        latest_followup: '0',
                        year_end_target: '≥20',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Oruro',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥12',
                        latest_followup: '10.8',
                        year_end_target: '≥12',
                        base_value: '35.7',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Potosi',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≤0',
                        latest_followup: '0',
                        year_end_target: '≤0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≤20',
                        latest_followup: '8',
                        year_end_target: '≤20',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Tarija',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '≥0',
                        latest_followup: '0',
                        year_end_target: '≥0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '≥20',
                        latest_followup: '13',
                        year_end_target: '≥20',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value:
          'Food Consumption Score  / Percentage of households with Poor Food Consumption Score',
        level_key: 'outcome_indicator',
        children: [
          {
            value: 'Chuquisaca',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Oruro',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '0',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '0',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '22.9',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Potosi',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: 'Tarija',
            level_key: 'target_location',
            children: [
              {
                value: 'Value Voucher',
                level_key: 'modalities',
                children: [
                  {
                    value:
                      'ACL: Provide conditional transfers to food insecure families in disaster affected and disaster prone areas',
                    level_key: 'activities',
                    children: [
                      {
                        gender: 'male',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'female',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                      {
                        gender: 'overall',
                        csp_end_target: '=0',
                        latest_followup: '0',
                        year_end_target: '=0',
                        base_value: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const sampleParametersList = [
  // 'strategic_outcome',
  'strategic_result',
  'outcome_indicator',
  'target_location',
  'modalities',
  'activities',
];
