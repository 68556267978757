import React from 'react';

const uploadErrorsBeMapping = {
  1: 'File type not allowed',
  2: 'File name not valid',
  3: 'Operation ID not found',
  4: "It's not possible to upload files of already merged reports",
  5: 'File exceeds size limit',
  6: 'Server side error',
};

const ACRUploadNotificationError = ({ errors }) => {
  return (
    <ul className="acr-multi-upload-errors-msg">
      <b>Errors on files upload:</b>
      {Object.keys(errors).map(key => {
        return (
          <div key={key} className="acr-multi-upload-errors-msg-file">
            <div className="muem-title">{key} :</div>
            {errors[key].map(item => (
              <li key={item}>{uploadErrorsBeMapping?.[item]}</li>
            ))}
          </div>
        );
      })}
    </ul>
  );
};

export default ACRUploadNotificationError;
