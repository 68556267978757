import { Button } from '@wfp/ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import TableMain from 'src/components/TableMain';
import { API_HOST } from 'src/constants';
import { receiveAPIResponseHoldingData } from 'src/redux/actions';
import { REPORT_LIST_DATA_KEY } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { openACRFile, showNotification } from 'src/utils';
import OperationIdCell from '../OperationIdCell';
import ACRFileCell from './ACRFileCell';

const PublicationStatusTab = ({ data, filterText, filterSelect, period }) => {
  const dispatch = useDispatch();
  const [reportSubmitting, setReportSubmitting] = useState(null);

  async function submitReport(pk) {
    setReportSubmitting(pk);
    const url = `${API_HOST}/api/acr-merge/submit/${pk}/`;

    try {
      const response = await apiCallAsync(url, null, 'post');

      dispatch(
        receiveAPIResponseHoldingData(response.data, REPORT_LIST_DATA_KEY),
      );
      showNotification('Report submitted successfully', 'success');
      setReportSubmitting(null);
    } catch (err) {
      console.error(err);

      showNotification('Error while submitting the report', 'error');
      setReportSubmitting(null);
    }
  }

  function calcReportSubmitted({ link, preview_link, pk }) {
    if (link) return 'Yes';
    if (preview_link) {
      return (
        <Button
          disabled={reportSubmitting === pk}
          onClick={() => submitReport(pk)}
          data-test-id="submit-button"
        >
          Submit
        </Button>
      );
    }

    return 'No';
  }

  const columns = [
    {
      Header: 'Operation ID',
      accessor: 'project_code',
      id: 'project_code_column',
      Filter: column => filterText(column),
      Cell: ({ row }) => <OperationIdCell row={row} />,
    },
    {
      Header: 'ACR 5',
      accessor: 'acr_5_file_url',
      id: 'acr_5_file_url_column',
      Filter: () => null,
      Cell: ({ value, row: { original } }) => (
        <ACRFileCell
          type="acr_5"
          value={value}
          rowValues={original}
          period={period}
        />
      ),
    },
    {
      Header: 'ACR 1',
      accessor: 'acr_1_file_url',
      id: 'acr_1_file_url_column',
      Filter: () => null,
      Cell: ({ value, row: { original } }) => (
        <ACRFileCell
          type="acr_1"
          value={value}
          rowValues={original}
          period={period}
        />
      ),
    },
    {
      Header: 'Project Status',
      accessor: 'current_status',
      id: 'current_status_column',
      Cell: ({ value }) => (
        <div className="publication-project-status">{value}</div>
      ),
      Filter: column => filterSelect('current_status', column),
    },
    {
      Header: 'Merge Status',
      accessor: 'merge_status',
      id: 'merge_status_column',
      filter: 'equals',
      Filter: column => filterSelect('merge_status', column, true),
    },
    {
      Header: 'Report Submitted',
      accessor: 'report_submitted',
      id: 'report_submitted_column',
      Filter: () => null,
      Cell: ({ row: { original } }) => calcReportSubmitted(original),
    },
    {
      Header: 'Report Link',
      accessor: 'report_link',
      id: 'report_link_column',
      Filter: () => null,
      Cell: ({
        row: {
          original: { link, preview_link },
        },
      }) => {
        if (link)
          return (
            <a
              className="acr-file-link file-submitted"
              href={link}
              target="_blank"
              rel="noreferrer"
              data-test-id="show-submitted-acr-link"
            >
              Show submitted ACR
            </a>
          );
        if (preview_link)
          return (
            <div
              className="acr-file-link"
              onClick={() => openACRFile(`https://${preview_link}`)}
              data-test-id="show-acr-preview-link"
            >
              Show ACR preview
            </div>
          );
        return '';
      },
    },
  ];

  return (
    <TableMain
      data={data}
      loading={!data || data.length === 0}
      columns={columns}
      pagination="client"
      className="-striped -highlight"
      isFilterable
      initialState={{
        sortBy: [
          {
            id: 'project_code_column',
            desc: false,
          },
        ],
      }}
    />
  );
};

export default PublicationStatusTab;
