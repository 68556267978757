import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';

const OperationIdCell = ({ row }) => {
  const routesConfig = useSelector(state => state.routesConfig);
  const reportLinkPattern = routesConfig.root.sub_routes.report_detail.path;

  return (
    <NavLink
      className="project-number-link"
      data-test-id="operation-id-link"
      to={generatePath(reportLinkPattern, {
        cpb: row.original.project_code,
        year: row.original.period,
      })}
    >
      {row.original.project_code}
    </NavLink>
  );
};

export default OperationIdCell;
