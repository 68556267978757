import { call, put, takeLatest } from 'redux-saga/effects';

import { SET_SUBTITLE_ACTION } from 'src/constants';
import { getActionAPISource } from 'src/redux/actions';
import { EDIT_CUSTOM_SUBTITLE } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';
import { setSubtitleValSuccess } from '../actions';

export function* SubtitleApiCallSaga(action) {
  try {
    const url = getActionAPISource(EDIT_CUSTOM_SUBTITLE);

    const params = {
      section_id: action.sectionId,
      custom_subtitle: action.value,
    };

    const response = yield call(apiCallAsync, url, params, 'post');

    if (response.status >= 200 && response.status < 300) {
      yield put(
        setSubtitleValSuccess({
          value: response.data.new_custom_subtitle,
        }),
      );
    } else {
      throw 'Set Subtitle API call has failed';
    }
  } catch (e) {
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* setSubtitleSaga() {
  yield takeLatest(SET_SUBTITLE_ACTION, SubtitleApiCallSaga);
}
