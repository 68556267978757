import React from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { generatePath } from 'react-router-dom';

import { format } from 'date-fns';

import ToastNotification from 'src/components/ToastNotification';
import { wordCountAllowedSections } from 'src/constants';
import {
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import store from 'src/redux/store';
import { IDate, IErrorObject, IUser, TNotificationType } from 'src/types';

interface IStorageItem {
  value: number;
  expiry?: number;
}

type TOperation = '+' | '-';

export function formatFileSize(number: number) {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'kB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
}

export const getLocalStorageItem = (itemKey: string) => {
  const oldValue = localStorage.getItem(itemKey);
  if (!oldValue) return null;

  const oldItem = JSON.parse(oldValue);

  const now = new Date();
  if (oldItem.expiry && now.getTime() > oldItem.expiry) {
    localStorage.removeItem(itemKey);
    return null;
  }

  return oldItem.value;
};

export const setLocalStorageItem = (
  itemKey: string,
  operation: TOperation,
  hasExpiration: boolean,
  hours = 2,
) => {
  let oldValue = getLocalStorageItem(itemKey);
  if (oldValue) {
    oldValue = parseInt(oldValue);
  } else {
    oldValue = 0;
  }

  let value = 0;

  if (operation === '+') {
    value = oldValue + 1;
  } else if (operation === '-') {
    value = oldValue - 1;
  }

  const item: IStorageItem = { value };

  if (hasExpiration) {
    const now = new Date();
    const hoursToMs = hours * 3600000;
    item.expiry = now.getTime() + hoursToMs;
  }

  localStorage.setItem(itemKey, JSON.stringify(item));
};

export const setCookie = (name: string, value: number) => {
  document.cookie = `${name}=${value}; path=/`;
};

// Given a cookie name get its value
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export function replaceNbsps(str: string) {
  var re = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(re, ' ');
}

export const countWords = (s: string) => {
  // return zero value if no text was provided
  if (!s) return 0;
  if (typeof String.prototype.trim === 'undefined') {
    String.prototype.trim = function () {
      return String(this).replace(/^\s+|\s+$/g, '');
    };
  }
  s.trim();

  s = replaceNbsps(s)
    .replaceAll(/<[^>]+>/g, '')
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&nbsp;', ' ')
    .replaceAll(/\n/g, ' ');
  s = s.replace(/&ndash;/g, ' &ndash;');
  s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
  s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
  return s.split(' ').filter(function (str) {
    return str !== '';
  }).length;
};

export const showNotification = (
  msg: string | JSX.Element,
  type: TNotificationType,
  options = {},
) => {
  if (!msg) return;

  switch (type) {
    case 'success':
      toast.success(<ToastNotification msg={msg} type={type} />, options);
      break;
    case 'warning':
      toast.warn(<ToastNotification msg={msg} type={type} />, options);
      break;
    case 'error':
      toast.error(<ToastNotification msg={msg} type={type} />, options);
      break;
    case 'info':
    default:
      toast.info(<ToastNotification msg={msg} type={type} />, options);
      break;
  }
};

export const newLinesAsBr = (text: string | null) => {
  if (!text) return '';
  return text.replace(/\r?\n/g, '<br/>');
};

export const buildErrorComponents = (errorsObject: IErrorObject[]) => {
  const errorComponents = [];
  if (errorsObject) {
    errorComponents.push(
      errorsObject?.['message']
        ? errorsObject['message']
        : errorsObject.toString(),
    );
  }
  return errorComponents;
};

export function replaceParamsInUrl(url, params) {
  if (params.hasOwnProperty('url_format')) {
    for (const [key, value] of Object.entries(params.url_format)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }
  }

  return url;
}

// Given a narrative text with some anchors return an array wihch contains all the anchors
export function anchorsArrayFromNarrative(narrativeText: string) {
  if (!narrativeText) return [];

  let narrativeArray = narrativeText.split('id="');
  narrativeArray.shift();
  narrativeArray = narrativeArray.map(item => item.split('" name', 1)[0]);

  return narrativeArray;
}

export function calcMergeStatus(report) {
  const { current_status, acr_1_file_url, acr_5_file_url, link, preview_link } =
    report;

  let mergeStatus = 'Ready';

  if (current_status !== 'published' || !acr_1_file_url || !acr_5_file_url) {
    mergeStatus = 'Not ready';
  }

  if (link || preview_link) mergeStatus = 'Done';

  return mergeStatus;
}

export async function openACRFile(link: string) {
  try {
    const pdfResponse = await apiCallAsync(link, {}, 'get', 'blob');
    const data = pdfResponse.data;
    const fileURL = URL.createObjectURL(data);
    window.open(fileURL);
  } catch (err) {
    console.error(err);

    showNotification('Error while getting the file', 'error');
  }
}

export function sendGAEvent(eventName: string, additionalParams = {}) {
  if (!process.env.REACT_APP_GA_4 || !eventName) return;

  const report = store.getState().viewData?.[REPORT_DETAIL_DATA_KEY]?.data;
  const section = store.getState().viewData?.[SECTION_DETAIL_DATA_KEY]?.data;

  ReactGA.event(eventName, {
    wfp_country: report?.country,
    wfp_period: report?.period,
    wfp_project_code: report?.project_code,
    wfp_section_title: section?.title,
    ...additionalParams,
  });
}

export function getReportPageTitle(country = '', period = '') {
  return `${country} - ${period}`;
}

export function getShowWordcount(currentTitle: string, canChange: boolean) {
  return wordCountAllowedSections.includes(currentTitle) && canChange;
}

export function validateEmail(email: string) {
  const re =
    // eslint-disable-next-line max-len
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,3})*$/;

  return re.test(email);
}

export function capitalizeWords(text: string) {
  if (!text) return '';

  const words = text.split(' ');
  const res = words.map(word => {
    let formattedWord = word.charAt(0).toUpperCase();
    for (let i = 1; i < word.length; i++) {
      if (word[i - 1] === "'" && i < word.length - 1) {
        formattedWord += word[i];
      } else {
        formattedWord += word[i].toLowerCase();
      }
    }
    return formattedWord;
  });

  return res.join(' ');
}

export function getDateWithTimezone(date: IDate) {
  if (!date) return null;

  return `${format(new Date(date.date), 'd MMMM yyyy, HH:mm')} (${
    date?.timezone
  })`;
}

export function getUserInfo(userObj: IUser) {
  if (!userObj) return '';

  if (userObj?.full_name) return userObj.full_name;

  return userObj?.username;
}

export function getReportListUrl(dashboardPath: string, currentYear: string) {
  if (!dashboardPath || !currentYear) return '/';

  return generatePath(dashboardPath, { year: parseInt(currentYear) });
}
