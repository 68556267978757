import { iconEdit, iconForum } from '@wfp/icons';
import { Icon } from '@wfp/ui';
import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';

import { requestAPI } from 'src/redux/actions';
import { selectSection } from 'src/redux/report/actions';
import {
  APPLY_CHANGE_TITLE_KEY,
  REPORT_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';

export class SidebarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { expandedSections: [] };
  }

  componentDidMount() {
    this.handleRefresh();
  }

  selectLink(item) {
    const newTitle = this.props.newTitle.data.new_title;
    const title =
      newTitle && item.custom_title === 'Extra Section'
        ? newTitle
        : item.current_title;
    if (title === decodeURI(this.props.location.hash.slice(1)))
      this.handleSelection(item.section_pk);
  }

  componentDidUpdate() {
    if (!this.props.activeSectionID && !!this.props.data.sections.length) {
      if (!this.props.location.hash)
        this.handleSelection(this.props.data.sections[0].section_pk);

      this.props.data.sections.forEach(item => {
        this.selectLink(item);
        if (item.children_list) {
          item.children_list.forEach(chItem => {
            this.selectLink(chItem);
          });
        }
      });
    }
  }

  handleRefresh = () => {
    this.props.dispatchRequestData(null, {
      url_format: { _year_: this.props.year, _cpb_: this.props.cpb },
    });
  };
  handleSelection = id => {
    this.props.dispatchSelect(id);
  };

  handleExpandingToggle = id => {
    let sectionIdMatch = false;
    const expandedSections = [];
    for (let i = 0, len = this.state.expandedSections.length; i < len; i++) {
      const item = this.state.expandedSections[i];
      if (item === id) {
        sectionIdMatch = true;
      } else {
        expandedSections.push(item);
      }
    }
    if (!sectionIdMatch) expandedSections.push(id);
    this.setState(() => ({
      expandedSections: expandedSections,
    }));
  };

  buildNavigationTreeProps = (item, level = 0) => {
    const newTitle = this.props.newTitle.data.new_title;
    const activeSectionID = this.props.activeSectionID;
    const title =
      newTitle &&
      ['Country office story', 'Extra Section'].includes(item.custom_title)
        ? newTitle
        : item.current_title;

    const onSelect =
      !!item.only_title || item.section_pk === activeSectionID
        ? undefined
        : this.handleSelection.bind(this, item.section_pk);

    const children = item.children_list
      ? item.children_list.map(item =>
          this.buildNavigationTreeProps(item, level + 1),
        )
      : [];

    return {
      title: title,
      iAmEditor: item.iAmEditor,
      onSelect: onSelect,
      onlyTitle: item.only_title,
      onExpandingToggle: undefined,
      expanded: true,
      isActive: item.section_pk === activeSectionID,
      children: children,
      commentsCount: item.comments_count,
    };
  };

  render() {
    const { data: { sections = [] } = {} } = this.props;

    const presentationProps = sections.map(this.buildNavigationTreeProps);

    return (
      <aside className="report-sidebar" data-test-id="report-sidebar">
        <SectionNavigation data={presentationProps} />
      </aside>
    );
  }
}

export function IAmEditorIcon() {
  return (
    <>
      <Icon
        icon={iconEdit}
        className="edit-icon"
        description="You are editing this section"
      />
      <p className="note">You are editing this section</p>
    </>
  );
}

export function CommentsCount(props) {
  const helpText = `
    ${props.count ? props.count : 'No'} open issues in the section.`;

  return (
    <div className="comment-count">
      <Icon
        icon={iconForum}
        fill="grey"
        className="comment-icon"
        data-test-id="comment-icon"
        description={helpText}
      />
      <div className="tooltip">
        {props.count}
        <span className="tooltip-text">{helpText}</span>
      </div>
    </div>
  );
}

const CustomLinkSidebar = forwardRef(({ navigate, ...props }, ref) => {
  const history = useHistory();

  function onClickHandler() {
    if (!props.onClick) return;
    props.onClick();
    history.replace({ hash: props.href.slice(1) });
  }

  return (
    <div
      className="custom-link-sidebar"
      ref={ref}
      {...props}
      onClick={onClickHandler}
      data-test-id={props['data-test-id']}
    >
      {props.children}
    </div>
  );
});

export class SectionNavigation extends Component {
  mapDataToNavigationTreeProps = (item, index) => {
    const wrapClassNames = [
      item.expanded ? 'expanded' : '',
      item.children.length > 0 ? 'has-children' : '',
      item.isActive ? 'active' : '',
      item.level ? 'level-' + item.level : '',
      item.iAmEditor ? 'edited' : '',
    ]
      .filter(x => !!x)
      .join(' ');

    const children = item.expanded
      ? item.children.map(this.mapDataToNavigationTreeProps)
      : [];

    const titleClassName = item.onlyTitle
      ? 'section-title only-title'
      : 'section-title';

    return (
      <li key={index} className={wrapClassNames}>
        <div className={titleClassName}>
          <Link
            component={CustomLinkSidebar}
            to={{ hash: item.title }}
            onClick={item.onSelect}
            data-test-id={`${item.title.toLowerCase().replace(/ /g, '-')}-link`}
          >
            {item.title}
          </Link>
          {item.commentsCount ? (
            <CommentsCount count={item.commentsCount} />
          ) : null}
        </div>

        {item.iAmEditor ? <IAmEditorIcon /> : null}
        {children ? <ul>{children}</ul> : null}
      </li>
    );
  };

  render() {
    return (
      <>
        <h6 className="sidebar-title">Table of contents</h6>
        <ul>{this.props.data.map(this.mapDataToNavigationTreeProps)}</ul>
      </>
    );
  }
}

export const mapStateToProps = state => {
  return {
    data: { sections: [] },
    isFetching: false,
    error: null,
    activeSectionID: 0,
    newTitle: {
      data: {},
      ...state.viewData[APPLY_CHANGE_TITLE_KEY],
    },
    ...state.viewData[REPORT_DETAIL_DATA_KEY],
    ...state.viewState,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    dispatchRequestData: (id, params = {}) => {
      dispatch(requestAPI(REPORT_DETAIL_DATA_KEY, id, params));
    },
    dispatchSelect: id => {
      dispatch(selectSection(id));
    },
  };
};

const ReportSidebar = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContainer),
);

export default ReportSidebar;
