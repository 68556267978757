import { Wrapper } from '@wfp/ui';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import UsersManagementTemplate from 'src/components/UsersManagementTemplate';

export const UsersManagementComponent = () => (
  <>
    <Helmet>
      <title>Users Management</title>
    </Helmet>

    <Wrapper pageWidth="lg" spacing="md">
      <UsersManagementTemplate />
    </Wrapper>
  </>
);

export const mapStateToProps = state => {
  return { routesConfig: state.routesConfig };
};

const UsersManagement = connect(mapStateToProps)(UsersManagementComponent);

export default UsersManagement;
