import { Button, Loading } from '@wfp/ui';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getTokenRedirect, msalApp, msSignIn } from 'src/auth/auth';
import CustomLoginForm from 'src/components/CustomLoginForm';
import { API_HOST } from 'src/constants';
import { setCustomLoginData } from 'src/redux/actions';
import { showNotification } from 'src/utils';

const AuthState = {
  Authenticated: 'Authenticated',
  UnAuthenticated: 'UnAuthenticated',
  InProgress: 'InProgress',
};

function AuthProvider(props) {
  const location = useLocation();
  const isCustomLoginPage = location?.pathname === '/custom-login';

  const dispatch = useDispatch();

  const [account, setAccount] = useState(null);
  const [authState, setAuthState] = useState(AuthState.UnAuthenticated);
  const [, setAccessToken] = useState(null);
  const [error, setError] = useState(null);

  async function login() {
    if (authState !== AuthState.UnAuthenticated) {
      return;
    }
    setAuthState(AuthState.InProgress);
    msSignIn();
  }

  async function customLogin(username, password) {
    axios
      .post(`${API_HOST}/jwt/token/login/`, {
        username,
        password,
      })
      .then(response => {
        dispatch(
          setCustomLoginData({
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
          }),
        );
        return;
      })
      .catch(e => {
        console.log('Error on custom login ', e);
        setAuthState(AuthState.UnAuthenticated);
      });
  }

  const loginCallBack = useCallback(() => {
    if (authState !== AuthState.UnAuthenticated) {
      return;
    }
    setAuthState(AuthState.InProgress);
  }, [authState]);

  useEffect(() => {
    if (authState === AuthState.InProgress) {
      setTimeout(() => {
        getTokenRedirect()
          .then(tokenResponse => {
            if (tokenResponse) {
              setAccount(tokenResponse.account);
              setAuthState(AuthState.Authenticated);
              setAccessToken(tokenResponse.accessToken);
            }
          })
          .catch(e => {
            console.warn(e);
            console.log("Can't find auth token. Call re-login.");
            msSignIn();
          });
      }, 500);
    }
  }, [authState]);

  useEffect(() => {
    function handleResponse(tokenResponse) {
      if (tokenResponse) {
        setAccount(tokenResponse.account);
        setAuthState(AuthState.Authenticated);
        setAccessToken(tokenResponse.accessToken);
      } else if (
        props.forceLogin &&
        !error &&
        authState === AuthState.UnAuthenticated
      ) {
        loginCallBack();
      }
    }

    // Skip the MS login if page is Custom Login
    if (isCustomLoginPage) return;

    msalApp
      .handleRedirectPromise()
      .then(handleResponse)
      .catch(error => {
        setAuthState(AuthState.UnAuthenticated);
        setError(error.errorMessage);
        showNotification(error.errorMessage, 'warning');
      });
  }, [authState, error, props.forceLogin, loginCallBack, isCustomLoginPage]);

  useEffect(() => {
    if (account) console.log(`Welcome ${account.username}`);
  }, [account]);

  if (error) {
    return (
      <div>
        Auth Error: {error} <Button onClick={login}>login</Button>
      </div>
    );
  }
  if (authState === AuthState.Authenticated) {
    return props.children;
  }
  if (authState === AuthState.UnAuthenticated && isCustomLoginPage) {
    return <CustomLoginForm customLogin={customLogin} />;
  }
  if (authState === AuthState.UnAuthenticated && !props.forceLogin)
    return (
      <div>
        <Button onClick={login}>Login</Button>
      </div>
    );

  return <Loading withOverlay={true} className="page-loader" />;
}

export default AuthProvider;
