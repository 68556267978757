import { Button, TextArea, TextInput } from '@wfp/ui';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';

import {
  commentsErrorsMessages,
  maxCommentTitleCharacters,
} from 'src/constants';

const CommentsTextInput = props => {
  const {
    displayTitleInput,
    titleValue = '',
    contentValue,
    handleChangeTitle,
    handleChange,
    submitButtonLabel,
    handleSubmit,
  } = props;

  const [isSubmitted, setIsSubmitted] = useState(false);

  const formErrors = useMemo(() => {
    const errors = {};

    if (contentValue.length < 1) {
      errors.content = 'empty';
    }

    if (titleValue.length < 1) {
      errors.title = 'empty';
    } else if (titleValue.length > maxCommentTitleCharacters) {
      errors.title = 'maxLength';
    }

    return errors;
  }, [titleValue, contentValue]);

  const formNotValid = displayTitleInput
    ? !isEmpty(formErrors)
    : !!formErrors?.content;

  return (
    <div className="acr-comment-form">
      {displayTitleInput && (
        <TextInput
          className="acr-comment-title rounded-border"
          placeholder="Comment Title"
          onChange={handleChangeTitle}
          value={titleValue}
          labelText=""
          id="title"
          invalid={formErrors.title && isSubmitted}
          invalidText={commentsErrorsMessages.title[formErrors.title]}
        />
      )}

      <TextArea
        className="rounded-border acr-comment-body"
        placeholder="Comment Message"
        onChange={handleChange}
        value={contentValue}
        labelText=""
        id="comment-msg-body"
        invalid={contentValue.length < 1 && isSubmitted}
        invalidText={commentsErrorsMessages.content[formErrors.content]}
      />
      <div className="acr-comment-post-btn">
        <Button
          onClick={() => {
            formNotValid ? setIsSubmitted(true) : handleSubmit();
          }}
        >
          {submitButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default CommentsTextInput;
