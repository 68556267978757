import { Button, InlineLoading } from '@wfp/ui';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getActionAPISource } from 'src/redux/actions';
import {
  EXPORT_ACR_DASHBOARD_KEY,
  REPORT_LIST_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

export function HeaderExportButton(props) {
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(true);

  function downloadFile() {
    let url = getActionAPISource(EXPORT_ACR_DASHBOARD_KEY);
    const params = {
      _period_: props.period ? props.period : 'current',
      _dashboard_type_: props.dashboard_type ? props.dashboard_type : 'project',
    };
    for (const [key, value] of Object.entries(params)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }

    setLoading(true);
    setFileUploaded(false);

    apiCallAsync(url, params, 'get', 'blob').then(response => {
      const fileName = `acr-dashboard-${props.dashboard_type}-${props.period}.xls`;
      const options = { type: response.headers['content-type'] };
      const file = new Blob([response.data], options);
      saveAs(file, fileName);
      setFileUploaded(true);
      showNotification('File downloaded!', 'success');
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    });
  }

  return (
    <div className="acr-dashboard-button">
      {loading ? (
        <InlineLoading
          description={fileUploaded ? 'Done' : 'Loading file...'}
          success={fileUploaded}
        />
      ) : null}

      <Button
        className="btn btn-default"
        onClick={downloadFile}
        disabled={loading}
        data-test-id="export-in-excel-button"
      >
        Export in Excel
      </Button>
    </div>
  );
}

export const mapStateToProps = state => {
  try {
    return {
      ...state.viewData[REPORT_LIST_DATA_KEY].request_params.data,
    };
  } catch (e) {
    return {
      dashboard_type: 'project',
      period: '',
    };
  }
};

export default connect(mapStateToProps)(HeaderExportButton);
