import React, { FC } from 'react';
import {
  Breadcrumb as WfpBreadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
} from '@wfp/ui';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { REPORT_DETAIL_DATA_KEY } from 'src/redux/report/constants';
import { RootStateOrAny, useSelector } from 'react-redux';

const Breadcrumb: FC = () => {
  const routesConfig = useSelector(
    (state: RootStateOrAny) => state.routesConfig,
  );
  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );

  const location = useLocation();

  const context = {
    country: reportDetailData?.country,
  };

  function prepareRoutes(routes, three) {
    for (const key in routes) {
      const route = routes[key];

      if (route.hasOwnProperty('sub_routes')) {
        prepareRoutes(route.sub_routes, three);
      }

      if (route.hasOwnProperty('path')) {
        const match = matchPath(location.pathname, {
          path: route.path,
        });
        if (match) {
          if (
            key === 'reports_dashboard' &&
            typeof match.params.year === 'undefined'
          )
            continue;

          three.push({
            ...match,
            name: route.matchToName
              ? route.matchToName(match, context)
              : route.name,
          });
        }
      }
    }
  }

  const three = [];

  prepareRoutes(routesConfig || {}, three);

  return (
    <WfpBreadcrumb className="main-side-padding">
      {three.reverse().map((match, index) => {
        return (
          <BreadcrumbItem key={index}>
            <NavLink
              exact={true}
              to={match.url}
              data-test-id={index === 0 ? 'home-link' : 'year-link'}
            >
              {index === 0 ? (
                <BreadcrumbHome
                  hometext={match.name}
                  data-test-id="home-icon"
                />
              ) : (
                match.name
              )}
            </NavLink>
          </BreadcrumbItem>
        );
      })}
    </WfpBreadcrumb>
  );
};

export default Breadcrumb;
