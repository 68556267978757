import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import {
  getActionAPISource,
  receiveAPIInfographicsResponse,
  receiveAPIResponse,
  receiveDataNotesResponse,
  receiveExtraNarrativeResponse,
  receiveMultiNarrativeResponse,
  receiveNarrativeResponse,
  receivePostAcrInfoBoxSuccess,
  requestAPI,
  requestAPIFailed,
  requestAPIHoldingData,
} from 'src/redux/actions';
import {
  stopPullingPdf,
  UnlockInfographicsActionSuccess,
} from 'src/redux/report/actions';
import {
  ACR_INFO_BOX_ACTION,
  ACR_REPORT_STATE_TRANSITION_ACTION,
  ADD_EXTRA_SECTION_ACTION,
  APPLY_CHANGE_TITLE_ACTION,
  APPLY_CHANGE_TITLE_KEY,
  CANCEL_NARRATIVE_EDITING_ACTION,
  CANCEL_NARRATIVE_EDITING_KEY,
  CHANGE_DATE_PICKER_ACTION,
  CHANGE_DATE_PICKER_KEY,
  EXTRA_NARRATIVE_GET_DATA_KEY,
  EXTRA_SECTION_DATA_KEY,
  GET_EXTRA_NARRATIVE_ACTION,
  GET_MULTI_NARRATIVE_ACTION,
  GET_NARRATIVE_ACTION,
  HISTORY_NARRATIVE_ACTION,
  HISTORY_NARRATIVE_KEY,
  ICONS_DETAILS_ACTION,
  ICONS_DETAILS_KEY,
  INFOGRAPHICS_CREATE,
  INFOGRAPHICS_LOCK,
  INFOGRAPHICS_UNLOCK,
  LOCK_INFOGRAPHICS_ACTION,
  LOCK_NARRATIVE_ACTION,
  LOCK_NARRATIVE_KEY,
  MULTI_NARRATIVES_LIST,
  NARRATIVE_GET_DATA_KEY,
  POST_ACR_REPORTS_STATE_TRANSITION_KEY,
  PULL_REQUESTED_PDF,
  REFRESH_SECTIONS_DATA_SOURCE_ACTION,
  REPORT_DETAIL_DATA_KEY,
  REPORT_LIST_DATA_KEY,
  REVERT_HISTORY_NARRATIVE_ACTION,
  REVERT_HISTORY_NARRATIVE_KEY,
  SECTION_DETAIL_DATA_KEY,
  SECTION_REFRESH_DATA_SOURCE_KEY,
  STOP_PULLING_REQUESTED_PDF,
  UNLOCK_INFOGRAPHICS_ACTION,
  UPDATE_DATA_NOTES_ACTION,
  UPDATE_DATA_NOTES_KEY,
  UPDATE_INFOGRAPHICS_ACTION,
  UPDATE_REVIEW_STATE_DATA_ACTION,
  UPDATE_REVIEW_STATE_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';

export function* postSagaBase(action) {
  try {
    const urlObjId = action.value.url_format._id_;
    let url = getActionAPISource(action.dataKey);
    url = url.replace(new RegExp('_id_', 'g'), urlObjId);

    const response = yield call(apiCallAsync, url, action.value.data, 'post');

    if (response.status >= 200 && response.status < 300) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
    } else {
      console.warn(`API call ${action.dataKey} has failed.`);
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* postAcrInfoBoxSaga(action) {
  try {
    const urlObjId = action.value.url_format._id_;
    let url = getActionAPISource(action.dataKey);
    url = url.replace(new RegExp('_id_', 'g'), urlObjId);

    const response = yield call(apiCallAsync, url, action.value.data, 'post');

    if (response.status >= 200 && response.status < 300) {
      yield put(receivePostAcrInfoBoxSuccess(action.value.data));
    } else {
      console.warn(`API call ${action.dataKey} has failed.`);
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* requestExtraSectionSaga(action) {
  yield put(
    requestAPI(EXTRA_SECTION_DATA_KEY, action.value, {
      url_format: { _id_: action.value },
      method: 'post',
    }),
  );
  yield put(requestAPI(REPORT_DETAIL_DATA_KEY, action.value, {}));
}

export function* watchRequestExtraSectionSaga() {
  yield takeLatest(ADD_EXTRA_SECTION_ACTION, requestExtraSectionSaga);
}

export function* revertNarrativeHistorySaga(action) {
  try {
    let url = getActionAPISource(REVERT_HISTORY_NARRATIVE_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.sectionPk);
    const response = yield call(
      apiCallAsync,
      url,
      { versionPk: action.value.versionPk },
      'post',
    );

    if (response.status === 200) {
      yield put(
        receiveAPIResponse(REVERT_HISTORY_NARRATIVE_KEY, response.data),
      );

      if (action.value.hasOwnProperty('success_actions')) {
        const successLen = action.value.success_actions.length;
        for (let i = 0, len = successLen; i < len; i++) {
          yield put(action.value.success_actions[i]);
        }
      }
    } else {
      throw 'API call has failed 1';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchRevertNarrativeHistorySaga() {
  yield takeLatest(REVERT_HISTORY_NARRATIVE_ACTION, revertNarrativeHistorySaga);
}

export function* getNarrativeHistorySaga(action) {
  try {
    let url = getActionAPISource(HISTORY_NARRATIVE_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value);
    const response = yield call(apiCallAsync, url, action.value, 'get');
    if (response.status === 200) {
      yield put(receiveAPIResponse(HISTORY_NARRATIVE_KEY, response.data));
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchGetNarrativeHistorySaga() {
  yield takeLatest(HISTORY_NARRATIVE_ACTION, getNarrativeHistorySaga);
}

export function* getIconsDetailsSaga(action) {
  try {
    let url = getActionAPISource(ICONS_DETAILS_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value);
    const response = yield call(apiCallAsync, url, action.value, 'get');
    if (response.status === 200) {
      yield put(receiveAPIResponse(ICONS_DETAILS_KEY, response.data));
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchIconsDetailsSaga() {
  yield takeLatest(ICONS_DETAILS_ACTION, getIconsDetailsSaga);
}

export function* getNarrativeSaga(action) {
  try {
    let url = getActionAPISource(NARRATIVE_GET_DATA_KEY);
    for (const [key, value] of Object.entries(action.value.url_format)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }
    const response = yield call(apiCallAsync, url, null, 'get');
    if (response.status === 200) {
      yield put(receiveNarrativeResponse(response.data));
    } else {
      throw 'API call getNarrative has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* getMultiNarrativeSaga(action) {
  try {
    let url = getActionAPISource(MULTI_NARRATIVES_LIST);
    for (const [key, value] of Object.entries(action.value.url_format)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }
    const response = yield call(apiCallAsync, url, null, 'get');
    if (response.status === 200) {
      yield put(receiveMultiNarrativeResponse(response.data));
    } else {
      throw 'API call getMultiNarrative has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchMultiNarrativeSaga() {
  yield takeLatest(GET_MULTI_NARRATIVE_ACTION, getMultiNarrativeSaga);
}

export function* watchNarrativeSaga() {
  yield takeLatest(GET_NARRATIVE_ACTION, getNarrativeSaga);
}

export function* getExtraNarrativeSaga(action) {
  try {
    let url = getActionAPISource(EXTRA_NARRATIVE_GET_DATA_KEY);
    for (const [key, value] of Object.entries(action.value.url_format)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }
    const response = yield call(apiCallAsync, url, null, 'get');
    if (response.status === 200) {
      yield put(receiveExtraNarrativeResponse(response.data));
    } else {
      throw 'API call getExtraNarrative has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchExtraNarrativeSaga() {
  yield takeLatest(GET_EXTRA_NARRATIVE_ACTION, getExtraNarrativeSaga);
}

export function* cancelNarrativeEditingSaga(action) {
  try {
    let url = getActionAPISource(CANCEL_NARRATIVE_EDITING_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.section);
    const response = yield call(
      apiCallAsync,
      url,
      action.value.section,
      'post',
    );

    if (response.status === 200) {
      yield put(
        receiveAPIResponse(CANCEL_NARRATIVE_EDITING_KEY, response.data),
      );
      if (action.value.hasOwnProperty('success_actions')) {
        const successLen = action.value.success_actions.length;
        for (let i = 0, len = successLen; i < len; i++) {
          yield put(action.value.success_actions[i]);
        }
      }
    } else {
      throw 'API call has failed 3';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchCancelNarrativeEditingSaga() {
  yield takeLatest(CANCEL_NARRATIVE_EDITING_ACTION, cancelNarrativeEditingSaga);
}

export function* lockNarrativeSaga(action) {
  try {
    let url = getActionAPISource(LOCK_NARRATIVE_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.section);
    const response = yield call(
      apiCallAsync,
      url,
      action.value.section,
      'post',
    );

    if (response.status === 200) {
      yield put(receiveAPIResponse(LOCK_NARRATIVE_KEY, response.data));
      if (action.value.hasOwnProperty('success_actions')) {
        const successLen = action.value.success_actions.length;
        for (let i = 0, len = successLen; i < len; i++) {
          yield put(action.value.success_actions[i]);
        }
      }
    } else {
      throw 'API call has failed 4';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
    if (action.value.hasOwnProperty('failure_actions')) {
      const failureLen = action.value.failure_actions.length;
      for (let i = 0, len = failureLen; i < len; i++) {
        yield put(action.value.failure_actions[i]);
      }
    }
  }
}

export function* watchLockNarrativeSaga() {
  yield takeLatest(LOCK_NARRATIVE_ACTION, lockNarrativeSaga);
}

export function* changeDatePickerSaga(action) {
  try {
    let url = getActionAPISource(CHANGE_DATE_PICKER_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.reportPk);
    const response = yield call(apiCallAsync, url, action.value, 'put');

    if (response.status === 200) {
      yield put(
        requestAPIHoldingData(REPORT_LIST_DATA_KEY, null, {
          data: { period: action.value.period, dashboard_type: 'project' },
        }),
      );
    } else {
      throw 'API call has failed 5';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchChangeDatePickerSaga() {
  yield takeLatest(CHANGE_DATE_PICKER_ACTION, changeDatePickerSaga);
}

export function* updateReviewStateSaga(action) {
  try {
    let url = getActionAPISource(UPDATE_REVIEW_STATE_DATA_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.reportPk);
    const response = yield call(apiCallAsync, url, action.value, 'put');
    if (response.status === 200) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchUpdateReviewStateSaga() {
  yield takeLatest(UPDATE_REVIEW_STATE_DATA_ACTION, updateReviewStateSaga);
}

export function* updateInfographicsSaga(action) {
  try {
    const url = getActionAPISource(INFOGRAPHICS_CREATE);
    const res = yield call(
      apiCallAsync,
      url,
      action.value?.value?.data,
      'post',
    );
    if (res.status === 200) {
      yield put(receiveAPIInfographicsResponse(action.dataKey, res.data));
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* lockInfographicsSaga(action) {
  try {
    const url = getActionAPISource(INFOGRAPHICS_LOCK);
    yield call(apiCallAsync, url, action.value?.value?.data, 'post');
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* unlockInfographicsSaga(action) {
  try {
    const url = getActionAPISource(INFOGRAPHICS_UNLOCK);
    const res = yield call(
      apiCallAsync,
      url,
      action.value?.value?.data,
      'post',
    );
    yield put(UnlockInfographicsActionSuccess(res.data.infographics_editor));
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchUpdateInfographicsSaga() {
  yield takeLatest(UPDATE_INFOGRAPHICS_ACTION, updateInfographicsSaga);
}

export function* watchLockInfographicsSaga() {
  yield takeLatest(LOCK_INFOGRAPHICS_ACTION, lockInfographicsSaga);
}

export function* watchUnlockInfographicsSaga() {
  yield takeLatest(UNLOCK_INFOGRAPHICS_ACTION, unlockInfographicsSaga);
}

export function* updateDataNotesSaga(action) {
  const newDataNotesValue = action?.value?.value;

  try {
    let url = getActionAPISource(UPDATE_DATA_NOTES_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.sectionPk);
    const response = yield call(apiCallAsync, url, action.value, 'put');
    if (response.status === 200) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
      yield put(
        receiveDataNotesResponse('ACR_SECTIONS_DETAIL', {
          data_notes: newDataNotesValue,
        }),
      );
    } else {
      throw 'API call has failed 7';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchUpdateDataNotesSaga() {
  yield takeLatest(UPDATE_DATA_NOTES_ACTION, updateDataNotesSaga);
}

export function* watchRequestDataInfoBoxSaga() {
  yield takeLatest(ACR_INFO_BOX_ACTION, postAcrInfoBoxSaga);
}

export function* postSectionTitleSaga(action) {
  try {
    let url = getActionAPISource(APPLY_CHANGE_TITLE_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.sectionPk);
    const response = yield call(apiCallAsync, url, action.value.title, 'post');
    if (response.status === 200) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
      yield put(
        requestAPI(SECTION_DETAIL_DATA_KEY, action.value, {
          url_format: { _id_: action.value.sectionPk },
        }),
      );
    } else {
      throw 'API call has failed 9';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.toString()));
    console.error(e);
  }
}

export function* watchPostSectionTitleSaga() {
  yield takeLatest(APPLY_CHANGE_TITLE_ACTION, postSectionTitleSaga);
}

export function* requestDataSourcesRefreshForSectionSaga(action) {
  try {
    let url = getActionAPISource(SECTION_REFRESH_DATA_SOURCE_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value);
    const response = yield call(apiCallAsync, url, {}, 'post');

    if (response.status >= 200 && response.status < 300) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
      yield put(
        requestAPI(SECTION_DETAIL_DATA_KEY, action.value, {
          url_format: { _id_: action.value },
        }),
      );
    } else {
      throw 'API call has failed 10';
    }
  } catch (e) {
    yield put(requestAPIFailed(action.dataKey, e.message));
    console.error(e);
  }
}

export function* watchRequestDataSourcesRefreshSaga() {
  yield takeLatest(
    REFRESH_SECTIONS_DATA_SOURCE_ACTION,
    requestDataSourcesRefreshForSectionSaga,
  );
}

// Report workflow Transitions
export function* postAcrReportStateTransitionSaga(action) {
  try {
    let url = getActionAPISource(POST_ACR_REPORTS_STATE_TRANSITION_KEY);
    url = url.replace(new RegExp('_id_', 'g'), action.value.url_format._id_);
    const response = yield call(apiCallAsync, url, action.value.data, 'post');

    if (response.status >= 200 && response.status < 300) {
      yield put(receiveAPIResponse(action.dataKey, response.data));
      yield put(
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          ...action.value,
        }),
      );
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailed(
        action.dataKey,
        e.response.data,
        Math.round(+new Date() / 1000),
      ),
    );
  }
}

export function* watchAcrReportStateTransitionSaga() {
  yield takeLatest(
    ACR_REPORT_STATE_TRANSITION_ACTION,
    postAcrReportStateTransitionSaga,
  );
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export function* repeatedActionSaga(action) {
  let getPDFsList = true;
  while (getPDFsList) {
    yield delay(3500);

    const urlObjId = action.subAction.params.url_format._id_;
    let url = getActionAPISource(action.subAction.dataKey);
    url = url.replace(new RegExp('_id_', 'g'), urlObjId);

    const response = yield call(apiCallAsync, url, {}, 'get');
    if (response.status >= 200 && response.status < 300) {
      if (response.data.report.is_generated) {
        getPDFsList = false;

        yield put(
          receiveAPIResponse(
            action.subAction.dataKey,
            response.data,
            action.subAction.params,
          ),
        );
      }
    } else {
      getPDFsList = false;
      console.warn(`API call GET PDF Reports list failed.`);
    }
  }
  yield put(stopPullingPdf());
}

export function* watchRepeatedActionSaga() {
  let pendingAction;
  while (true) {
    const action = pendingAction
      ? pendingAction
      : yield take(PULL_REQUESTED_PDF);
    pendingAction = null;

    const { newAction } = yield race({
      success: call(repeatedActionSaga, action),
      stop: take(STOP_PULLING_REQUESTED_PDF),
      newAction: take(PULL_REQUESTED_PDF),
    });

    if (typeof newAction !== 'undefined') {
      pendingAction = newAction;
    }
  }
}
