const keyToTitleMap = {
  strategic_result: 'strategic result',
  strategic_outcome: 'strategic outcome',
  commodity_group: 'commodity group',
  modality: 'modality',
  project_regional_bureau_display_code: 'bureau',
  current_status: 'status',
};

export const getTitleForKey = key => {
  let title = keyToTitleMap[key];
  if (!title) {
    title = key.toString().replace(new RegExp('_', 'g'), ' ');
  }
  return '-' + title + '-';
};
