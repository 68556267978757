import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  DisplayWrapper,
  mapStateToWrapperProps as DisplayWrapperMapStateToProps,
} from 'src/components/DisplayWrapper';
import { CRF23 } from 'src/constants';
import { clearViewData, clearViewState } from 'src/redux/report/actions';
import {
  COMMENTS_TARGET_ID,
  REPORT_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { buildErrorComponents, showNotification } from 'src/utils';
import CommentPanel from './comments/CommentPanel';
import ReportHeader from './ReportHeader';
import ReportSidebar from './ReportSidebar';
import Workspace from './Workspace';
import WorkspaceACR2023 from './WorkspaceACR2023';

export class DetailContainer extends Component {
  componentWillUnmount() {
    this.props.clearApiData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.viewData?.error !== prevProps.viewData?.error &&
      this.props.viewData?.error !== null
    ) {
      const errorsList = buildErrorComponents(this.props.viewData?.error);
      showNotification(errorsList, 'error', {
        autoClose: false,
        closeOnClick: false,
      });
    }
  }

  render() {
    let countryDirector = '';
    let regionalDirector = '';

    if (this.props.viewData && this.props.viewData.data) {
      countryDirector = this.props.viewData.data.country_director_name
        ? this.props.viewData.data.country_director_name
        : '';
      regionalDirector = this.props.viewData.data.regional_director_name
        ? this.props.viewData.data.regional_director_name
        : '';
    }

    if (this.props.viewData?.error?.data?.detail === 'Not found.') {
      return <Redirect to="/not-found" />;
    }

    return (
      <div
        className={`report-details ${
          this.props.viewData?.error ? 'request-error' : ''
        } ${this.props.commentsDisplayStatus ? 'comments-view' : ''}`}
      >
        {this.props.viewData.data && (
          <ReportHeader
            country={this.props.viewData.data.country}
            period={this.props.viewData.data.period}
            project_type={this.props.viewData.data.project_type}
            project_code={this.props.viewData.data.project_code}
            project_title={this.props.viewData.data.title}
            country_iso_code={this.props.viewData.data.country_iso_code}
            has_director={this.props.viewData.data.has_director}
            has_country_overview={this.props.viewData.data.has_country_overview}
            report_pk={this.props.viewData.data.pk}
            contact_name={this.props.viewData.data.contact_name}
            contact_email={this.props.viewData.data.contact_email}
            regional_director_name={regionalDirector}
            country_director_name={countryDirector}
            photo_credit={this.props.viewData.data.photo_credit}
            caption={this.props.viewData.data.caption}
          />
        )}
        {this.props.viewData.data?.crf !== CRF23 ? (
          <Workspace />
        ) : (
          <WorkspaceACR2023 />
        )}

        <ReportSidebar
          year={this.props.match.params.year}
          cpb={this.props.match.params.cpb}
        />
        <div className="acr-comments-panel-container">
          <DisplayWrapper targetId={COMMENTS_TARGET_ID} visible={false}>
            <CommentPanel id="comments_panel" />
          </DisplayWrapper>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  const newState = {
    data: {},
    isFetching: false,
    error: null,
    sectionsContentMode: 'view',
    commentsDisplayStatus: DisplayWrapperMapStateToProps(state, {
      targetId: COMMENTS_TARGET_ID,
    }).display,
    viewData: { ...state.viewData[REPORT_DETAIL_DATA_KEY] },
  };
  return newState;
};

export const mapDispatchToProps = dispatch => {
  return {
    clearApiData: () => {
      dispatch(clearViewState());
      dispatch(clearViewData());
    },
  };
};

const Detail = connect(mapStateToProps, mapDispatchToProps)(DetailContainer);
export default Detail;
