import { Form, Icon, Modal } from '@wfp/ui';
import { iconSearch } from '@wfp/icons';

import React, { FC, useCallback, useState } from 'react';
import { apiCallAsync } from 'src/redux/sagas';
import { Autocomplete } from '@material-ui/lab';
import { getActionAPISource } from 'src/redux/actions';

import { IEditUserFormFields, IOptionValue, IUserData } from 'src/types';
import { useForm } from 'react-hook-form';
import { showNotification } from 'src/utils';
import { InputAdornment, TextField } from '@material-ui/core';
import UserInfoBox from '../UserInfoBox';
import EditUserRepeatableFields from '../EditUserRepeatableFields';
import { debounce } from 'lodash';

interface Props {
  roles: IOptionValue[];
  offices: IOptionValue[];
  onClose: () => void;
  open: boolean;
}

const AddUserModal: FC<Props> = ({
  roles: rolesList,
  offices: officesList,
  onClose,
  open,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [lastSearch, setLastSearch] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [userData, setUserData] = useState<Partial<IUserData>>({
    roles: [],
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IEditUserFormFields>({
    defaultValues: {
      roles: [
        {
          role: 0,
          office: [],
        },
      ],
    },
  });

  function handleUpdateBtn() {
    handleSubmit(saveUser)();
  }

  function saveUser(data: IEditUserFormFields) {
    setIsSaving(true);

    const url = getActionAPISource('USERS_MANAGEMENT_LIST');
    apiCallAsync(url, { user_data: { ...userData, roles: data.roles } }, 'post')
      .then(response => {
        if (response.status === 200) {
          setIsSaving(false);
          if ('error' in response.data) {
            showNotification(response.data.error, 'error');
          } else {
            showNotification(response.data.message, 'success');
            onClose();
          }
        }
      })
      .catch(e => {
        console.log(e);
        setIsSaving(false);
      });
  }

  function handleAutocomplete(e: any) {
    fillUserData(e.target.outerText);
  }

  function ifEmail(toCheck: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(toCheck).toLowerCase());
    return isValid;
  }

  function fillUserData(email: string) {
    if (ifEmail(email)) {
      let suggestUser: any = {};
      for (const each of lastSearch) {
        if (each.email === email) {
          suggestUser = each;
        }
      }
      if (!!suggestUser && Object.keys(suggestUser).length > 0) {
        setUserData({
          first_name: suggestUser.first_name,
          last_name: suggestUser.last_name,
          email: suggestUser.email,
          username: suggestUser.login_name,
          roles: [],
        });
      }
    } else {
      setUserData({
        email: null,
        roles: [],
      });
      setSuggestions([]);
      setLastSearch([]);
    }
  }

  function getSuggestions(email: string) {
    if (!!email && email.length > 1) {
      getOptionsDelayed(email);
    }
  }

  const getOptionsDelayed = useCallback(
    debounce(text => {
      suggestUsers(text);
    }, 300),
    [],
  );

  async function suggestUsers(partName: string) {
    const url = `${getActionAPISource(
      'USERS_MANAGEMENT_SUGGEST',
    )}?user_data=${partName}`;

    await apiCallAsync(url, {}, 'get').then(response => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          const tmp = response.data.map(row => {
            return row.email;
          });

          setSuggestions(tmp);
          setLastSearch(response.data);
        }
      }
    });
  }

  function processEmail(e: any) {
    const email = e.target.value.toLowerCase();

    if (ifEmail(email)) {
      const fpart = email.substring(email.indexOf('.') + 1);
      const _email = fpart.substr(0, fpart.indexOf('@'));
      getSuggestions(_email);
    } else {
      getSuggestions(email);
    }
    fillUserData(email);
  }

  return (
    <>
      <Modal
        open={open}
        onRequestSubmit={handleUpdateBtn}
        // @ts-ignore: Wrong type check in wfp UI Kit
        onSecondarySubmit={onClose}
        hideClose={true}
        modalHeading="Add new user"
        primaryButtonText="Add User"
        secondaryButtonText="Cancel"
        primaryButtonDisabled={isSaving}
        secondaryButtonDisabled={isSaving}
        iconDescription="Close the modal"
        className="add-user-modal"
        selectorPrimaryFocus={false}
        wide
      >
        <>
          <Autocomplete
            filterOptions={x => x}
            className="users-manage-autocomplete"
            id="add-user-autocomplete"
            freeSolo
            disabled={!!userData?.email}
            options={suggestions.map(option => option)}
            onChange={handleAutocomplete}
            renderInput={params => (
              <TextField
                {...params}
                onChange={processEmail}
                margin="normal"
                variant="outlined"
                placeholder="Enter new user last name"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon={iconSearch} description="search" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {userData.email && (
            <>
              <UserInfoBox userData={userData as IUserData} />
              <Form id="edit-user-form">
                <EditUserRepeatableFields
                  name="roles"
                  control={control}
                  errors={errors}
                  appendValue={{ role: 0, office: [] }}
                  rolesList={rolesList}
                  officesList={officesList.filter(({ value }) => value !== 0)}
                />
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default AddUserModal;
