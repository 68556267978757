import { scaleLinear, scaleThreshold } from 'd3-scale';

/* Basic Colors from WFP UI  */
const colors = {
  /* Map Colors */
  mapFillDarker: '#031430',
  mapFillDark: '#002054',
  mapFillMiddle: '#245CFF',
  mapFillLight: '#559CFF',
  mapFillLighter: '#8AC2FF',
  mapFillContrastA: '#0A48A0',
  mapFillContrastB: '#DBC108',
  mapFillContrastC: '#319B3B',
  accent: '#1f6ebc',
  blueDarker: '#037cf5',
  blueLighter: '#b2d8fe',

  /* Brand Colors http://brand.manuals.wfp.org/en/core-elements/colours/colour-palette/ */
  primary: '#0a6eb4',
  navy: '#19486a',
  aqua: '#26bde2',
  darkGreen: '#3f7e44',
  lime: '#97d700',
  yellow: '#fcc30b',
  ochre: '#dda63a',
  orange: '#fd6925',
  red: '#c5192d',
  darkRed: '#a21942',
  magenta: '#dd1367',
  green: '#00a878',
  greenLighter: '#95DA38',
  purple: '#FC00A4',

  /* Greys */
  greyLighter: '#e8e8e8',
  greyLight: '#bababa',
  grey: '#8c8c8c',
  greyDark: '#5e5e5e',
  greyDarker: '#303030',

  female: '#3f7e44',
  male: '#26bde2',
  planned: '#E1E6F1',
  actual: '#638CA6',
};

/* Lookup for the Regional Bureau Names */
const lookups = {
  RBB: { fullName: 'Bangkok Regional Bureau', shortName: 'Bangkok RB' },
  RBP: { fullName: 'Panama Regional Bureau', shortName: 'Panama RB' },
  RBN: { fullName: 'Nairobi Regional Bureau', shortName: 'Nairobi RB' },
  RBJ: {
    fullName: 'Johannesburg Regional Bureau',
    shortName: 'Johannesburg RB',
  },
  RBD: { fullName: 'Dakar Regional Bureau', shortName: 'Dakar RB' },
  RBC: { fullName: 'Cairo Regional Bureau', shortName: 'Cairo RB' },
};

/* PACE Color Scale */
const paceColorScale = scaleThreshold()
  .domain([60, 75, 89, 95, 100])
  .range([colors.red, colors.orange, colors.yellow, colors.aqua, colors.lime]);

/* Color Scale (qualitative) */
const colorScale = scaleLinear()
  .domain([0, 1])
  .range([colors.primary, colors.accent]);

const tooltipStyle = {
  duration: 200,
  animation: 'fade',
  theme: 'light',
  arrow: true,
};

const tooltipStyleDark = {
  duration: 200,
  animation: 'fade',
  theme: 'dark',
  arrow: true,
};

const splitLabelText = (labelText: string, len: number) => {
  var curr = len;
  var prev = 0;

  var splitLabelText = [];

  if (labelText === undefined) return '';
  while (labelText[curr]) {
    if (labelText[curr++] === ' ') {
      splitLabelText.push(labelText.substring(prev, curr));
      prev = curr;
      curr += len;
    }
  }
  splitLabelText.push(labelText.substr(prev));
  return splitLabelText;
};

const truncLabelText = (labelText: string, len: number) => {
  return labelText.length > len
    ? labelText.substr(0, len - 1) + '...'
    : labelText;
};

/* Convert String to CamelCase */
const camelCase = (string: string) => {
  return string.toLowerCase().replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};
export {
  camelCase,
  colors,
  colorScale,
  lookups,
  paceColorScale,
  splitLabelText,
  truncLabelText,
  tooltipStyle,
  tooltipStyleDark,
};
