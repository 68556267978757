import {
  ACR_INFO_BOX_ACTION,
  ACR_REPORT_STATE_TRANSITION_ACTION,
  ADD_EXTRA_SECTION_ACTION,
  APPLY_CHANGE_TITLE_ACTION,
  APPLY_CHANGE_TITLE_KEY,
  CANCEL_NARRATIVE_EDITING_ACTION,
  CANCEL_NARRATIVE_EDITING_KEY,
  CHANGE_DATE_PICKER_ACTION,
  CHANGE_DATE_PICKER_KEY,
  CHANGE_INFOGRAPHICS_EDITOR,
  CHANGE_SECTIONS_CONTENT_MODE_ACTION,
  CHANGE_VIEW_STATE,
  CLEAN_REPORT_DATA_ACTION,
  CLEAR_VIEW_DATA,
  CLEAR_VIEW_STATE,
  EXTRA_NARRATIVE_GET_DATA_KEY,
  GET_EXTRA_NARRATIVE_ACTION,
  GET_MULTI_NARRATIVE_ACTION,
  GET_NARRATIVE_ACTION,
  HISTORY_NARRATIVE_ACTION,
  HISTORY_NARRATIVE_KEY,
  ICONS_DETAILS_ACTION,
  ICONS_DETAILS_KEY,
  INFOGRAPHICS_CREATE,
  INFOGRAPHICS_LOCK,
  INFOGRAPHICS_UNLOCK,
  LOCK_INFOGRAPHICS_ACTION,
  LOCK_NARRATIVE_ACTION,
  LOCK_NARRATIVE_KEY,
  MULTI_NARRATIVES_LIST,
  NARRATIVE_GET_DATA_KEY,
  POST_ACR_INFO_BOX_CONTENT_KEY,
  POST_ACR_REPORTS_STATE_TRANSITION_KEY,
  PULL_REQUESTED_PDF,
  REFRESH_SECTIONS_DATA_SOURCE_ACTION,
  RESET_DATA_NOTES_ACTION,
  RESET_DATA_NOTES_KEY,
  REVERT_HISTORY_NARRATIVE_ACTION,
  REVERT_HISTORY_NARRATIVE_KEY,
  SECTION_DETAIL_DATA_KEY,
  SECTION_REFRESH_DATA_SOURCE_KEY,
  SELECT_OPTION_ACTION,
  SELECT_OPTION_STATE_KEY,
  SELECT_SECTION_ACTION,
  SET_PAGINATION_ACTION,
  SET_PAGINATION_KEY,
  SET_SEARCH_BOX_ACTION,
  SET_SEARCH_BOX_KEY,
  STOP_PULLING_REQUESTED_PDF,
  UNLOCK_INFOGRAPHICS_ACTION,
  UPDATE_DATA_NOTES_ACTION,
  UPDATE_DATA_NOTES_KEY,
  UPDATE_INFOGRAPHICS_ACTION,
  UPDATE_NARRATIVE_IN_STORAGE,
  UPDATE_REVIEW_STATE_DATA_ACTION,
  UPDATE_REVIEW_STATE_DATA_KEY,
} from 'src/redux/report/constants';

export const updateReviewData = params => {
  return {
    type: UPDATE_REVIEW_STATE_DATA_ACTION,
    dataKey: UPDATE_REVIEW_STATE_DATA_KEY,
    value: params,
  };
};

export const revertHistoryNarrative = params => {
  return {
    type: REVERT_HISTORY_NARRATIVE_ACTION,
    dataKey: REVERT_HISTORY_NARRATIVE_KEY,
    value: params,
  };
};

export const getHistoryNarrative = active_section => {
  return {
    type: HISTORY_NARRATIVE_ACTION,
    dataKey: HISTORY_NARRATIVE_KEY,
    value: active_section,
  };
};

export const getIconsDetails = params => {
  return {
    type: ICONS_DETAILS_ACTION,
    dataKey: ICONS_DETAILS_KEY,
    value: params,
  };
};

export const getNarrative = params => {
  return {
    type: GET_NARRATIVE_ACTION,
    dataKey: NARRATIVE_GET_DATA_KEY,
    value: params,
  };
};

export const getMultiNarrative = params => {
  return {
    type: GET_MULTI_NARRATIVE_ACTION,
    dataKey: MULTI_NARRATIVES_LIST,
    value: params,
  };
};

export const getExtraNarrative = params => {
  return {
    type: GET_EXTRA_NARRATIVE_ACTION,
    dataKey: EXTRA_NARRATIVE_GET_DATA_KEY,
    value: params,
  };
};

export const cancelNarrativeEditing = params => {
  return {
    type: CANCEL_NARRATIVE_EDITING_ACTION,
    dataKey: CANCEL_NARRATIVE_EDITING_KEY,
    value: params,
  };
};

export const lockNarrative = params => {
  return {
    type: LOCK_NARRATIVE_ACTION,
    dataKey: LOCK_NARRATIVE_KEY,
    value: params,
  };
};

export const selectSection = active_section => {
  return {
    type: SELECT_SECTION_ACTION,
    dataKey: 'activeSectionID',
    value: active_section,
  };
};

export const cleanReportDataDetail = () => {
  return {
    type: CLEAN_REPORT_DATA_ACTION,
  };
};

export const selectSectionsDataSource = sectionId => {
  return {
    type: REFRESH_SECTIONS_DATA_SOURCE_ACTION,
    dataKey: SECTION_REFRESH_DATA_SOURCE_KEY,
    value: sectionId,
  };
};

export const UpdateDataNotes = (data, sectionPk) => {
  return {
    type: UPDATE_DATA_NOTES_ACTION,
    dataKey: UPDATE_DATA_NOTES_KEY,
    value: { value: data, sectionPk: sectionPk },
  };
};

export const ResetDataNotesMessage = () => {
  return {
    type: RESET_DATA_NOTES_ACTION,
    dataKey: RESET_DATA_NOTES_KEY,
  };
};

export const UpdateInfographicsAction = data => {
  return {
    type: UPDATE_INFOGRAPHICS_ACTION,
    dataKey: INFOGRAPHICS_CREATE,
    value: { value: data },
  };
};

export const LockInfographicsAction = data => {
  return {
    type: LOCK_INFOGRAPHICS_ACTION,
    dataKey: INFOGRAPHICS_LOCK,
    value: { value: data },
  };
};

export const UnlockInfographicsAction = data => {
  return {
    type: UNLOCK_INFOGRAPHICS_ACTION,
    dataKey: INFOGRAPHICS_UNLOCK,
    value: { value: data },
  };
};

export const UnlockInfographicsActionSuccess = data => {
  return {
    type: CHANGE_INFOGRAPHICS_EDITOR,
    value: data,
  };
};

export const ApplyChangeTitle = (title, sectionPk) => {
  return {
    type: APPLY_CHANGE_TITLE_ACTION,
    dataKey: APPLY_CHANGE_TITLE_KEY,
    value: { title: title, sectionPk: sectionPk },
  };
};

export const updateNarrativeValue = data => {
  return {
    type: UPDATE_NARRATIVE_IN_STORAGE,
    dataKey: SECTION_DETAIL_DATA_KEY,
    value: data,
  };
};

export const postAcrInfoBox = params => {
  return {
    type: ACR_INFO_BOX_ACTION,
    dataKey: POST_ACR_INFO_BOX_CONTENT_KEY,
    value: params,
  };
};

export const postAcrReportStateTransition = params => {
  return {
    type: ACR_REPORT_STATE_TRANSITION_ACTION,
    dataKey: POST_ACR_REPORTS_STATE_TRANSITION_KEY,
    value: params,
  };
};

export const changeContentMode = mode => {
  return {
    type: CHANGE_SECTIONS_CONTENT_MODE_ACTION,
    dataKey: 'sectionsContentMode',
    value: mode,
  };
};

export const changeViewStateAction = payload => {
  return {
    type: CHANGE_VIEW_STATE,
    value: payload,
  };
};

export const addExtraSection = report_id => {
  return {
    type: ADD_EXTRA_SECTION_ACTION,
    dataKey: 'addExtraSectionToReport',
    value: report_id,
  };
};

export const selectOption = (selectBoxID, value) => {
  return {
    type: SELECT_OPTION_ACTION,
    dataKey: SELECT_OPTION_STATE_KEY,
    selectBoxID: selectBoxID,
    value: { value: value },
  };
};

export const setPagination = (paginatorId, value) => {
  return {
    type: SET_PAGINATION_ACTION,
    dataKey: SET_PAGINATION_KEY,
    paginatorId: paginatorId,
    value: { value: value },
  };
};

export const searchBoxKeyDown = value => {
  return {
    type: SET_SEARCH_BOX_ACTION,
    dataKey: SET_SEARCH_BOX_KEY,
    value: { value: value },
  };
};

export const changeDatePicker = (dateValue, state, reportPk, period) => {
  return {
    type: CHANGE_DATE_PICKER_ACTION,
    dataKey: CHANGE_DATE_PICKER_KEY,
    value: { dateValue, state, reportPk, period },
  };
};

export const clearViewState = () => {
  return {
    type: CLEAR_VIEW_STATE,
  };
};

export const clearViewData = () => {
  return {
    type: CLEAR_VIEW_DATA,
  };
};

export const pullPdf = (subAction = {}) => {
  return {
    type: PULL_REQUESTED_PDF,
    subAction,
  };
};

export const stopPullingPdf = () => {
  return {
    type: STOP_PULLING_REQUESTED_PDF,
  };
};
