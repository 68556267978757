import axios from 'axios';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getTokenRedirect } from 'src/auth/auth';
import {
  REQUEST_API,
  REQUEST_API_HOLDING_DATA,
  REQUEST_API_LATEST,
} from 'src/constants/index';
import {
  getActionAPISource,
  receiveAPIResponse,
  requestAPIFailed,
  resetCustomLoginDataRequest,
} from 'src/redux/actions';
import {
  watchAcrReportStateTransitionSaga,
  watchCancelNarrativeEditingSaga,
  watchChangeDatePickerSaga,
  watchExtraNarrativeSaga,
  watchGetNarrativeHistorySaga,
  watchIconsDetailsSaga,
  watchLockInfographicsSaga,
  watchLockNarrativeSaga,
  watchMultiNarrativeSaga,
  watchNarrativeSaga,
  watchPostSectionTitleSaga,
  watchRepeatedActionSaga,
  watchRequestDataInfoBoxSaga,
  watchRequestDataSourcesRefreshSaga,
  watchRequestExtraSectionSaga,
  watchRevertNarrativeHistorySaga,
  watchUnlockInfographicsSaga,
  watchUpdateDataNotesSaga,
  watchUpdateInfographicsSaga,
  watchUpdateReviewStateSaga,
} from 'src/redux/report/sagas';
import {
  watchCommentApiCallsSaga,
  watchCommentReplyApiCallsSaga,
  watchRestoreCommentApiCallsSaga,
} from 'src/redux/sagas/comments';
import { watchCustomLoginSaga } from 'src/redux/sagas/customLogin';
import { narrativeSaga } from 'src/redux/sagas/narrative';
import { getPublicationStatusSaga } from 'src/redux/sagas/publicationStatus';
import { setSubtitleSaga } from 'src/redux/sagas/subtitle';
import store from 'src/redux/store';
import { replaceParamsInUrl, sendGAEvent } from 'src/utils';

export async function apiCallAsync(
  url,
  params = {},
  method = 'get',
  responseType = null,
  additionalHeaders = {},
) {
  return getTokenRedirect()
    .then(authResponse => {
      if (!authResponse) throw 'Auth error. Token redirect response is empty';
      const authToken = `Bearer ${authResponse.accessToken}`;
      sendGAEvent('api_service_called', { wfp_url: url });
      return fetchData(
        url,
        params,
        method,
        authToken,
        responseType,
        additionalHeaders,
      );
    })
    .catch(error => {
      // If user is logged with custom login and token is not valid perform a logout
      if (
        error?.response?.data?.code === 'token_not_valid' &&
        error?.response?.data?.detail ===
          'Given token not valid for any token type'
      ) {
        store.dispatch(resetCustomLoginDataRequest());
        return;
      } else {
        throw error;
      }
    });
}

export function fetchData(
  url,
  params = {},
  method = 'get',
  authToken,
  responseType,
  additionalHeaders = {},
) {
  if (!authToken) {
    console.warn(
      `Please update your fetchData call with using authToken! ${url}`,
    );
    return null;
  }

  const headers = {
    Authorization: authToken,
    ...additionalHeaders,
  };

  // If user is logged with custom login add the X-Custom-auth token in the requests headers
  const customLoginData = store.getState()?.customLogin;
  if (customLoginData?.accessToken) {
    headers['X-Custom-Auth'] = 'testuser';
  }

  const kwargs = {
    url,
    method,
    headers,
  };
  if (responseType) kwargs['responseType'] = responseType;

  if (method === 'get') {
    kwargs['params'] = params;
  } else if (['post', 'put', 'patch', 'delete'].includes(method)) {
    kwargs.headers['X-CSRFToken'] = window.djconfig.csrfToken;
    kwargs.data = params ? params : {};
  }

  return axios(kwargs);
}

export function* ApiCallSaga(action) {
  let storage_key = action.dataKey;

  if (
    action.hasOwnProperty('params') &&
    action.params.hasOwnProperty('storage_key')
  ) {
    storage_key = action.params.storage_key;
  }
  try {
    let url = getActionAPISource(action.dataKey);
    url = replaceParamsInUrl(url, action.params);

    const response = yield call(
      apiCallAsync,
      url,
      action.params.data,
      action.params.method,
    );

    if (response?.status >= 200 && response?.status < 300) {
      yield put(receiveAPIResponse(storage_key, response.data, action.params));
      if (action.params.hasOwnProperty('success_actions')) {
        for (
          let i = 0, len = action.params.success_actions.length;
          i < len;
          i++
        ) {
          yield put(action.params.success_actions[i]);
        }
      }
    } else {
      throw 'API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailed(storage_key, {
        message: e.message,
        data: e.response ? e.response.data : null,
      }),
    );
    console.error(e);
    if (action.params.hasOwnProperty('failure_actions')) {
      for (
        let i = 0, len = action.params.failure_actions.length;
        i < len;
        i++
      ) {
        yield put(action.params.failure_actions[i]);
      }
    }
  }
}

export function* watchApiCallsSaga() {
  yield takeEvery(REQUEST_API, ApiCallSaga);
  yield takeEvery(REQUEST_API_HOLDING_DATA, ApiCallSaga);
  yield takeLatest(REQUEST_API_LATEST, ApiCallSaga);
}

export default function* rootSaga() {
  yield all([
    watchApiCallsSaga(),
    watchCommentApiCallsSaga(),
    watchCommentReplyApiCallsSaga(),
    watchRestoreCommentApiCallsSaga(),
    getPublicationStatusSaga(),
    setSubtitleSaga(),
    narrativeSaga(),
    watchRequestExtraSectionSaga(),
    watchRequestDataSourcesRefreshSaga(),
    watchRequestDataInfoBoxSaga(),
    watchChangeDatePickerSaga(),
    watchAcrReportStateTransitionSaga(),
    watchUpdateDataNotesSaga(),
    watchPostSectionTitleSaga(),
    watchCancelNarrativeEditingSaga(),
    watchLockNarrativeSaga(),
    watchGetNarrativeHistorySaga(),
    watchRevertNarrativeHistorySaga(),
    watchUpdateReviewStateSaga(),
    watchRepeatedActionSaga(),
    watchUpdateInfographicsSaga(),
    watchLockInfographicsSaga(),
    watchUnlockInfographicsSaga(),
    watchIconsDetailsSaga(),
    watchNarrativeSaga(),
    watchMultiNarrativeSaga(),
    watchExtraNarrativeSaga(),
    watchCustomLoginSaga(),
  ]);
}
