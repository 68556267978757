import React, { Component } from 'react';
import Select from 'react-select';

import { API_HOST } from 'src/constants';
import { apiCallAsync } from 'src/redux/sagas';

export default class AdministrationDashboardTableFilters extends Component {
  state = {
    filterSets: {
      bureauFilters: [],
      countryFilters: [],
      projectTypeFilters: [],
      statusFilters: ['Generated', 'Activated', 'No report'],
    },
    activeFilters: {
      bureauFilters: [],
      countryFilters: [],
      projectTypeFilters: [],
      statusFilters: ['Generated', 'Activated', 'No report'],
    },
  };

  handleFilterSelect = (options, action) => {
    const filterName = action.name;
    const selectedOptions = options
      ? options.map(selectedOption => selectedOption.value)
      : [];
    this.setState(
      {
        activeFilters: {
          ...this.state.activeFilters,
          [filterName]: selectedOptions,
        },
      },
      () =>
        this.props.onFilterSelect(
          filterName,
          this.state.activeFilters[filterName],
        ),
    );
  };

  componentDidMount() {
    const apiURL = `${API_HOST}/acr-api/administration-dashboard-filters/`;
    apiCallAsync(apiURL).then(response => {
      this.setState({ filterSets: response.data });
    });
  }

  render() {
    return (
      <div className="admin-dashboard-table-filters">
        <div className="wfp--react-select-container">
          <div className="admin-dashboard-filter-label-container">
            <div className="wfp--label admin-dashboard-filter-label">
              Regional Bureaus
            </div>
          </div>
          <Select
            classNamePrefix="wfp--react-select"
            onChange={this.handleFilterSelect}
            isMulti
            name="bureauFilters"
            id="bureauFilter"
            options={this.state.filterSets.bureauFilters}
          />
        </div>
        <div className="wfp--react-select-container">
          <div className="admin-dashboard-filter-label-container">
            <div className="wfp--label admin-dashboard-filter-label">
              Countries
            </div>
          </div>

          <Select
            classNamePrefix="wfp--react-select"
            onChange={this.handleFilterSelect}
            isMulti
            name="countryFilters"
            id="countryFilter"
            options={this.state.filterSets.countryFilters}
          />
        </div>
        <div className="wfp--react-select-container">
          <div className="admin-dashboard-filter-label-container">
            <div className="wfp--label admin-dashboard-filter-label">
              Project Types
            </div>
          </div>
          <Select
            classNamePrefix="wfp--react-select"
            onChange={this.handleFilterSelect}
            isMulti
            name="projectTypeFilters"
            id="projectTypeFilter"
            options={this.state.filterSets.projectTypeFilters}
          />
        </div>
        <div className="wfp--react-select-container">
          <div className="admin-dashboard-filter-label-container">
            <div className="wfp--label admin-dashboard-filter-label">
              Statuses
            </div>
          </div>
          <Select
            classNamePrefix="wfp--react-select"
            onChange={this.handleFilterSelect}
            isMulti
            name="statusFilters"
            id="abdc"
            options={this.state.filterSets.statusFilters}
          />
        </div>
      </div>
    );
  }
}
