import { STORAGE_KEY } from 'acr_ui/sources/components/constants';
import { acrDatasourcesReducer } from 'acr_ui/sources/components/reducers';
import cloneDeep from 'lodash/cloneDeep';
import findKey from 'lodash/findKey';
import { combineReducers } from 'redux';
import type { IViewDataInitialState } from 'src/redux/types';

import {
  ADD_SIGNALS,
  CLEAN_SIGNALS,
  CLEAR_API_KEYS,
  COMMENT_REPLY_REQUEST_API,
  COMMENT_REQUEST_API,
  RECEIVE_DATA_NOTES_FROM_API,
  RECEIVE_EXTRA_NARRATIVE_FROM_API,
  RECEIVE_FROM_API,
  RECEIVE_FROM_API_HOLDING_DATA,
  RECEIVE_INFOGRAPHICS_FROM_API,
  RECEIVE_NARRATIVE_FROM_API,
  REQUEST_API,
  REQUEST_API_FAILURE,
  REQUEST_API_FAILURE_HOLDING_DATA,
  REQUEST_API_HOLDING_DATA,
  REQUEST_API_LATEST,
  RESET_REPORTS_LIST,
  SET_EXTRA_NARRATIVE_ACTION,
  SET_NARRATIVE_ACTION,
  SET_SUBTITLE_SUCCESS_ACTION,
  SET_ROUTES_CONFIG,
  SET_CUSTOM_LOGIN_DATA,
  RESET_CUSTOM_LOGIN_DATA_SUCCESS,
  TOGGLE_SIGNALS,
  CANCEL_MULTINARRATIVE_ACTION_SUCCESS,
  SAVE_MULTINARRATIVE_ACTION_SUCCESS,
  LOCK_MULTINARRATIVE_ACTION_SUCCESS,
  RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS,
  RESET_MULTINARRATIVE_ACTION,
  RECEIVE_MULTI_NARRATIVE_FROM_API,
  RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API,
  REQUEST_POST_ACR_INFO_BOX_SUCCESS,
} from 'src/constants';
import {
  ACR_INFO_BOX_ACTION,
  ACR_REPORT_STATE_TRANSITION_ACTION,
  APPLY_CHANGE_TITLE_ACTION,
  CHANGE_INFOGRAPHICS_EDITOR,
  CLEAN_REPORT_DATA_ACTION,
  CLEAR_VIEW_DATA,
  REFRESH_SECTIONS_DATA_SOURCE_ACTION,
  REPORT_DETAIL_DATA_KEY,
  RESET_DATA_NOTES_ACTION,
  SECTION_DETAIL_DATA_KEY,
  UPDATE_DATA_NOTES_ACTION,
  UPDATE_DATA_NOTES_KEY,
  UPDATE_NARRATIVE_IN_STORAGE,
  UPDATE_REVIEW_STATE_DATA_ACTION,
} from 'src/redux/report/constants';
import { viewState } from 'src/redux/report/reducers';

export const componentSignals = function (state = {}, action) {
  let newState;
  switch (action.type) {
    case ADD_SIGNALS:
      newState = cloneDeep(state);

      for (const id in action.signalsValuesPool) {
        const signals = action.signalsValuesPool[id];
        if (!newState[id]) {
          newState[id] = signals;
          continue;
        } else {
          Object.assign(newState[id], signals);
        }
      }
      return newState;
    case TOGGLE_SIGNALS:
      newState = cloneDeep(state);
      for (const id in action.signalsToToggle) {
        for (const key of action.signalsToToggle[id]) {
          newState[id][key] = !newState[id][key];
        }
      }
      return newState;
    case CLEAN_SIGNALS:
      newState = cloneDeep(state);
      for (const id in action.signalsToDrop) {
        for (const key of action.signalsToDrop[id]) {
          delete newState[id][key];
        }
      }
      return newState;
    default:
      return state;
  }
};

export const routesConfig = (state = {}, action) => {
  switch (action.type) {
    case SET_ROUTES_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const customLogin = (state = {}, action) => {
  switch (action.type) {
    case SET_CUSTOM_LOGIN_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_CUSTOM_LOGIN_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const viewData = (state: IViewDataInitialState = {}, action) => {
  let key;
  if (
    action.hasOwnProperty('params') &&
    action.params.hasOwnProperty('storage_key')
  ) {
    key = action.params.storage_key;
  } else {
    key = action.dataKey;
  }

  let newState;
  let narrKey;

  switch (action.type) {
    case RESET_DATA_NOTES_ACTION:
      return {
        ...state,
        [UPDATE_DATA_NOTES_KEY]: {
          ...state[UPDATE_DATA_NOTES_KEY],
          data: {
            message: '',
          },
        },
      };

    case CLEAR_VIEW_DATA:
      return {};
    // Not clearing existing data while updating them
    case REQUEST_API_HOLDING_DATA:
      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
        },
      };
    case REQUEST_API:
    case REQUEST_API_LATEST:
      return {
        ...state,
        [key]: { isFetching: true },
      };
    case UPDATE_NARRATIVE_IN_STORAGE:
      newState = cloneDeep(state);
      newState[action.dataKey].data = {
        ...newState[action.dataKey].data,
        ...{ content: action.value },
      };
      return newState;
    case REFRESH_SECTIONS_DATA_SOURCE_ACTION:
    case ACR_INFO_BOX_ACTION:
    case UPDATE_REVIEW_STATE_DATA_ACTION:
    case APPLY_CHANGE_TITLE_ACTION:
    case UPDATE_DATA_NOTES_ACTION:
    case ACR_REPORT_STATE_TRANSITION_ACTION:
      return {
        ...state,
        [action.dataKey]: { isFetching: true },
      };
    case RECEIVE_FROM_API:
      return {
        ...state,
        [key]: {
          isFetching: false,
          data: action.payload,
          request_params: action.request_params,
        },
      };
    case RECEIVE_FROM_API_HOLDING_DATA:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: action.payload,
          isFetching: false,
          error: undefined,
        },
      };
    case COMMENT_REQUEST_API:
    case COMMENT_REPLY_REQUEST_API:
      return {
        ...state,
        COMMENTS_LIST: {
          ...state.COMMENTS_LIST,
          isFetching: true,
        },
      };
    case RECEIVE_INFOGRAPHICS_FROM_API:
      newState = cloneDeep(state);
      const { alignment, widget_id, result } = action.payload;
      newState.ACR_SECTIONS_DETAIL.data.infographics_data = {
        alignment,
        widget_pk: widget_id,
        widget_items: result,
      };
      return newState;
    case RESET_REPORTS_LIST:
      return {
        ...state,
        ACR_REPORTS_LIST: {
          data: [],
          isFetching: false,
        },
      };
    case CHANGE_INFOGRAPHICS_EDITOR:
      newState = cloneDeep(state);
      const editor_data = action.value;
      newState.ACR_SECTIONS_DETAIL.data.infographics_editor = editor_data;
      return newState;
    case RECEIVE_NARRATIVE_FROM_API:
      newState = cloneDeep(state);

      newState.ACR_SECTIONS_DETAIL.data.content = action.payload;
      return newState;

    case RECEIVE_MULTI_NARRATIVE_FROM_API:
      newState = cloneDeep(state);

      newState.ACR_SECTIONS_DETAIL.data.narratives = action.payload;
      return newState;

    case RECEIVE_MULTI_NARRATIVE_SINGLE_FROM_API:
      narrKey = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.payload.narrative_id,
      );

      if (!narrKey) return state;

      newState = cloneDeep(state);
      newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey] = {
        ...newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey],
        content: action.payload.content,
      };

      return newState;

    case RECEIVE_EXTRA_NARRATIVE_FROM_API:
      newState = cloneDeep(state);

      let extraNarratives = newState.ACR_SECTIONS_DETAIL.data.extra_narratives;

      extraNarratives = extraNarratives.map(item => {
        const narrItem = action.payload.find(narr => narr.id === item.id);

        return {
          ...item,
          ...narrItem,
        };
      });

      return {
        ...state,
        ACR_SECTIONS_DETAIL: {
          ...state.ACR_SECTIONS_DETAIL,
          data: {
            ...state.ACR_SECTIONS_DETAIL.data,
            extra_narratives: extraNarratives,
          },
        },
      };

    case LOCK_MULTINARRATIVE_ACTION_SUCCESS:
      narrKey = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.narrativeId,
      );

      if (!narrKey) return state;

      newState = cloneDeep(state);
      newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey] = {
        ...newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey],
        i_am_editor: true,
        editor: action.editor,
        locked_at: new Date().toISOString(),
        locked: true,
        content: action.content,
      };

      return newState;

    case CANCEL_MULTINARRATIVE_ACTION_SUCCESS:
      narrKey = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.value.id,
      );

      if (!narrKey) return state;

      newState = cloneDeep(state);
      newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey] = {
        ...action.value,
        canceled: true,
      };
      return newState;

    case SAVE_MULTINARRATIVE_ACTION_SUCCESS:
      narrKey = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.value.id,
      );

      if (!narrKey) return state;

      let notification = {};

      if (action.autosave) {
        notification = {
          autosaved: true,
        };
      } else {
        notification = {
          saved: true,
        };
      }

      newState = cloneDeep(state);
      newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey] = {
        ...action.value,
        ...notification,
      };
      return newState;

    case RESET_MULTINARRATIVE_ACTION:
      narrKey = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.narrativeId,
      );

      if (!narrKey) return state;

      newState = cloneDeep(state);
      const narr = newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey];

      narr.saved = false;
      narr.autosaved = false;
      narr.canceled = false;
      narr.locked = false;

      newState.ACR_SECTIONS_DETAIL.data.narratives[narrKey] = narr;

      return newState;

    case RESTORE_VERSION_MULTINARRATIVE_ACTION_SUCCESS:
      const narrativeKey_ = findKey(
        state.ACR_SECTIONS_DETAIL.data.narratives,
        item => item.id === action.narrativeId,
      );

      if (!narrativeKey_) return state;

      newState = cloneDeep(state);
      newState.ACR_SECTIONS_DETAIL.data.narratives[narrativeKey_] = {
        ...newState.ACR_SECTIONS_DETAIL.data.narratives[narrativeKey_],
        content: action.content,
      };
      return newState;

    case SET_NARRATIVE_ACTION:
      return {
        ...state,
        ACR_SECTIONS_DETAIL: {
          ...state.ACR_SECTIONS_DETAIL,
          data: {
            ...state.ACR_SECTIONS_DETAIL.data,
            content: action.value,
          },
        },
      };

    case SET_SUBTITLE_SUCCESS_ACTION:
      return {
        ...state,
        ACR_SECTIONS_DETAIL: {
          ...state.ACR_SECTIONS_DETAIL,
          data: {
            ...state.ACR_SECTIONS_DETAIL.data,
            custom_subtitle: action.value,
          },
        },
      };

    case SET_EXTRA_NARRATIVE_ACTION:
      newState = cloneDeep(state);

      const _extraNarratives =
        newState.ACR_SECTIONS_DETAIL.data.extra_narratives;
      narrKey = findKey(_extraNarratives, item => item.id === action.id);

      newState.ACR_SECTIONS_DETAIL.data.extra_narratives[narrKey].content =
        action.value;

      return newState;

    case REQUEST_POST_ACR_INFO_BOX_SUCCESS:
      newState = cloneDeep(state);

      newState['ACR_REPORTS_DETAIL'].data = {
        ...newState['ACR_REPORTS_DETAIL'].data,
        ...action.payload,
      };

      return newState;

    case RECEIVE_DATA_NOTES_FROM_API:
      newState = cloneDeep(state);

      newState.ACR_SECTIONS_DETAIL.data.data_notes = action.payload.data_notes;
      return newState;
    case REQUEST_API_FAILURE:
      return {
        ...state,
        [key]: {
          isFetching: false,
          error: action.error,
          timestamp: action.timestamp,
        },
      };
    case REQUEST_API_FAILURE_HOLDING_DATA:
      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: false,
          error: action.error,
          timestamp: action.timestamp,
        },
      };
    case CLEAR_API_KEYS:
      newState = cloneDeep(state);
      action.dataKeys.forEach(value => {
        delete newState[value];
      });
      return newState;
    case CLEAN_REPORT_DATA_ACTION:
      const newStateCleanReport = { ...state };
      delete newStateCleanReport[SECTION_DETAIL_DATA_KEY];
      delete newStateCleanReport[REPORT_DETAIL_DATA_KEY];
      return newStateCleanReport;

    default:
      return state;
  }
};

const ACRApp = combineReducers({
  routesConfig,
  customLogin,
  viewData,
  viewState,
  component_signals: componentSignals,
  [STORAGE_KEY]: acrDatasourcesReducer,
});

export default ACRApp;
