import {
  Button,
  Link,
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationContent,
  SubNavigationHeader,
  SubNavigationTitle,
  User,
} from '@wfp/ui';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import { msalApp, msSignOut } from 'src/auth/auth';
import { API_HOST, SPR_HOST } from 'src/constants';
import {
  getActionAPISource,
  getAppUri,
  resetCustomLoginDataRequest,
} from 'src/redux/actions';
import { apiCallAsync } from 'src/redux/sagas';

export function HQAdminLinks(props) {
  const admin_dashboard_link = getActionAPISource('ADMIN_DASHBOARD') ? (
    <NavLink
      className="wfp--link"
      to={props.routesConfig.root.sub_routes.admin_dashboard.path}
      data-test-id="admin-dashboard-link"
    >
      Administration dashboard
    </NavLink>
  ) : null;

  const management_link = getAppUri('users-management') ? (
    <NavLink
      className="wfp--link"
      to={props.routesConfig.root.sub_routes.users_management.path}
      data-test-id="user-management-link"
    >
      Users Management
    </NavLink>
  ) : null;

  return (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationTitle>HQ Admin</SubNavigationTitle>
      </SubNavigationHeader>
      <SubNavigationContent>
        {admin_dashboard_link}
        {management_link}
        <Link
          className="wfp--link"
          href={`${API_HOST}/oidc/`}
          target="_blank"
          data-test-id="annual-report-link"
        >
          Django backend
        </Link>
      </SubNavigationContent>
    </SubNavigation>
  );
}

export function SignOutBox() {
  const appConfig = useSelector(
    state => state.viewData?.GET_APP_CONFIGURATION?.data,
  );
  const customLoginData = useSelector(state => state.customLogin);
  const userFullname = appConfig?.userInfo?.full_name;
  const [account, setAccount] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const accounts = msalApp.getAllAccounts();
    if (accounts && accounts.length === 1) {
      setAccount(accounts[0]);
    }
  }, []);

  const userUsername = useMemo(() => {
    if (userFullname) {
      return userFullname;
    }
    if (account && account.username) {
      return account.username;
    }
    return 'Anonymous';
  }, [userFullname, account]);

  function handleSignOut() {
    if (customLoginData?.accessToken) {
      dispatch(resetCustomLoginDataRequest());
      return;
    }

    msSignOut();
  }

  return (
    <SubNavigation>
      <SubNavigationHeader>
        <div data-test-id="welcome-message">{userUsername}</div>
        <Button data-test-id="logout-button" onClick={handleSignOut} small>
          Logout
        </Button>
      </SubNavigationHeader>
    </SubNavigation>
  );
}

export const Header = props => {
  const [navInstance, setNavInstance] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');

  async function getUserPicture() {
    const graphUrl = 'https://graph.microsoft.com/v1.0/me/photo/$value';

    await apiCallAsync(graphUrl, {}, 'get', 'blob')
      .then(response => {
        return response.status === 200 ? response.data : null;
      })
      .then(blobData => {
        setProfilePhotoUrl(blobData ? URL.createObjectURL(blobData) : '');
      })
      .catch(e => {
        console.log(e);
      });
  }

  const mainNavigationProps = {
    className: 'two-lines-navigation',
    pageWidth: 'lg',
    logo: (
      <NavLink to="/" data-test-id="spring-main-link">
        SPRiNG
      </NavLink>
    ),
  };

  useEffect(() => {
    getUserPicture();
  }, []);

  useEffect(() => {
    // Close SubNavigation when Route changes
    if (navInstance && navInstance.state.openMobile) navInstance.toggleMenu();
    else if (navInstance) navInstance.triggerSubNavigation();
  }, [props.location.pathname]);

  const roles = get(window, 'djconfig.userInfo.roles', []);
  const isHQAdmin = roles.includes('hq_admin') ? true : false;
  const superuser = get(window, 'djconfig.userInfo.superuser', []);

  const envName = process.env.ACR_ENV || 'LOCAL';
  const envClass = 'environment-indicator ' + envName.toLowerCase();
  let envNotification = null;
  if (envName !== 'PROD') {
    envNotification = <div className={envClass}>{envName}</div>;
  }
  if (get(window, 'djconfig.userInfo.hasAnyPermission', false)) {
    return (
      <>
        <MainNavigation {...mainNavigationProps} ref={setNavInstance}>
          <MainNavigationItem>
            <a className="wfp--link" href={SPR_HOST} data-test-id="spr-link">
              SPR
            </a>
          </MainNavigationItem>

          <MainNavigationItem>
            <NavLink
              className="wfp--link"
              to="/"
              data-test-id="acr-dashboard-link"
            >
              ACR Dashboard
            </NavLink>
          </MainNavigationItem>

          {isHQAdmin || superuser ? (
            <MainNavigationItem
              subNavigation={<HQAdminLinks routesConfig={props.routesConfig} />}
            >
              <NavLink
                className="wfp--link"
                to="/index/"
                data-test-id="hq-admin-link"
              >
                HQ Admin
              </NavLink>
            </MainNavigationItem>
          ) : null}

          <MainNavigationItem
            className="wfp--main-navigation__user"
            subNavigation={<SignOutBox />}
          >
            <User ellipsis data-test-id="user-icon" image={profilePhotoUrl} />
          </MainNavigationItem>
        </MainNavigation>
        {envNotification}
      </>
    );
  }

  // show if no permissions
  return (
    <MainNavigation {...mainNavigationProps} ref={setNavInstance}>
      <MainNavigationItem
        className="wfp--main-navigation__user"
        subNavigation={<SignOutBox />}
      >
        <User ellipsis data-test-id="user-icon" />
      </MainNavigationItem>
    </MainNavigation>
  );
};

export default withRouter(props => <Header {...props} />);
