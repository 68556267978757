import MediumEditor from 'medium-editor';
import React, { useEffect, useRef } from 'react';

function MediumEditorWrapper({ className, options, text, editorRef }) {
  const container = useRef();

  useEffect(() => {
    editorRef.current = new MediumEditor(container.current, options);
    return () => {
      editorRef.current.destroy();
    };
  }, []);

  if (editorRef && editorRef.current) {
    editorRef.current.saveSelection();
  }

  return (
    <div
      className={className ? className : ''}
      dangerouslySetInnerHTML={{ __html: text }}
      ref={container}
    />
  );
}

export default MediumEditorWrapper;
