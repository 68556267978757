import { call, put, takeEvery } from 'redux-saga/effects';

import { GET_PUBLICATION_LIST_REQUEST } from 'src/constants';
import {
  getActionAPISource,
  receiveAPIResponseHoldingData,
  requestAPIFailedHoldingData,
} from 'src/redux/actions';
import {
  PDF_MERGE_LIST,
  REPORT_LIST_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

export function* PublicationStatusApiCallSaga(action) {
  try {
    let url = getActionAPISource(PDF_MERGE_LIST);
    url = `${url}?period=${action.payload.period}`;

    const response = yield call(apiCallAsync, url);

    if (response.status >= 200 && response.status < 300) {
      yield put(
        receiveAPIResponseHoldingData(response.data, REPORT_LIST_DATA_KEY),
      );
    } else {
      throw 'Publication Status API call has failed';
    }
  } catch (e) {
    yield put(
      requestAPIFailedHoldingData(REPORT_LIST_DATA_KEY, {
        message: e.message,
        data: e.response ? e.response.data : null,
      }),
    );
    showNotification(e.message || 'Error', 'error');
    console.error(e);
  }
}

export function* getPublicationStatusSaga() {
  yield takeEvery(GET_PUBLICATION_LIST_REQUEST, PublicationStatusApiCallSaga);
}
